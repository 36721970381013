import { ReactNode, createContext, useContext, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  RiskMatrixSlim,
  updateCalculationNodeSchema,
} from '@spektr/shared/validators';

import { getRiskMatricesQuery } from '@spektr/client/services';

import {
  IncompleteCalculation,
  IncompleteSegment,
  RiskMatrixSegment,
  makeIncompleteRuleGroup,
} from '../tree-helpers';

// TODO: renmame file
export type IncompleteCalculationNodeContextType = {
  incomplete: IncompleteCalculation;
  calculationNode: ReturnType<
    (typeof updateCalculationNodeSchema)['safeParse']
  >;
  addSegment: () => void;
  addMatrixSegment: (riskMatrix: RiskMatrixSlim) => void;
  getSegment: (segmentId: string) => IncompleteSegment | undefined;
  updateSegment: (segment: IncompleteSegment) => void;
  removeSegment: (segmentId: string) => void;
  setTitle: (title: string) => void;
  riskMatrices: RiskMatrixSlim[];
  processType: string;
};

export const useIncompleteCalculationNode = () =>
  useContext(IncompleteCalculationNodeContext);

const IncompleteCalculationNodeContext = createContext(
  {} as IncompleteCalculationNodeContextType
);

export type ProcessNodeProviderProps = {
  initial: IncompleteCalculation;
  processType: string;
};

export const CalculationNodeProvider = ({
  initial,
  processType,
  children,
}: ProcessNodeProviderProps & { children: ReactNode }) => {
  const [incomplete, setIncomplete] = useState(initial);

  const { data: matrices = [] } = useSuspenseQuery(getRiskMatricesQuery());

  const calculationNode = updateCalculationNodeSchema.safeParse(incomplete);

  const setTitle = (title: string) =>
    setIncomplete((current) => ({ ...current, title }));

  const addSegment = () =>
    setIncomplete((current) => {
      const id = String(segmentIdCounter++);
      const next: IncompleteSegment = {
        title: `Segment #${id}`,
        id: undefined, // will be set by the server once saved
        clientSideOnlyId: id,
        weight: '100',
        groups: [makeIncompleteRuleGroup()],
      };

      return {
        ...current,
        segments: [...current.segments, next],
      };
    });

  const addMatrixSegment = (riskMatrix: RiskMatrixSlim) => {
    setIncomplete((current) => {
      const next: RiskMatrixSegment = {
        title: riskMatrix.title,
        id: undefined, // will be set by the server once saved
        clientSideOnlyId: riskMatrix.id,
        weight: '100',
        groups: [],
        riskMatrixId: riskMatrix.id,
      };

      return {
        ...current,
        segments: [...current.segments, next],
      };
    });
  };

  const getSegment = (segmentId: string) => {
    const segment = incomplete.segments.find(
      (segment) => segment.clientSideOnlyId === segmentId
    );

    return segment;
  };

  const updateSegment = (segment: IncompleteSegment) => {
    if (segment.groups.length === 0) removeSegment(segment.clientSideOnlyId);
    setIncomplete((current) => ({
      ...current,
      segments: current.segments.map((s) =>
        s.clientSideOnlyId === segment.clientSideOnlyId ? segment : s
      ),
    }));
  };

  const removeSegment = (segmentId: string) =>
    setIncomplete((current) => ({
      ...current,
      segments: current.segments.filter(
        (segment) => segment.clientSideOnlyId !== segmentId
      ),
    }));

  return (
    <IncompleteCalculationNodeContext.Provider
      value={{
        incomplete,
        calculationNode,
        getSegment,
        addSegment,
        addMatrixSegment,
        removeSegment,
        setTitle,
        updateSegment,
        processType,
        riskMatrices: matrices,
      }}
    >
      {children}
    </IncompleteCalculationNodeContext.Provider>
  );
};

let segmentIdCounter = 1;
