import { forwardRef, useState } from 'react';
import { cva } from 'class-variance-authority';
import { format } from 'date-fns';

import { Calendar as CalendarIcon } from 'lucide-react';

import {
  DayPickerDefaultProps,
  SelectSingleEventHandler,
} from 'react-day-picker';

import { cn } from '@spektr/style-utils';

import { isValidDateFormat, parseDate } from '../../utils/isValidDateFormat';

import { Calendar } from '../Calendar';
import { Popover } from '../Popover';

export type DatePickerProps = {
  disabled?: DayPickerDefaultProps['disabled'];
  name: string;
  hasError?: boolean;
  placeholder?: string;
  value: string | number | undefined;
  dateFormat?: string;
  onChange?: (selectedDate: string | undefined) => void;
};

const DEFAULT_FORMAT = 'LLL d, yyyy';

export const DatePicker = forwardRef<HTMLButtonElement, DatePickerProps>(
  (
    {
      placeholder = 'Select date',
      hasError = false,
      name,
      value,
      dateFormat = DEFAULT_FORMAT,
      onChange,
      disabled = false,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(
      parseDate(value)
    );

    const handleSingleDateChange: SelectSingleEventHandler = (date) => {
      setSelectedDate(date);
      setOpen(false);
      onChange?.(date?.getTime()?.toString());
    };

    const validFormat = isValidDateFormat(dateFormat)
      ? dateFormat
      : DEFAULT_FORMAT;

    return (
      <Popover
        className="w-auto"
        trigger={
          <button
            ref={ref}
            name={name}
            className={cn(
              'relative',
              'flex items-center',
              'h-9 w-full px-3',
              'rounded-md border',
              'bg-white',
              'text-sm font-medium text-black',
              'transition-colors',
              'placeholder:text-zinc-500',
              'disabled:cursor-not-allowed disabled:bg-zinc-200 disabled:text-zinc-500',
              'focus-visible:shadow-md focus-visible:outline-none focus-visible:ring-1',
              'focus-within:border-blue-500 focus-within:ring-blue-500',
              hasError
                ? 'focus-within:border-red-500 focus-within:ring-red-500'
                : 'focus-within:shadow-md focus-within:outline-none focus-within:ring-1',
              variants({ variant: hasError ? 'error' : 'default' })
            )}
          >
            {selectedDate ? format(selectedDate, validFormat) : placeholder}
            <CalendarIcon className="absolute right-3 h-4 w-4 text-zinc-400" />
          </button>
        }
        triggerProps={{
          asChild: true,
        }}
        open={open}
        onOpenChange={setOpen}
      >
        <Calendar
          mode="single"
          selected={selectedDate}
          onSelect={handleSingleDateChange}
          disabled={disabled}
        />
      </Popover>
    );
  }
);

const variants = cva('', {
  variants: {
    variant: {
      default: [
        'border-zinc-500',
        'focus:border-blue-500 focus-visible:ring-blue-500',
      ],
      error: [
        'border-error-500',
        'focus:border-error-500 focus-visible:ring-error-500',
      ],
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});
