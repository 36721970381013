import { ProcessType } from '@spektr/shared/validators';

import { Badge } from '../Badge';

import { useProcessTypeVariants } from './useProcessTypeVariants';

export namespace ProcessTypeBadge {
  export type Props = {
    type: ProcessType;
    className?: string;
  };

  export type Types = {
    variant: Badge.Variants;
    label: string;
  };
}

export const ProcessTypeBadge = ({
  type,
  className,
}: ProcessTypeBadge.Props) => {
  const { variant, label } = useProcessTypeVariants(type);
  return (
    <Badge variant={variant} className={className}>
      {label}
    </Badge>
  );
};
