import { Process } from '@spektr/shared/types';
import { ChannelSettings } from '@spektr/shared/validators';

/**
 * @description Channel settings should be set if loop has at least
 * one form or mitId or veriff node.
 * @param process - loop or onboarding process
 */
export const shouldProcessHaveChannelSettings = (process: Process) => {
  if (process.type === 'onboarding' && process.rootId) {
    const rootNode = process.nodes.get(process.rootId);
    if (rootNode && rootNode.nodeType !== 'onboardingProcessSource')
      return false;
    return true;
  }

  return [...process.nodes.values()].some((node) =>
    ['form', 'mitId', 'veriff', 'ownershipTreeForm'].includes(node.nodeType)
  );
};

/**
 * @description Check if channel settings are valid. Email channel
 * should have an email mapping and internal email should have an email address.
 *
 * @param settings - channel settings
 */
export const areChannelSettingsSet = (
  settings: ChannelSettings[] | undefined | null
) => {
  if (!settings?.length) return false;

  return settings?.every((s) => {
    if (s.type === 'email') {
      return !!s.mapping.email;
    }
    if (s.type === 'internalEmail') {
      return !!s.emailAddress;
    }
    return true;
  });
};
