import { HTMLAttributes } from 'react';

export const VirkIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="60"
    height="38"
    viewBox="0 0 60 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_16302_2835)">
      <path
        d="M0.360565 19.5714C0.360565 18.9643 5.62499 18.9286 5.73316 19.4286C5.73316 19.4286 7.64422 30.3214 7.71633 30.7143H7.86057C8.47355 28.75 12.1154 19.4286 12.1154 19.4286C12.3317 18.9286 17.1274 18.9643 17.1274 19.5C17.1274 19.6786 17.0913 19.8929 16.9832 20.1071L9.51922 37.2857C9.30287 37.7857 4.47114 37.7857 4.36297 37.2857L0.432681 20.1071C0.396623 19.8214 0.360565 19.6429 0.360565 19.5714Z"
        fill="#1A1A1A"
      />
      <path
        d="M30.1442 22.25L30.3606 19.4643C30.6851 18.8214 35.3726 19.1429 35.4086 19.5714C35.4086 19.8571 35.3365 20.9286 35.3365 20.9286C36.0937 19.5714 38.5457 19.2857 40.1322 19.2857C40.601 19.2857 40.8173 19.25 41.0337 19.3214C41.3221 19.4286 40.6731 23.75 40.3486 23.8571C39.7356 24.0357 39.2308 24 38.2212 24.25C36.887 24.6071 35.3365 25.0714 35.0481 26.4286L34.0024 37.1429C33.9303 37.8929 28.5577 37.6786 28.7019 37.1786C28.7019 37.1786 30.0361 23.0714 30.1442 22.25Z"
        fill="#1A1A1A"
      />
      <path
        d="M50.4086 11.75L48.8942 26.25L49.0385 26.2857L54.0505 19.5C54.411 19.0357 59.6034 19 59.6034 19.3214C59.6034 19.5 59.4591 19.6786 59.1707 20L53.4735 27.4643L58.3413 36.6429C58.4495 36.8929 58.6298 37.25 58.5216 37.3929C58.3413 37.6786 53.0769 37.8214 52.8606 37.3571L48.75 29.0714L48.6058 29.1071L47.7764 37.0714C47.7043 37.8214 42.476 37.8214 42.476 37.0714L45.1082 11.75C45.1803 11 50.4086 11 50.4086 11.75Z"
        fill="#1A1A1A"
      />
      <path
        d="M25.8533 19.6428C25.9255 18.9286 20.625 18.9286 20.5529 19.6786L19.9759 25.6071L18.786 37.0714C18.786 37.8214 24.0144 37.8214 24.0865 37.0714L24.9158 29.1071L25.2043 26.25L25.4567 23.6071C25.5649 22.4643 25.7091 21.2143 25.8533 19.8571"
        fill="#1A1A1A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.3534 10.4643C19.4711 10.4643 20.3365 11.3572 20.3365 12.4286C20.3365 13.5357 19.4351 14.3929 18.3534 14.3929C17.2356 14.3929 16.3702 13.5 16.3702 12.4286C16.3702 11.3214 17.2716 10.4643 18.3534 10.4643Z"
        fill="#0059B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.6394 5.92859C30.7572 5.92859 31.6226 6.82145 31.6226 7.89287C31.6226 9.00002 30.7211 9.85716 29.6394 9.85716C28.5216 9.85716 27.6562 8.9643 27.6562 7.89287C27.6202 6.78573 28.5216 5.92859 29.6394 5.92859Z"
        fill="#0059B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.0168 10.4643C29.1346 10.4643 30 11.3572 30 12.4286C30 13.5357 29.0985 14.3929 28.0168 14.3929C26.899 14.3929 26.0336 13.5 26.0336 12.4286C25.9976 11.3214 26.899 10.4643 28.0168 10.4643Z"
        fill="#0059B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7308 5.92859C17.8486 5.92859 18.7139 6.82145 18.7139 7.89287C18.7139 9.00002 17.8125 9.85716 16.7308 9.85716C15.613 9.85716 14.7476 8.9643 14.7476 7.89287C14.7476 6.78573 15.613 5.92859 16.7308 5.92859Z"
        fill="#0059B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.1851 0.357147C24.3029 0.357147 25.1683 1.25 25.1683 2.32143C25.1683 3.42858 24.2668 4.28572 23.1851 4.28572C22.0673 4.28572 21.2019 3.39286 21.2019 2.32143C21.1659 1.25 22.0673 0.357147 23.1851 0.357147Z"
        fill="#0059B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.1851 5.32144C24.3029 5.32144 25.1683 6.2143 25.1683 7.28573C25.1683 8.39287 24.2668 9.25001 23.1851 9.25001C22.0673 9.25001 21.2019 8.35716 21.2019 7.28573C21.1659 6.2143 22.0673 5.32144 23.1851 5.32144Z"
        fill="#0059B3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.1851 10.0714C24.3029 10.0714 25.1683 10.9643 25.1683 12.0357C25.1683 13.1429 24.2668 14 23.1851 14C22.0673 14 21.2019 13.1072 21.2019 12.0357C21.1659 10.9643 22.0673 10.0714 23.1851 10.0714Z"
        fill="#0059B3"
      />
    </g>
    <defs>
      <clipPath id="clip0_16302_2835">
        <rect width="60" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
