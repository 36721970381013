import { useMutation, useQueryClient } from '@tanstack/react-query';

import { InsightsApiClient, CUSTOMERS_KEYS } from '@spektr/client/services';
import { invalidateMultipleQueries } from '@spektr/client/utils';
import { ClientRecordUpdate } from '@spektr/shared/validators';

export const useUpdateClientRecord = (spektrId: string) => {
  const queryClient = useQueryClient();
  const updateCustomer = useMutation({
    mutationFn: async (body: ClientRecordUpdate) => {
      const validatedData = ClientRecordUpdate.parse(body);
      await InsightsApiClient.getClient().updateClientRecordById(
        validatedData,
        {
          params: { clientRecordId: spektrId },
        }
      );

      await invalidateMultipleQueries(queryClient, [
        {
          queryKey: CUSTOMERS_KEYS.BY_ID(spektrId),
        },
        {
          queryKey: CUSTOMERS_KEYS.ALL(),
        },
      ]);
      await queryClient.invalidateQueries();
    },
  });

  return updateCustomer;
};
