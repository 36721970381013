import { cn } from '@spektr/style-utils';

import { type ConsentField } from '@spektr/moonraker-types';

export namespace ConsentContent {
  export interface Props {
    field: ConsentField;
  }
}

export const ConsentContent = ({ field }: ConsentContent.Props) => {
  return (
    <div className="flex w-full flex-row items-center gap-2">
      <div className={cn('h-3.5 w-3.5', 'border', 'rounded-sm')} />
      <span
        className={cn('text-color-text-subtext text-sm font-medium')}
        dangerouslySetInnerHTML={{ __html: field.attributes.label }}
      />
    </div>
  );
};
