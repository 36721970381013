import { z } from 'zod';

import { Field } from '@spektr/moonraker-types';

import { fieldsFormRoutes } from '../FormRouterApi';

export const Widget = z.object({
  id: z.string(),
  category: z.string(),
  title: z.string(),
  icon: z.string(),
  component: Field,
  type: fieldsFormRoutes,
});

export type Widget = z.infer<typeof Widget>;
