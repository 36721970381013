import { Controller, useFormContext } from 'react-hook-form';

import { Label, RichTextEditor } from '@spektr/client/components';
import { SpektrField } from '@spektr/shared/types';

export type MessageBodyFieldProps = {
  spektrFields: SpektrField[];
};

export const MessageBodyField = ({ spektrFields }: MessageBodyFieldProps) => {
  const { watch, control } = useFormContext();

  const channelType = watch('type');

  return (
    <div className="flex flex-col gap-2">
      <Label>Message Body</Label>
      <Controller
        name="messageBody"
        render={({ field }) => (
          <RichTextEditor
            formats={
              channelType === 'email' || channelType === 'internalEmail'
                ? ['bold', 'italic', 'underline']
                : []
            }
            floatingToolbar={false}
            className="h-40"
            placeholder="Please enter the content of your message"
            initialValue={field.value}
            mentionOptions={spektrFields.map((field) => field.key)}
            onChange={field.onChange}
          />
        )}
        control={control}
      />
    </div>
  );
};
