import { useSuspenseQuery } from '@tanstack/react-query';

import { useUpdateNode } from '@spektr/shared/hooks';

import { getNodeByIdQuery } from '@spektr/client/services';

import { Dialog, DialogContent } from '@spektr/client/components';

import { processNodeSchema } from '@spektr/shared/validators';
import { type NodeUpdateInput, type ProcessNode } from '@spektr/shared/types';

import { FormDialogWrapper } from './containers/FormDialog';
import { OwnershipTreeFormDialogWrapper } from './containers/OwnershipTreeFormDialog';
import { OnboardingSourceWrapper } from './containers/OnboardingSourceDialog';
import { ServiceAlertDialog } from './containers/ServiceAlertNodeDialog';
import { ManualReviewDialog } from './containers/ManualReviewDialog';
import { AiAmlAlertDialog } from './containers/AiAmlAlertDialog';
import { CalculationDialog } from './containers/CalculationDialog';
import { ReturningProcessDialog } from './containers/ReturningProcessDialog';
import { MonitoringDatasetDialog } from './containers/MonitoringDatasetDialog';
import { DocumentOCRDialog } from './containers/DocumentOCRDialog';
import { SlackDialog } from './containers/SlackDialog';
import { RouterDialog } from './containers/RouterDialog';
import { OpenCorporateMonitoringDialog } from './containers/OpenCorporatesMonitoringDialog';
import { OpenCorporatesRiskDialog } from './containers/OpenCorporatesRiskDialog';
import { ComplyAdvantageKybDialog } from './containers/ComplyAdvantageKybDialog';
import { ComplyAdvantageKycDialog } from './containers/ComplyAdvantageKycDialog';
import { VeriffDialog } from './containers/VeriffDialog';
import { VeriffIdvDialog } from './containers/VeriffIdvDialog';
import { MitIdDialog } from './containers/MitIdDialog';
import { KyckrDialog } from './containers/KyckrDialog';
import { BodaccDialog } from './containers/BodaccDialog';
import { VirkDialog } from './containers/VirkDialog';

import { type BaseDialogProps } from './types/BaseDialogProps';
import { type SupportedNodes, isSupportedNode } from './types/SupportedNodes';

const NODE_DETAILS_MAP: Record<SupportedNodes, any> = {
  onboardingProcessSource: OnboardingSourceWrapper,
  router: RouterDialog,
  calculation: CalculationDialog,
  alert: ServiceAlertDialog,
  form: FormDialogWrapper,
  ownershipTreeForm: OwnershipTreeFormDialogWrapper,
  manualReview: ManualReviewDialog,
  returningProcess: ReturningProcessDialog,
  monitoringDataset: MonitoringDatasetDialog,
  aiAmlAlert: AiAmlAlertDialog,
  documentOCR: DocumentOCRDialog,

  slack: SlackDialog,

  openCorporatesMonitoring: OpenCorporateMonitoringDialog,
  openCorporatesRisk: OpenCorporatesRiskDialog,

  complyAdvantageKyb: ComplyAdvantageKybDialog,
  complyAdvantageKyc: ComplyAdvantageKycDialog,

  kyckr: KyckrDialog,
  veriff: VeriffDialog,
  veriffIdv: VeriffIdvDialog,
  bodacc: BodaccDialog,
  mitId: MitIdDialog,
  virk: VirkDialog,
};

export namespace NodeDetailsFactoryDialog {
  export type Props = {
    processId: string;
    nodeId: string;
    onClose: () => void;
  };
}

export const NodeDetailsFactoryDialog = ({
  processId,
  nodeId,
  onClose,
}: NodeDetailsFactoryDialog.Props) => {
  const { data: node } = useSuspenseQuery(getNodeByIdQuery(processId, nodeId));

  const updateMutation = useUpdateNode(processId);
  const handleUpdateNode = (data: NodeUpdateInput) =>
    updateMutation.mutateAsync({
      nodeId: node.id,
      node: data,
    });

  const nodeResult = processNodeSchema.safeParse(node);

  if (nodeResult.success) {
    const props: BaseDialogProps<ProcessNode> = {
      nodeId,
      processId,
      node: nodeResult.data,
      onClose,
      onUpdate: handleUpdateNode,
      isPendingUpdate: updateMutation.isPending,
    };

    if (!isSupportedNode(node.nodeType)) {
      return;
    }

    if (node.nodeType in NODE_DETAILS_MAP && NODE_DETAILS_MAP[node.nodeType]) {
      const Component = NODE_DETAILS_MAP[node.nodeType];
      return <Component {...props} />;
    }
  }

  return (
    <Dialog defaultOpen modal={false}>
      <DialogContent
        modal={false}
        className="text-color-text-dialog-default"
        requestStartExitAnimation={onClose}
        onEndExitAnimation={onClose}
        onEscapeKeyDown={onClose}
      >
        Not implemented yet
      </DialogContent>
    </Dialog>
  );
};
