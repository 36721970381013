import { useMeasure } from 'react-use';

import { Tooltip } from '@spektr/client/components';

export namespace NameWithTooltip {
  export type Props = {
    name: string;
    maxWidth?: number;
  };
}

export const NameWithTooltip = ({
  name,
  maxWidth = 160,
}: NameWithTooltip.Props) => {
  const [ref, { width }] = useMeasure<HTMLParagraphElement>();

  const needsTooltip = Math.ceil(width) > maxWidth;

  return (
    <div className="relative h-5 text-sm font-medium text-black dark:text-white">
      <p
        ref={ref}
        aria-hidden="true"
        className="-z-1 absolute -top-full h-0 opacity-0"
      >
        {name}
      </p>
      {needsTooltip ? (
        <Tooltip
          content={name}
          contentClassName="bg-white dark:bg-zinc-900"
          side="top"
        >
          <p className="line-clamp-1 text-start">{name}</p>
        </Tooltip>
      ) : (
        <p className="line-clamp-1 text-start">{name}</p>
      )}
    </div>
  );
};
