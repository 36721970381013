import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { FileWarning } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
  connectionHubDataSourcesDatasetUrl,
  connectionHubDataSourcesProcessesUrl,
  connectionHubDataSourcesRecordsUrl,
  processRunsFullUrl,
} from '@spektr/shared/utils';

import {
  DATASETS_KEYS,
  MappingApiClient,
  connectionDataSetByIdQuery,
  connectionDataSetsQuery,
} from '@spektr/client/services';
import { AlertDialog, toast } from '@spektr/client/components';

import {
  DatasetResponse,
  DatasetWithMetaExtendedResponse,
} from '@spektr/shared/types';

import { RenameDatasetDialog } from '../../../RenameProcessDialog';
import { DatasetCard } from '../../components/DatasetCard';

const possibleActions = ['rename', 'delete'] as const;
type PossibleActionsType = (typeof possibleActions)[number];
type PendingAction = {
  id: string;
  action: PossibleActionsType;
};

export const ExtractDatasetsFromSource = () => {
  let IS_LONG_POLLING_ENABLED = true;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [pendingAction, setPendingAction] = useState<PendingAction | null>();

  const { data } = useSuspenseQuery({
    ...connectionDataSetsQuery(),
    refetchInterval: () => (IS_LONG_POLLING_ENABLED ? 2000 : false),
  });
  const { data: dataset, isPending: isDatasetPending } = useQuery({
    ...connectionDataSetByIdQuery(pendingAction?.id ?? ''),
    enabled: !!pendingAction,
  });

  const isPossibleActionsType = (
    value: string
  ): value is PossibleActionsType => {
    return possibleActions.includes(value);
  };

  const renameDatasetMutation = useMutation({
    mutationFn: async ({ id, newName }: { id: string; newName: string }) => {
      await MappingApiClient.getClient().updateDatasetName(
        {
          name: newName,
        },
        {
          params: { id },
        }
      );
    },
    onError: (_error, _variables, context: any) => {
      queryClient.setQueryData(DATASETS_KEYS.ALL(), context?.previousDatasets);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: DATASETS_KEYS.ALL() });
      setPendingAction(null);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: DATASETS_KEYS.ALL() });
    },
  });

  const deleteDatasetMutation = useMutation({
    mutationFn: async (id: string) => {
      await queryClient.cancelQueries({ queryKey: DATASETS_KEYS.ALL() });

      const previousDatasets =
        queryClient.getQueryData<DatasetResponse[]>(DATASETS_KEYS.ALL()) ?? [];

      queryClient.setQueryData(
        DATASETS_KEYS.ALL(),
        previousDatasets?.filter((dataset: DatasetResponse) => {
          return dataset.id !== id;
        })
      );

      await MappingApiClient.getClient().deleteDatasetById(undefined, {
        params: { id },
      });

      return {
        previousDatasets,
      };
    },
    onError: (error, variables, context: any) => {
      queryClient.setQueryData(DATASETS_KEYS.ALL(), context?.previousDatasets);
      if (axios.isAxiosError(error)) {
        return toast.error({
          title: 'Something went wrong!',
          description: error.response?.data?.message ?? 'Something went wrong!',
        });
      }

      throw error;
    },
    onSuccess: () => {
      toast.success({
        title: 'Dataset deleted',
        description: 'The dataset was successfully deleted.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: DATASETS_KEYS.ALL() });
    },
  });

  IS_LONG_POLLING_ENABLED =
    data?.some(
      (dataset) =>
        dataset.meta.scan === 'idle' || dataset.meta.scan === 'processing'
    ) ?? false;

  const handleConfirmRename = (name: string) => {
    if (pendingAction) {
      if (!name) {
        return;
      }
      renameDatasetMutation.mutate({
        id: pendingAction.id,
        newName: name,
      });
    }
  };

  const handleConfirmDelete = async () => {
    if (pendingAction) {
      deleteDatasetMutation.mutateAsync(pendingAction.id);
      setPendingAction(null);
    }
  };

  const handleDropdownMenuClick =
    (dataset: DatasetResponse) => (value: string) => {
      if (value === 'inspect') {
        navigate(connectionHubDataSourcesRecordsUrl(dataset.id));
      }

      if (value === 'processes') {
        navigate(connectionHubDataSourcesProcessesUrl(dataset.id));
      }

      if (value === 'process-runs') {
        navigate(processRunsFullUrl(dataset.id));
      }

      if (isPossibleActionsType(value)) {
        setPendingAction({
          id: dataset.id,
          action: value,
        });
      }
    };

  if (data.length === 0) {
    return (
      <div className="mt-4 flex gap-2">
        <FileWarning className="text-color-yellow h-6 w-6" />
        <p className="text-color-text-primary text-base font-medium">
          No datasets yet
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {data
          .filter((a) => a.origin !== 'child')
          .sort((a, b) => b.createdAt - a.createdAt)
          .map((dataset) => (
            <DatasetCard
              key={dataset.id}
              dataset={dataset}
              onClick={() =>
                navigate(connectionHubDataSourcesDatasetUrl(dataset.id))
              }
              onActionClick={handleDropdownMenuClick(dataset)}
              data-cy={'card-more-options-' + dataset.name}
            />
          ))}
      </div>

      {!!pendingAction && !isDatasetPending && (
        <>
          <RenameDatasetDialog
            open={pendingAction.action === 'rename'}
            dataset={dataset}
            onSave={handleConfirmRename}
            onClose={() => setPendingAction(null)}
          />

          <AlertDialog
            open={pendingAction.action === 'delete'}
            title="Are you sure?"
            paragraph={getMessageForPendingDelete(dataset)}
            onCancel={() => setPendingAction(null)}
            cancel="Cancel"
            onConfirm={handleConfirmDelete}
            confirm="Yes, delete"
            confirmDataCy="Yes-delete"
          />
        </>
      )}
    </>
  );
};

function getMessageForPendingDelete(dataset?: DatasetWithMetaExtendedResponse) {
  const sourcedProcesses = dataset?.meta.linkedProcessIds.length ?? 0;
  if (sourcedProcesses > 0) {
    const pluralProcesses = sourcedProcesses > 1 ? 'processes' : 'process';
    return `This dataset deletion is irreversible and affects ${sourcedProcesses} active ${pluralProcesses}. Confirm your choice, as this action cannot be undone.`;
  }

  return 'This dataset deletion is irreversible. Confirm your choice, as this action cannot be undone.';
}
