import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

export namespace Input {
  export type Props = InputHTMLAttributes<HTMLInputElement> & {
    hasError?: boolean;
    prefix?: ReactNode;
    suffix?: ReactNode;
  };
}

export const Input = forwardRef<HTMLInputElement, Input.Props>(
  ({ hasError = false, className, prefix, suffix, style, ...props }, ref) => {
    const classes = cn(
      'flex items-center w-full',
      'rounded-md border',
      'transition-colors',
      'bg-white',
      'text-black text-sm font-medium',
      'disabled:bg-zinc-100 disabled:cursor-not-allowed',
      variants({ variant: hasError ? 'error' : 'default' }),
      className
    );

    const inputClassName = cn(
      'placeholder:text-zinc-500',
      'disabled:text-zinc-300',
      {
        '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none':
          props.type === 'number',
      }
    );

    if (prefix || suffix) {
      return (
        <div
          style={style}
          className={cn(
            classes,
            'flex items-center',
            'focus-within:border-blue-500 focus-within:ring-blue-500',
            hasError
              ? 'focus-within:border-red-500 focus-within:ring-red-500'
              : 'focus-within:shadow-md focus-within:outline-none focus-within:ring-1',
            props.disabled && 'cursor-not-allowed opacity-50',
            suffix && 'pr-3',
            prefix && 'pl-3'
          )}
        >
          {prefix}
          <input
            {...props}
            className={cn(
              classes,
              inputClassName,
              'h-auto w-full',
              'border-none bg-transparent shadow-none',
              'focus-visible:outline-none focus-visible:ring-0'
            )}
            ref={ref}
          />
          {suffix}
        </div>
      );
    }

    return (
      <input
        {...props}
        style={style}
        className={cn(
          classes,
          inputClassName,
          'focus-visible:shadow-md focus-visible:outline-none focus-visible:ring-1'
        )}
        ref={ref}
      />
    );
  }
);

const variants = cva('', {
  variants: {
    variant: {
      default: [
        'border-zinc-500',
        'focus:border-blue-500 focus-visible:ring-blue-500',
      ],
      error: [
        'border-red-500',
        'focus:border-red-500 focus-visible:ring-red-500',
      ],
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});
