import { RBAC } from '@spektr/shared/rbac';

import {
  ActionButton,
  BaseVariantColor,
  DialogFooter,
  ServiceVariantColor,
} from '@spektr/client/components';

export namespace DialogFooterActions {
  export type Props = {
    btnColor?: BaseVariantColor | ServiceVariantColor;
    disabled?: boolean;
    disabledReason?: string;
    isPendingUpdate?: boolean;
    saveText?: string;
    pendingSaveText?: string;
    onSave: () => void;
  };
}

export const DialogFooterActions = ({
  btnColor = 'primary',
  disabled,
  disabledReason = '',
  isPendingUpdate,
  pendingSaveText = 'Saving...',
  saveText = 'Save',
  onSave,
}: DialogFooterActions.Props) => {
  return (
    <DialogFooter className="pt-6">
      <ActionButton
        rbacAction={RBAC.ACTIONS.NODE.UPDATE}
        color={btnColor}
        disabled={disabled}
        isPending={isPendingUpdate}
        pendingLabel={pendingSaveText}
        getDisabledReason={() => disabledReason}
        onClick={onSave}
      >
        {saveText}
      </ActionButton>
    </DialogFooter>
  );
};
