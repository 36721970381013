import { cn } from '@spektr/style-utils';

import { Accordion, ScrollArea } from '@spektr/client/components';

import { useFormEngine } from '../../hooks/useFormEngine';
import { useFormRouter } from '../../hooks/useFormRouter';
import { useContentHeight } from '../../hooks/useContentHeight';

import { EmptyContent } from './components/EmptyContent';
import { ConfigHeader } from './components/ConfigHeader';
import { FormHelpContent } from './components/FormHelpContent';
import { Content } from './components/Content';
import { Validation } from './components/Validation';

export const FieldConfigSidebar = () => {
  const { currentRoute } = useFormRouter();
  const { spektrFields, getCurrentField, deleteField } = useFormEngine();
  const currentField = getCurrentField();
  const height = useContentHeight();

  if (currentRoute !== 'design' && currentRoute !== 'layout') {
    return (
      <div className={cn('h-full w-full border-l', 'bg-color-bg-moonraker')}>
        {currentField === null ? (
          <EmptyContent />
        ) : (
          <>
            <ConfigHeader
              title={currentField.type}
              onDelete={() => deleteField(currentField.id)}
            />
            <ScrollArea
              style={{
                height: height - 44,
              }}
            >
              <Accordion
                className="flex flex-col"
                defaultValue={['content', 'validation']}
                type="multiple"
              >
                <>
                  <Content
                    currentField={currentField}
                    spektrFields={spektrFields}
                  />
                  <Validation currentField={currentField} />
                </>
              </Accordion>
            </ScrollArea>
          </>
        )}
      </div>
    );
  }

  return (
    <div
      className={cn(
        'relative',
        'h-full w-full',
        'border-l',
        'bg-color-bg-moonraker'
      )}
    >
      <ScrollArea
        style={{
          height: height,
        }}
      >
        <Accordion collapsible defaultValue="content" type="single">
          <FormHelpContent />
        </Accordion>
      </ScrollArea>
    </div>
  );
};
