import {
  BaseEdge,
  EdgeLabelRenderer,
  type EdgeProps,
  getSmoothStepPath,
} from 'reactflow';

import { cn } from '@spektr/style-utils';
import { Skeleton } from '@spektr/client/components';

export const SkeletonEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  ...props
}: EdgeProps) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        {...props}
        path={edgePath}
        style={{
          ...props.style,
          stroke: 'rgb(var(--zinc-500))',
        }}
      />
      <EdgeLabelRenderer>
        <div
          className={cn(
            'absolute',
            'flex items-center justify-center gap-2',
            'px-3 py-2',
            'rounded-md border border-zinc-300 dark:border-zinc-700',
            'bg-white dark:bg-zinc-900',
            'text-zinc-700 dark:text-zinc-300',
            'cursor-pointer',
            'nodrag nopan'
          )}
          style={{
            pointerEvents: 'all',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
        >
          <Skeleton className="h-2 w-10" />
        </div>
      </EdgeLabelRenderer>
    </>
  );
};
