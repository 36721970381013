import { format } from 'date-fns';
import { EllipsisVertical } from 'lucide-react';
import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import { SHORT_DATE_FORMAT } from '@spektr/client/constants';
import { usePermissionsContext } from '@spektr/client/providers';
import { cn } from '@spektr/style-utils';

import { RBAC } from '@spektr/shared/rbac';
import { ProcessType } from '@spektr/shared/validators';

import { Badge } from '../Badge';

import { DropdownMenuComboBox, DropdownOption } from '../DropdownMenu';
import { ProcessTypeBadge } from '../ProcessTypeBadge';

export type ProcessCardProps = {
  actions?: DropdownOption[];
  children?: React.ReactNode;
  className?: string;
  name: string;
  route: string;
  routeState?: unknown;
  type: ProcessType;
  isLive?: boolean;
  updatedAt?: number;
  onActionClick?: (action: string, ev: MouseEvent) => void;
};

export const ProcessCard = ({
  actions,
  children,
  className,
  route,
  routeState,
  name,
  type,
  isLive,
  updatedAt,
  onActionClick,
}: ProcessCardProps) => {
  const { hasPermission } = usePermissionsContext();

  const options = hasPermission(RBAC.ACTIONS.PROCESS.UPDATE)
    ? actions
    : actions?.map((action) => ({ ...action, disabled: true }));
  return (
    <Link
      to={route}
      state={routeState}
      className={cn(
        'flex flex-col',
        'border-color-border-card rounded-md border',
        'overflow-hidden transition-colors',
        'bg-color-bg-card-default',
        'hover:bg-color-bg-card--hover',
        className
      )}
    >
      <div className={cn('flex flex-grow flex-col gap-3 p-4')}>
        <div className="flex flex-row">
          <ProcessTypeBadge type={type} />
          <div className="ml-auto flex gap-2">
            {isLive && <Badge>Live</Badge>}
            {isLive === false && <Badge>Not Live</Badge>}
            {options && (
              <DropdownMenuComboBox
                trigger={
                  <EllipsisVertical
                    className={cn(
                      'ml-auto w-5',
                      'stroke-color-stroke-primary',
                      'h-5 cursor-pointer',
                      'hover:stroke-color-stroke-primary--hover'
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    data-cy={'card-more-options-' + name}
                  />
                }
                options={options}
                onClick={onActionClick}
              />
            )}
          </div>
        </div>
        <div className="text-color-text-primary line-clamp-2 text-sm">
          {name}
        </div>
        {children && <div className="text-xs">{children}</div>}
      </div>
      {updatedAt && (
        <div className="border-color-border-card flex h-10 items-center border-t px-4 py-3">
          <span className="text-color-text-subtext line-clamp-1 text-xs">
            {`Last updated: ${
              updatedAt ? format(updatedAt, SHORT_DATE_FORMAT) : ''
            }`}
          </span>
        </div>
      )}
    </Link>
  );
};
