import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';

import {
  filterNodeSourceDataInputSchema,
  filterNodeSourceProcessInputSchema,
} from '@spektr/shared/validators';
import { RBAC } from '@spektr/shared/rbac';
import { useSaveProcessFilter } from '@spektr/shared/hooks';
import { FilterNodeSourceInputSchema, Process } from '@spektr/shared/types';

import { ActionButton } from '@spektr/client/components';

import { useSourceProvider } from '../../providers/SourceProvider';
import { useTriggerOperations } from '../../hooks/useTriggerOperations';

export type FooterActionsProps = {
  process: Process;
  onClose: () => void;
};

export const FooterActions = ({ process, onClose }: FooterActionsProps) => {
  const { t } = useTranslation('common');
  const [isPending, setIsPending] = useToggle(false);
  const {
    isFormDirty,
    getSelectedMethod,
    getFilterSource,
    getFilterProcess,
    initialTrigger,
    trigger,
  } = useSourceProvider();

  const updateNode = useSaveProcessFilter(process, onClose);
  const { createTrigger, updateTrigger, removeTrigger } = useTriggerOperations(
    process.id
  );

  const handleClickSave = async () => {
    let source: FilterNodeSourceInputSchema | undefined;

    const triggerFrequencyChange =
      trigger?.frequency && trigger?.frequency !== initialTrigger?.frequency;
    const triggerIntervalChange =
      trigger?.interval && trigger?.interval !== initialTrigger?.interval;

    if (triggerFrequencyChange || triggerIntervalChange) {
      trigger.id ? await updateTrigger(trigger) : await createTrigger(trigger);
    }

    if (!trigger && initialTrigger) {
      await removeTrigger(initialTrigger.id);
    }

    if (getSelectedMethod() === 'process') {
      const parsed =
        filterNodeSourceProcessInputSchema.safeParse(getFilterProcess());
      if (parsed.success) {
        source = parsed.data;
      }
    } else {
      const filterSource = getFilterSource();

      if (filterSource?.filter) {
        const parsed = filterNodeSourceDataInputSchema.safeParse({
          ...filterSource,
          filter: filterSource.filter,
        });
        if (!parsed.success) {
          throw parsed.error;
        }

        source = parsed.data;
      } else {
        source = filterSource;
      }
    }

    if (source) {
      setIsPending(true);

      try {
        await updateNode(source);
      } catch (err) {
        console.error(err);
      }

      setIsPending(false);
    }
  };
  const isValid =
    getSelectedMethod() === 'process'
      ? filterNodeSourceProcessInputSchema.safeParse(getFilterProcess()).success
      : filterNodeSourceDataInputSchema.safeParse(getFilterSource()).success;

  return (
    <div className="mt-auto flex justify-end">
      <ActionButton
        rbacAction={RBAC.ACTIONS.NODE.UPDATE}
        color="cyan"
        className="px-10"
        isPending={isPending}
        pendingLabel={t('actions.savePending')}
        disabled={!isFormDirty || !isValid}
        onClick={handleClickSave}
      >
        {t('actions.save')}
      </ActionButton>
    </div>
  );
};
