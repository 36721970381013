import { useState } from 'react';
import { useFetcher } from 'react-router-dom';
import { Button, Input, Label } from '@spektr/client/components';
import { NewPasswordInputLabel } from '@spektr/model-builder/auth';

export const ChangePasswordForm = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [allowError, setAllowError] = useState(false);
  const fetcher = useFetcher();

  const error =
    fetcher?.data?.action === 'error'
      ? fetcher?.data?.error?.message
      : undefined;

  const showError = error && allowError && fetcher.state === 'idle';

  return (
    <fetcher.Form
      method="post"
      onChange={() => setAllowError(false)}
      onSubmit={() => setAllowError(true)}
    >
      <div className="text-color-text-subtext space-y-6">
        <div>
          <Label htmlFor="oldPassword">Old password:</Label>
          <Input
            className="mt-2"
            id="oldPassword"
            type="password"
            name="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
        <div>
          <NewPasswordInputLabel htmlFor="newPassword" />
          <Input
            className="mt-2"
            id="newPassword"
            required
            name="newPassword"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        {showError && <div className="text-color-red text-sm">{error}</div>}
      </div>
      <Button
        type="submit"
        className="mt-8 w-full"
        disabled={!oldPassword || !newPassword || fetcher.state !== 'idle'}
      >
        {fetcher.state === 'submitting'
          ? 'Updating password...'
          : 'Update password'}
      </Button>
    </fetcher.Form>
  );
};
