import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef,
} from 'react';
import { Root, Indicator } from '@radix-ui/react-checkbox';

import { CheckIcon } from '@radix-ui/react-icons';

import { cn } from '@spektr/style-utils';

export type CheckboxProps = ComponentPropsWithoutRef<typeof Root> & {
  label?: ReactNode;
};

export type CheckboxRef = ElementRef<typeof Root>;

export const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(
  ({ className, label, ...props }, ref) => {
    return (
      <div className={cn('flex items-center gap-2', className)}>
        <Root
          ref={ref}
          className={cn(
            'peer',
            'h-4 w-4 shrink-0',
            'rounded border border-zinc-500',
            'transition-colors',
            'disabled:cursor-not-allowed disabled:bg-zinc-300 disabled:opacity-50',
            'focus:border-blue-400 focus-visible:shadow-md focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue-400',
            'data-[state=checked]:border-blue-400 data-[state=checked]:bg-blue-400 data-[state=checked]:text-black',
            className
          )}
          {...props}
        >
          <Indicator
            className={cn('flex items-center justify-center text-current')}
          >
            <CheckIcon className="h-3 w-3" />
          </Indicator>
        </Root>
        {label && (
          <label
            className={cn('text-primary-foreground text-sm font-medium')}
            htmlFor={props.id}
          >
            {label}
          </label>
        )}
      </div>
    );
  }
);
