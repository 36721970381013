import { cn } from '@spektr/style-utils';

import { SpektrField } from '@spektr/shared/types';

import { RiskMatrixSegment } from '@spektr/shared/components';

import { AddMatrix } from './add-matrix';
import { AddSegmentButton } from './buttons';
import { useIncompleteCalculationNode } from './providers';
import { MatrixSegmentContainer, SegmentContainer } from './segment';

export type CalculationNodeFormDialogContentProps = {
  spektrFields: SpektrField[];
};

export const CalculationNodeFormDialogContent = ({
  spektrFields,
}: CalculationNodeFormDialogContentProps) => {
  const { incomplete } = useIncompleteCalculationNode();

  return (
    <div className="-mr-3 flex flex-col gap-6 overflow-y-auto">
      {incomplete.segments.map((segment) => (
        <div className="mr-3" key={segment.clientSideOnlyId}>
          {(segment as RiskMatrixSegment).riskMatrixId ? (
            <MatrixSegmentContainer
              segment={segment as RiskMatrixSegment}
              spektrFields={spektrFields}
            />
          ) : (
            <SegmentContainer segment={segment} spektrFields={spektrFields} />
          )}
        </div>
      ))}

      <div className="mr-3">
        <div className={cn('flex items-center gap-4')}>
          <AddSegmentButton className="basis-1/2" />
          <AddMatrix className="basis-1/2" />
        </div>
      </div>
    </div>
  );
};
