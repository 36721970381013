import { useMemo } from 'react';

import { DropdownOption } from '@spektr/client/components';

import {
  ClientRecordFilter,
  PaginationOptions,
} from '@spektr/shared/validators';

import { CasesFiltersConfigurations } from '../types';

export const useGetCasesFiltersOptions = (
  filters: ClientRecordFilter & PaginationOptions,
  configurations: CasesFiltersConfigurations
) => {
  const separatorOption = useMemo<DropdownOption>(
    () => ({
      type: 'separator',
      value: '',
    }),
    []
  );

  const filtersOptions: DropdownOption[] = useMemo(() => {
    const getSelectedStateForFilter = (filterIdentifier: string) => {
      const filterValue = Object.entries(filters).find(
        ([identifier, value]) => identifier === filterIdentifier && !!value
      )?.[1];

      if (filterValue) {
        if (configurations[filterIdentifier].selectionType === 'single') {
          return !!filterValue;
        } else {
          return (filterValue as string[]).length > 0;
        }
      }
      return false;
    };

    const getDisabledStateForFilter = (filterIdentifier: string) => {
      const conflictingFiltersList =
        configurations[filterIdentifier].conflictingFilters;

      if (conflictingFiltersList && conflictingFiltersList.length > 0) {
        return conflictingFiltersList.some((conflictingFilterIdentifier) =>
          getSelectedStateForFilter(conflictingFilterIdentifier)
        );
      }
      return false;
    };

    const options: DropdownOption[] = [];
    const configurationsList = Object.entries(configurations);

    configurationsList.forEach(
      ([filterIdentifier, filterConfiguration], index) => {
        options.push({
          label: filterConfiguration.label,
          value: filterIdentifier,
          type: 'item',
          icon: filterConfiguration.icon,
          selected: getSelectedStateForFilter(filterIdentifier),
          disabled: getDisabledStateForFilter(filterIdentifier),
        });
        if (index !== configurationsList.length - 1) {
          options.push(separatorOption);
        }
      }
    );

    return options;
  }, [configurations, filters, separatorOption]);

  return useMemo(() => ({ filtersOptions }), [filtersOptions]);
};
