import { ChevronRight, File, X } from 'lucide-react';

import { Skeleton } from '@spektr/client/components';

import { cn } from '@spektr/style-utils';

export const NodeRunDataSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <Skeleton className="h-5 w-28" />
        <X className="stroke-color-stroke-secondary h-5 w-5" />
      </div>
      <div
        className={cn(
          'flex items-center gap-4 p-4',
          'rounded-md border',
          'bg-color-bg-card-default'
        )}
      >
        <File className="m-3 h-4 w-4" />
        <div className="flex flex-col gap-1">
          <Skeleton className="h-4 w-40" />
          <Skeleton className="h-3 w-20" />
        </div>
        <Skeleton className="ml-auto h-9 w-9" />
      </div>
      <div className="flex items-center justify-between">
        <p className="text-sm font-medium">Data extracted from this document</p>
        <ChevronRight className="stroke-color-text-icon-secondary h-4 w-4" />
      </div>
    </div>
  );
};
