import { v4 as uuid } from 'uuid';

import { assertUnreachable } from '@spektr/shared/utils';

import {
  createCalculationNodeSchema,
  createReturningProcessNodeSchema,
  createMonitoringDatasetNodeSchema,
  CreateComplyAdvantageKybNode,
  CreateComplyAdvantageKycNode,
  CreateOpenCorporatesRiskNode,
  CreateOpenCorporatesMonitoringNode,
  createSlackNodeSchema,
  createRouterNodeSchema,
  CreateFormNode,
  CreateOwnershipTreeFormNode,
  CreateBodaccNode,
  createAlertNodeSchema,
  CreateKyckrNode,
  CreateVeriffNode,
  createMitIdNodeSchema,
  NodeType,
  createFilterNodeSchema,
  createLoopSourceNodeSchema,
  createOnboardingProcessSourceNodeSchema,
  createOutcomeNodeSchema,
  createManualReviewNodeSchema,
  CreateAiAmlAlertNode,
  createCustomerStatusNodeSchema,
  CreateVeriffIdvNode,
  CreateVirkNode,
  CreateDocumentOCRSchema,
} from '@spektr/shared/validators';

type RelationshipArg = {
  predecessorId?: string;
  successorId?: string;
  edgeType?: string;
};

export function createNodeFactory(
  nodeType: NodeType,
  relationship: RelationshipArg,
  nodeMetadata?: Record<string, unknown>
) {
  switch (nodeType) {
    case 'calculation':
      return createCalculationNode(relationship);
    case 'returningProcess':
      return createReturningProcessNode(relationship);
    case 'monitoringDataset':
      return createMonitoringDatasetNode(relationship);
    case 'slack':
      return createSlackNode(relationship);
    case 'router':
      return createRouterNode(relationship);
    case 'complyAdvantageKyb':
      return createComplyAdvantageKybService(relationship);
    case 'complyAdvantageKyc':
      return createComplyAdvantageKycService(relationship);
    case 'openCorporatesMonitoring':
      return createOpenCorporatesMonitoringService(relationship);
    case 'openCorporatesRisk':
      return createOpenCorporatesRiskService(relationship);
    case 'bodacc':
      return createBodaccService(relationship);
    case 'kyckr':
      return createKyckrService(relationship);
    case 'alert':
      return createAlertStepNode(relationship);
    case 'manualReview':
      return createManualReviewNode(relationship);
    case 'customerStatus':
      return createCustomerStatusNode(relationship, nodeMetadata);
    case 'form':
      return createFormNode(relationship);
    case 'ownershipTreeForm':
      return createOwnershipTreeFormNode(relationship);
    case 'mitId':
      return createMitIdService(relationship);
    case 'veriff':
      return createVeriffService(relationship);
    case 'veriffIdv':
      return createVeriffIdvService(relationship);
    case 'virk':
      return createVirkService(relationship);
    case 'filter':
      return createFilterNodeSchema.parse(
        createBaseNode('filter', 'New filter node', relationship)
      );
    case 'loopSource': {
      return createLoopSourceNodeSchema.parse({
        ...createBaseNode('loopSource', 'New loop source node', relationship),
        channelSettings: [{ type: 'email', messageBody: [], mapping: {} }],
      });
    }
    case 'onboardingProcessSource':
      return createOnboardingProcessSourceNodeSchema.parse(
        createBaseNode(
          'onboardingProcessSource',
          'New onboarding process source node',
          relationship
        )
      );
    case 'aiAmlAlert':
      return CreateAiAmlAlertNode.parse(
        createBaseNode('aiAmlAlert', 'spektrAI Alert', relationship)
      );
    case 'outcome':
      return createOutcomeNodeSchema.parse(
        createBaseNode('outcome', 'New outcome node', relationship)
      );
    case 'documentOCR':
      return CreateDocumentOCRSchema.parse(
        createBaseNode('documentOCR', 'Document OCR', relationship)
      );
    default:
      assertUnreachable(nodeType);
  }
}

function createBaseNode(
  nodeType: NodeType,
  title: string,
  relationship: RelationshipArg,
  fields?: Record<string, boolean>
) {
  return {
    nodeType,
    predecessorId: relationship?.predecessorId,
    successorId: relationship?.successorId,
    edgeType: relationship?.edgeType,
    title,
    ...(fields !== undefined && { fields }), // include fields in the response only if not undefined
  };
}

function createCalculationNode(relationship: RelationshipArg) {
  return createCalculationNodeSchema.parse({
    ...createBaseNode('calculation', 'New calculation node', relationship),
    segments: [],
  });
}

function createReturningProcessNode(relationship: RelationshipArg) {
  return createReturningProcessNodeSchema.parse({
    ...createBaseNode(
      'returningProcess',
      'New returning process node',
      relationship
    ),
  });
}

function createMonitoringDatasetNode(relationship: RelationshipArg) {
  return createMonitoringDatasetNodeSchema.parse({
    ...createBaseNode(
      'monitoringDataset',
      'New field watch node',
      relationship
    ),
  });
}

function createSlackNode(relationship: RelationshipArg) {
  return createSlackNodeSchema.parse({
    ...createBaseNode('slack', 'New slack node', relationship),
  });
}

function createRouterNode(relationship: RelationshipArg) {
  return createRouterNodeSchema.parse({
    ...createBaseNode('router', 'New router node', relationship),
    groups: [],
  });
}

function createComplyAdvantageKybService(relationship: RelationshipArg) {
  return CreateComplyAdvantageKybNode.parse(
    createBaseNode(
      'complyAdvantageKyb',
      'ComplyAdvantage (Company) Service',
      relationship
    )
  );
}

function createComplyAdvantageKycService(relationship: RelationshipArg) {
  return CreateComplyAdvantageKycNode.parse(
    createBaseNode(
      'complyAdvantageKyc',
      'ComplyAdvantage (Individual) Service',
      relationship
    )
  );
}

function createOpenCorporatesMonitoringService(relationship: RelationshipArg) {
  return CreateOpenCorporatesMonitoringNode.parse(
    createBaseNode(
      'openCorporatesMonitoring',
      'OpenCorporates Monitoring Service',
      relationship
    )
  );
}

function createOpenCorporatesRiskService(relationship: RelationshipArg) {
  return CreateOpenCorporatesRiskNode.parse(
    createBaseNode(
      'openCorporatesRisk',
      'OpenCorporates Risk Service',
      relationship
    )
  );
}

function createBodaccService(relationship: RelationshipArg) {
  return CreateBodaccNode.parse(
    createBaseNode('bodacc', 'BODACC', relationship)
  );
}

function createKyckrService(relationship: RelationshipArg) {
  return CreateKyckrNode.parse(createBaseNode('kyckr', 'Kyckr', relationship));
}

function createVeriffService(relationship: RelationshipArg) {
  return CreateVeriffNode.parse(
    createBaseNode('veriff', 'Veriff', relationship)
  );
}

function createVeriffIdvService(relationship: RelationshipArg) {
  return CreateVeriffIdvNode.parse(
    createBaseNode('veriffIdv', 'Veriff Upload', relationship)
  );
}

function createMitIdService(relationship: RelationshipArg) {
  return createMitIdNodeSchema.parse(
    createBaseNode('mitId', 'MitId', relationship)
  );
}

function createFormNode(relationship: RelationshipArg) {
  return CreateFormNode.parse({
    ...createBaseNode('form', 'New form node', relationship),
    form: [],
  });
}

function createOwnershipTreeFormNode(relationship: RelationshipArg) {
  const defaultEntityId = uuid();
  return CreateOwnershipTreeFormNode.parse({
    ...createBaseNode(
      'ownershipTreeForm',
      'New owner tree form node',
      relationship
    ),
    moonrakerForm: {
      fields: {
        [defaultEntityId]: {
          id: defaultEntityId,
          type: 'entity',
          config: {
            spektrDataField: 'entity',
            prefillDefaultValue: true,
          },
          ui: {
            style: {
              width: '100%',
            },
          },
          isStructured: true,
          validation: [],
          attributes: {
            label: 'Owners',
            buttonText: 'Press to add an entity',
          },
          form: {
            fields: {},
            order: [],
          },
        },
      },
      order: [defaultEntityId],
    },
  });
}

function createAlertStepNode(relationship: RelationshipArg) {
  return createAlertNodeSchema.parse({
    ...createBaseNode('alert', 'Service Alert', relationship),
    alerts: [],
  });
}

function createManualReviewNode(relationship: RelationshipArg) {
  return createManualReviewNodeSchema.parse({
    ...createBaseNode('manualReview', 'Manual Review', relationship),
  });
}

function createCustomerStatusNode(
  relationship: RelationshipArg,
  nodeMetadata?: Record<string, unknown>
) {
  const nodeTitle = nodeMetadata?.['customerStatus.rejected']
    ? 'Reject customer'
    : 'Approve customer';

  const nodeFields: Record<string, boolean> = nodeMetadata?.[
    'customerStatus.rejected'
  ]
    ? { 'customerStatus.rejected': true }
    : { 'customerStatus.approved': true };

  return createCustomerStatusNodeSchema.parse({
    ...createBaseNode('customerStatus', nodeTitle, relationship, nodeFields),
  });
}

function createVirkService(relationship: RelationshipArg) {
  return CreateVirkNode.parse(createBaseNode('virk', 'Virk', relationship));
}
