import { assertUnreachable } from '@spektr/shared/utils';

import { type AlertField } from '@spektr/client/types';
import { type AllowedAlertsField } from '@spektr/shared/validators';

/**
 * @description
 *    Maps the alert field from the API to the corresponding
 *  field in the spektrAI node (check AiAmlAlertNodeInput).
 *
 * @param alertField The alert field from the API
 * @returns The corresponding field in the spektrAI node
 */
export function mapAlertFieldToAiField(
  alertField: AlertField
): AllowedAlertsField {
  switch (alertField.name) {
    case 'has_adverse_media':
      return 'adverseMedia';

    case 'is_pep':
    case 'is_PEP_class_1':
    case 'is_PEP_class_2':
    case 'is_PEP_class_3':
    case 'is_PEP_class_4':
      return 'pep';

    case 'has_sanctions':
      return 'sanctions';

    default:
      assertUnreachable(`Unhandled alert field: ${alertField.name}` as never);
  }
}
