import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Download, Eye } from 'lucide-react';

import {
  casesServiceDataUrl,
  loopNodeDetails,
  processBuilderUrl,
} from '@spektr/shared/utils';
import { ServiceRunEventData } from '@spektr/shared/validators';

import {
  AsyncButton,
  Button,
  NodeIcon,
  TextTruncate,
} from '@spektr/client/components';

import { useDownloadServiceJson } from '../../hooks/useDownloadServiceJson';

import { CustomerTimelineCell } from '../CustomerTimelineCell';

export type ServiceRunEventProps = {
  event: ServiceRunEventData;
};

export const ServiceRunEvent = ({ event }: ServiceRunEventProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const downloadServiceData = useDownloadServiceJson(
    event.eventData.processName,
    event.eventData.nodeType,
    event.spektrId,
    event.eventData.vendorSearchId ?? ''
  );

  return (
    <>
      <CustomerTimelineCell
        renderIcon={() => (
          <NodeIcon
            size="xl"
            className="mt-0.5"
            nodeType={event.eventData.nodeType}
          />
        )}
        className="col-span-4"
        label="Service"
        value={
          <Link
            to={
              event.eventData.processType === 'loop'
                ? loopNodeDetails(
                    event.eventData.processId,
                    event.eventData.nodeId
                  )
                : processBuilderUrl(
                    event.eventData.processId,
                    event.eventData.nodeId
                  )
            }
          >
            <TextTruncate
              text={`${t(`process:nodes.${event.eventData.nodeType}.name`)}`}
            />
          </Link>
        }
      />
      <div className="col-span-6 ml-auto flex items-center gap-1.5">
        <Button
          color="icon"
          endIcon={<Eye className="h-4 w-4" />}
          onClick={() =>
            navigate(
              casesServiceDataUrl(
                event.spektrId,
                event.eventData.vendorSearchId ?? ''
              )
            )
          }
        >
          Inspect
        </Button>
        <AsyncButton
          color="icon"
          isPending={downloadServiceData.isPending}
          endIcon={<Download className="h-4 w-4" />}
          label="Download"
          pendingLabel="Downloading..."
          onClick={() => downloadServiceData.mutate()}
        />
      </div>
    </>
  );
};
