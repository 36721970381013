import { ImgHTMLAttributes } from 'react';

const DEFAULT_CDN_URL =
  'https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/';
const DEFAULT_CDN_SUFFIX = 'svg';

type CountryFlagProps = ImgHTMLAttributes<HTMLImageElement> & {
  countryCode: string;
};

export const CountryFlag = ({ countryCode, ...props }: CountryFlagProps) => {
  if (typeof countryCode !== 'string') {
    return null;
  }

  const flagUrl = `${DEFAULT_CDN_URL}${countryCode.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;

  return (
    <img
      {...props}
      src={flagUrl}
      className="inline-block h-3 w-4 rounded-sm align-middle"
    />
  );
};
