import { HTMLAttributes } from 'react';

export const VeriffIdvIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="123"
    height="49"
    viewBox="0 0 123 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_17343_13801)">
      <path
        d="M10.4768 28.57C10.0341 26.3321 9.03808 23.2596 5.68107 13.7011H0L7.78378 33.5388H13.0958L20.4739 13.7009H14.9034C11.7309 23.0698 10.8457 26.0666 10.4768 28.57Z"
        fill="#03282C"
      />
      <path
        d="M30.5005 13.2837C24.672 13.2837 20.54 17.7216 20.54 23.6769C20.54 29.8215 24.8193 33.9181 30.7954 33.9181C34.5922 33.9181 37.8375 31.8934 39.38 29.0057L35.4804 26.8252C34.7056 28.3802 33.1195 29.4802 30.8324 29.4802C28.1396 29.4802 26.0367 27.7353 25.7786 25.3079H40.4236C40.6819 17.3423 36.3658 13.2837 30.5005 13.2837ZM25.7417 21.5525C25.9261 19.2389 27.8813 17.6079 30.5005 17.6079C33.1197 17.6079 35.0009 19.2389 35.1116 21.5525H25.7417Z"
        fill="#03282C"
      />
      <path
        d="M61.4508 13.7012H56.2125V33.5391H61.4508V13.7012Z"
        fill="#03282C"
      />
      <path
        d="M86.9081 9.30115C87.6089 9.30115 88.4207 9.41488 89.2321 9.64232V5.31831C88.3469 5.01499 87.1295 4.78711 85.9122 4.78711C82.4446 4.78711 78.9768 6.68373 78.9768 12.2975V13.701H71.3374V13.0561C71.0421 10.2112 72.1488 9.30115 74.0302 9.30115C74.7311 9.30115 75.5428 9.41488 76.3543 9.64232V5.31831C75.469 5.01499 74.2517 4.78711 73.0344 4.78711C69.5668 4.78711 66.099 6.68373 66.099 12.2975V13.701H63.8118V17.9097H66.099V33.5388H71.3374V17.9097H78.9768V33.5388H84.2152V17.9097H89.2321V13.701H84.2152V13.0561C83.9201 10.2112 85.0267 9.30115 86.9081 9.30115Z"
        fill="#03282C"
      />
      <path
        d="M48.2629 19.7799V19.0053L47.6931 13.7012H43.1353V33.539H48.2629V25.5001C48.2629 21.3067 51.0787 18.7061 54.1685 18.7061V13.4736C50.5407 13.8448 48.263 15.9922 48.2629 19.7799Z"
        fill="#03282C"
      />
      <path d="M61.493 6.5354H56.1703V10.8529H61.493V6.5354Z" fill="#03282C" />
      <path
        d="M112.359 13.5279V26.9183L112.403 26.9178C113.278 20.1537 117.169 15.7328 123 13.6283V-0.000488281C116.295 1.20202 112.359 5.56111 112.359 13.5279Z"
        fill="#03282C"
      />
      <path
        d="M99.8551 24.9807L110.496 33.9995V16.1118L99.8551 7.09302V24.9807Z"
        fill="#03282C"
      />
      <path
        d="M89.264 46.78C88.616 46.78 88.044 46.648 87.548 46.384C87.06 46.112 86.68 45.736 86.408 45.256C86.136 44.768 86 44.208 86 43.576V38.2H87.176V43.576C87.176 43.992 87.264 44.36 87.44 44.68C87.624 45 87.872 45.248 88.184 45.424C88.504 45.6 88.872 45.688 89.288 45.688C89.704 45.688 90.072 45.6 90.392 45.424C90.712 45.248 90.96 45 91.136 44.68C91.312 44.36 91.4 43.992 91.4 43.576V38.2H92.588V43.576C92.588 44.208 92.448 44.768 92.168 45.256C91.896 45.736 91.508 46.112 91.004 46.384C90.508 46.648 89.928 46.78 89.264 46.78Z"
        fill="#03282C"
      />
      <path
        d="M94.3767 49V40.6H95.4447L95.5167 41.704V49H94.3767ZM97.4847 46.744C96.9807 46.744 96.5407 46.612 96.1647 46.348C95.7967 46.084 95.5127 45.716 95.3127 45.244C95.1127 44.772 95.0127 44.224 95.0127 43.6C95.0127 42.968 95.1127 42.42 95.3127 41.956C95.5127 41.484 95.7967 41.116 96.1647 40.852C96.5407 40.588 96.9807 40.456 97.4847 40.456C98.0207 40.456 98.4927 40.588 98.9007 40.852C99.3087 41.116 99.6247 41.484 99.8487 41.956C100.081 42.42 100.197 42.968 100.197 43.6C100.197 44.224 100.081 44.772 99.8487 45.244C99.6247 45.716 99.3087 46.084 98.9007 46.348C98.4927 46.612 98.0207 46.744 97.4847 46.744ZM97.2087 45.712C97.5687 45.712 97.8847 45.624 98.1567 45.448C98.4287 45.264 98.6407 45.016 98.7927 44.704C98.9527 44.384 99.0327 44.016 99.0327 43.6C99.0327 43.184 98.9567 42.82 98.8047 42.508C98.6527 42.188 98.4407 41.94 98.1687 41.764C97.8967 41.58 97.5807 41.488 97.2207 41.488C96.8927 41.488 96.5967 41.58 96.3327 41.764C96.0767 41.94 95.8767 42.188 95.7327 42.508C95.5887 42.828 95.5167 43.192 95.5167 43.6C95.5167 44.016 95.5887 44.384 95.7327 44.704C95.8767 45.016 96.0767 45.264 96.3327 45.448C96.5887 45.624 96.8807 45.712 97.2087 45.712Z"
        fill="#03282C"
      />
      <path d="M101.442 46.6V38.2H102.582V46.6H101.442Z" fill="#03282C" />
      <path
        d="M106.872 46.744C106.272 46.744 105.744 46.612 105.288 46.348C104.832 46.076 104.472 45.704 104.208 45.232C103.952 44.76 103.824 44.216 103.824 43.6C103.824 42.984 103.952 42.44 104.208 41.968C104.464 41.496 104.82 41.128 105.276 40.864C105.732 40.592 106.256 40.456 106.848 40.456C107.44 40.456 107.964 40.592 108.42 40.864C108.876 41.128 109.232 41.496 109.488 41.968C109.744 42.44 109.872 42.984 109.872 43.6C109.872 44.216 109.744 44.76 109.488 45.232C109.232 45.704 108.876 46.076 108.42 46.348C107.972 46.612 107.456 46.744 106.872 46.744ZM106.872 45.7C107.232 45.7 107.552 45.612 107.832 45.436C108.112 45.252 108.328 45.004 108.48 44.692C108.64 44.38 108.72 44.016 108.72 43.6C108.72 43.184 108.64 42.82 108.48 42.508C108.328 42.196 108.108 41.952 107.82 41.776C107.532 41.592 107.208 41.5 106.848 41.5C106.48 41.5 106.156 41.592 105.876 41.776C105.596 41.952 105.376 42.196 105.216 42.508C105.056 42.82 104.976 43.184 104.976 43.6C104.976 44.016 105.056 44.38 105.216 44.692C105.376 45.004 105.6 45.252 105.888 45.436C106.176 45.612 106.504 45.7 106.872 45.7Z"
        fill="#03282C"
      />
      <path
        d="M114.805 46.6L114.757 45.556V43.468C114.757 43.028 114.705 42.66 114.601 42.364C114.505 42.068 114.353 41.844 114.145 41.692C113.945 41.54 113.677 41.464 113.341 41.464C113.029 41.464 112.757 41.528 112.525 41.656C112.301 41.784 112.113 41.984 111.961 42.256L110.953 41.872C111.097 41.6 111.277 41.36 111.493 41.152C111.709 40.936 111.969 40.768 112.273 40.648C112.577 40.52 112.933 40.456 113.341 40.456C113.909 40.456 114.381 40.568 114.757 40.792C115.133 41.016 115.413 41.34 115.597 41.764C115.789 42.188 115.881 42.712 115.873 43.336L115.861 46.6H114.805ZM113.041 46.744C112.329 46.744 111.773 46.584 111.373 46.264C110.981 45.936 110.785 45.484 110.785 44.908C110.785 44.292 110.989 43.824 111.397 43.504C111.813 43.176 112.389 43.012 113.125 43.012H114.793V43.912H113.437C112.885 43.912 112.497 44 112.273 44.176C112.049 44.344 111.937 44.58 111.937 44.884C111.937 45.164 112.041 45.384 112.249 45.544C112.465 45.696 112.761 45.772 113.137 45.772C113.465 45.772 113.749 45.704 113.989 45.568C114.229 45.424 114.417 45.224 114.553 44.968C114.689 44.712 114.757 44.42 114.757 44.092H115.153C115.153 44.908 114.977 45.556 114.625 46.036C114.281 46.508 113.753 46.744 113.041 46.744Z"
        fill="#03282C"
      />
      <path
        d="M121.701 46.6L121.653 45.484V38.2H122.781V46.6H121.701ZM119.685 46.744C119.141 46.744 118.665 46.612 118.257 46.348C117.857 46.084 117.541 45.716 117.309 45.244C117.085 44.772 116.973 44.224 116.973 43.6C116.973 42.968 117.085 42.42 117.309 41.956C117.541 41.484 117.857 41.116 118.257 40.852C118.665 40.588 119.141 40.456 119.685 40.456C120.189 40.456 120.625 40.588 120.993 40.852C121.369 41.116 121.657 41.484 121.857 41.956C122.057 42.42 122.157 42.968 122.157 43.6C122.157 44.224 122.057 44.772 121.857 45.244C121.657 45.716 121.369 46.084 120.993 46.348C120.625 46.612 120.189 46.744 119.685 46.744ZM119.949 45.712C120.285 45.712 120.581 45.624 120.837 45.448C121.093 45.264 121.293 45.016 121.437 44.704C121.581 44.384 121.653 44.016 121.653 43.6C121.653 43.184 121.581 42.82 121.437 42.508C121.293 42.188 121.093 41.94 120.837 41.764C120.581 41.58 120.281 41.488 119.937 41.488C119.585 41.488 119.273 41.58 119.001 41.764C118.729 41.94 118.517 42.188 118.365 42.508C118.213 42.82 118.137 43.184 118.137 43.6C118.137 44.016 118.213 44.384 118.365 44.704C118.525 45.016 118.741 45.264 119.013 45.448C119.285 45.624 119.597 45.712 119.949 45.712Z"
        fill="#03282C"
      />
    </g>
    <defs>
      <clipPath id="clip0_17343_13801">
        <rect width="123" height="49" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
