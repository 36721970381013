import { ChangeEvent } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { FormField, Input } from '@spektr/components-forms';

import {
  isCurrencyField,
  type InputField as InputFieldProp,
} from '@spektr/moonraker-types';

export type InputFieldProps = {
  field: InputFieldProp;
  name: string;
};

const supportedInputTypes = ['text', 'email', 'number'];

export const InputField = ({ field, name }: InputFieldProps) => {
  const { control, clearErrors } = useFormContext();

  const requiredValidation = field.validation?.find(
    (v) => v.type === 'required'
  );
  const regexValidation = field.validation?.find((v) => v.type === 'regex');

  const inputType = ['currency', 'percentage'].includes(field.attributes.type)
    ? 'number'
    : field.attributes.type;

  const {
    field: formField,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: {
      required: requiredValidation?.message,
      pattern: regexValidation
        ? {
            value: new RegExp(regexValidation.value),
            message: regexValidation.message,
          }
        : undefined,
    },
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (regexValidation) {
      const regex = new RegExp(regexValidation.value);
      if (!regex.test(event.target.value)) {
        control.setError(name, {
          type: 'pattern',
          message: regexValidation.message,
        });
      } else {
        clearErrors(name);
      }
    }

    formField.onChange(event.target.value);
  };

  return (
    <FormField
      label={field.attributes.label}
      required={!!requiredValidation}
      helperText={error ? error.message : field.attributes.helperText}
      name={name}
      hasError={invalid || !!error}
    >
      <Input
        ref={formField.ref}
        name={name}
        type={supportedInputTypes.includes(inputType) ? inputType : 'text'}
        hasError={invalid || !!error}
        value={formField.value}
        placeholder={field.attributes.placeholder}
        prefix={
          isCurrencyField(field) ? field.attributes.currency?.symbol : undefined
        }
        suffix={field.attributes.type === 'percentage' ? '%' : undefined}
        style={field.ui?.style}
        onChange={handleChange}
      />
    </FormField>
  );
};
