import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProcessType } from '@spektr/shared/validators';
import { getColorBasedOnProcessType } from '@spektr/shared/utils';

import { ProcessTypeBadge } from './ProcessTypeBadge';

export function useProcessTypeVariants(processType: ProcessType) {
  const { t } = useTranslation();

  return useMemo<ProcessTypeBadge.Types>(
    () => ({
      variant: getColorBasedOnProcessType(processType),
      label: t(`process:processTypes.${processType}`),
    }),
    [processType, t]
  );
}
