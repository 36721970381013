import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonWithTooltip } from '@spektr/client/components';

import { HitDecision, SpektrAiDetails } from '@spektr/shared/validators';

import { useHitsProviderContext } from '../../providers';

export type HitResolutionButtonsProps = {
  id: string;
  hitType: 'aml' | 'kyb';
  decision: HitDecision;
  isDisabled?: boolean;
  spektrAiDetails: SpektrAiDetails | undefined;
  onFalsePositiveClick: (hitId: string) => void;
  onTruePositiveClick: (hitId: string) => void;
};

export const HitResolutionButtons = ({
  id,
  hitType,
  decision,
  isDisabled,
  spektrAiDetails,
  onFalsePositiveClick,
  onTruePositiveClick,
}: HitResolutionButtonsProps) => {
  const { t } = useTranslation('cases');
  const { resetSelectedHits } = useHitsProviderContext();

  const falsePositiveLabel = t(`alerts.hit.action.${hitType}.false-positive`);
  const truePositiveLabel = t(`alerts.hit.action.${hitType}.true-positive`);

  const handleClickDecision = (event: MouseEvent<HTMLButtonElement>) => {
    resetSelectedHits?.();

    if (event.currentTarget.name === 'true-positive') {
      return onTruePositiveClick(id);
    }

    if (event.currentTarget.name === 'false-positive') {
      return onFalsePositiveClick(id);
    }

    throw new Error('Invalid decision');
  };

  return (
    <div className="ml-auto flex flex-row gap-4">
      <ButtonWithTooltip
        color="red"
        name={hitType === 'aml' ? 'true-positive' : 'false-positive'}
        variant="outlined"
        disabled={isDisabled}
        onClick={handleClickDecision}
        showTooltip={
          spektrAiDetails?.status === 'pending' && decision.status === 'pending'
        }
        tooltipProps={{
          content:
            'spektr-ai will automatically resolve this hit shortly. However, feel free to resolve it now.',
        }}
        children={falsePositiveLabel}
      />

      <ButtonWithTooltip
        showTooltip={
          spektrAiDetails?.status === 'pending' && decision.status === 'pending'
        }
        color="cyan"
        disabled={isDisabled}
        name={hitType === 'aml' ? 'false-positive' : 'true-positive'}
        variant="outlined"
        onClick={handleClickDecision}
        tooltipProps={{
          content:
            'spektr-ai will automatically resolve this hit shortly. However, feel free to resolve it now.',
        }}
        children={truePositiveLabel}
      />
    </div>
  );
};
