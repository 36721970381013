import { cn } from '@spektr/style-utils';

import { Button, DatePicker, DatePickerProps } from '@spektr/client/components';

import { HelperTextTooltip } from '../../HelperTextTooltip';

export type DateFieldProps = DatePickerProps & {
  name: string;
  label: string;
  helperText?: string;
  onClearDate?: () => void;
};

export const DateField = ({
  label,
  helperText,
  onClearDate,
  ...props
}: DateFieldProps) => {
  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row items-center gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={props.name}
        >
          {label}
        </label>
        {helperText && <HelperTextTooltip helperText={helperText} />}
        <Button
          className={cn('ml-auto', 'h-auto', 'transition-opacity', {
            'opacity-0': !props.epochOrToday,
          })}
          variant="text"
          size="sm"
          onClick={onClearDate}
        >
          Clear date
        </Button>
      </div>
      <DatePicker popoverSide="top" {...props} />
    </div>
  );
};
