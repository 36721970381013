export const simulateDocumentDownload = (
  data: object,
  filename: string,
  type: string
) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], { type });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
