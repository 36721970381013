import { memo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import {
  getRootNode,
  hasAdditionalBranches,
  loopNodeDetails,
  loopSheetUrl,
  processBuilderSheetUrl,
  processBuilderUrl,
} from '@spektr/shared/utils';
import { RBAC } from '@spektr/shared/rbac';
import { useDeleteNode } from '@spektr/shared/hooks';

import { AlertDialog } from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';
import { cn } from '@spektr/style-utils';

import {
  ProcessNode,
  isAiAmlAlert,
  isCustomerStatusNodeType,
  isComplyAdvantageNodeType,
} from '@spektr/shared/types';

import { AiAmlAlertNode } from '../AiAmlAlertNode';

import { AdditionalBranches } from '../AdditionalBranches';

import { GraphNodeProps } from '../types';

import { NODE } from '../constants';

import { NewFallbackButton } from './components/NewFallbackButton';

import { NodeDeleteButton } from './components/NodeDeleteButton';
import { BasicNodeContent } from './components/BasicNodeContent';

type BasicNodeProps = GraphNodeProps<ProcessNode>;

export const BasicNode = memo(({ data }: BasicNodeProps) => {
  const { node, process } = data;
  const navigate = useNavigate();
  const deleteNode = useDeleteNode(process.id, node.id);
  const [pendingDelete, setPendingDelete] = useState(false);
  const { hasPermission } = usePermissionsContext();
  const canDeleteNode = hasPermission(RBAC.ACTIONS.NODE.DELETE);

  const handleDeleteNode = async () => {
    await deleteNode.mutateAsync();
    setPendingDelete(false);
  };

  const handleAddFallbackNode = () => {
    const href =
      data.process.type === 'loop'
        ? loopSheetUrl(data.process.id)
        : processBuilderSheetUrl(data.process.id);

    // Adding a new node as a fallback always follows this pattern:
    // before:
    // A → B → C
    // after adding B1 as fallback of B:
    // A → B → C
    //     ↓   ↑
    //    B1 → ↑
    // thus B's successor is still C. process-api will also set C as B1's successor automatically.
    navigate(href, {
      state: {
        predecessorNode: data.node,
        edgeType: 'fallback',
      },
    });
  };

  const isRootNode = getRootNode(process)?.id === node.id;

  const href =
    data.process.type === 'loop'
      ? loopNodeDetails(process.id, node.id)
      : processBuilderUrl(process.id, node.id);

  const nodeHasAdditionalBranches = hasAdditionalBranches(
    process.type,
    node.nodeType,
    node.adj
  );

  return (
    <>
      <div
        className={cn(
          'group',
          'relative',
          'flex min-h-0 flex-col gap-4 rounded-lg',
          'cursor-pointer duration-200 hover:shadow-lg',
          !isAiAmlAlert(data.node) &&
            'bg-color-bg-process-builder-item hover:border-color-blue',
          !isAiAmlAlert(data.node) && 'border p-3',
          nodeHasAdditionalBranches && 'rounded-b-none'
        )}
        style={{
          height: NODE.HEIGHT,
          width: NODE.WIDTH,
        }}
        data-cy={`${node.nodeType}-node`}
      >
        <Handle
          type="target"
          id={node.id}
          position={Position.Left}
          className={cn(
            isRootNode
              ? 'opacity-0'
              : cn(
                  'h-2 w-2',
                  'border-color-border-process-builder-item border',
                  'bg-color-bg-process-builder-item'
                )
          )}
        />

        <Link
          to={href}
          className="flex h-full w-full flex-row items-center gap-3"
          onClick={(e) => {
            if (isCustomerStatusNodeType(node)) {
              e.preventDefault(); // customer status nodes are not clickable
            }
          }}
        >
          {isAiAmlAlert(node) ? (
            <AiAmlAlertNode title={node.title} nodeType={node.nodeType} />
          ) : (
            <BasicNodeContent
              nodeType={node.nodeType}
              title={'title' in node ? node.title : undefined}
              fields={'fields' in node ? node.fields : undefined}
              label={isComplyAdvantageNodeType(node)}
            />
          )}
        </Link>

        {nodeHasAdditionalBranches && (
          <AdditionalBranches
            processId={process.id}
            processType={process.type}
            node={node}
          />
        )}

        <NodeDeleteButton
          disabled={!canDeleteNode}
          onClick={canDeleteNode ? () => setPendingDelete(true) : undefined}
        />
        <NewFallbackButton
          className={cn(nodeHasAdditionalBranches && '-bottom-8')}
          onClick={handleAddFallbackNode}
        />

        <Handle
          type="source"
          id={node.id}
          position={Position.Right}
          className={cn(
            'h-2 w-2',
            'border-color-border-process-builder-item border',
            'bg-color-bg-process-builder-item'
          )}
        />
      </div>
      <AlertDialog
        open={!!pendingDelete}
        title="Are you sure?"
        paragraph="You will permanently delete this step from the process."
        onCancel={() => setPendingDelete(false)}
        cancel="Cancel"
        onConfirm={handleDeleteNode}
        confirm="Yes, delete"
        confirmDataCy="Yes-delete"
        disableButtons={deleteNode.isPending}
      />
    </>
  );
});
