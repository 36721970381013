import { cn } from '@spektr/style-utils';

import { SpektrDataflowIcon } from './SpektrDataflow';
import { IconWithBackground } from './IconWithBackground';

type RouterIconProps = {
  className?: string;
  iconClassName?: string;
};

export const RouterIcon = ({ className, iconClassName }: RouterIconProps) => {
  return (
    <IconWithBackground
      className={cn(
        'bg-color-red/10 border-color-red ml-5 mr-4 h-9 w-9 p-1',
        className
      )}
    >
      <SpektrDataflowIcon
        className={cn('stroke-color-red h-4 w-4', iconClassName)}
      />
    </IconWithBackground>
  );
};
