'use client';

import { MouseEvent, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { Label } from '@spektr/components-forms';

import { AddNewEntryButton } from './components/AddNewEntryButton';
import { EmptyList } from './components/EmptyList';
import { EntityItem } from './components/EntityItem';

import type { EntitiesField as EntityFieldProp } from '@spektr/moonraker-types';

export type EntityFieldProps = {
  field: EntityFieldProp;
  name: string;
};

export const EntityField = ({ field, name }: EntityFieldProps) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const [collapsedId, setCollapsedId] = useState<string | undefined>();
  const { control } = useFormContext();
  const requiredValidation = field.validation?.find(
    (v) => v.type === 'required'
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name,
    rules: {
      required: requiredValidation?.message,
    },
  });

  const handleClickAddEntity = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const emptyEntityItem = {
      temporaryEntityId: uuid(),
    };

    setCollapsedId(emptyEntityItem.temporaryEntityId);
    append(emptyEntityItem);
  };

  const handleToggleEntity = (fieldId: string) => {
    setCollapsedId((prev) => (prev === fieldId ? undefined : fieldId));
  };
  const handleClickDelete = (fieldId: string) => {
    remove(fields.findIndex((field) => field.id === fieldId));
    setCollapsedId(undefined);
  };

  if (!isClient) {
    return;
  }

  return (
    <div className="flex flex-col items-start gap-1">
      <Label htmlFor={name} className="mb-1" required={!!requiredValidation}>
        {field.attributes.label}
      </Label>

      {fields.length > 0 ? (
        <div className="flex w-full flex-col gap-1">
          {fields.map((innerField, index) => (
            <EntityItem
              key={innerField.id}
              entityField={field}
              field={innerField}
              name={`${name}.${index}`}
              isCollapsed={innerField.id === collapsedId}
              onClickDelete={handleClickDelete}
              onClickToggle={handleToggleEntity}
            />
          ))}
        </div>
      ) : (
        <EmptyList />
      )}

      <AddNewEntryButton className="mt-1" onClick={handleClickAddEntity}>
        {field.attributes.buttonText}
      </AddNewEntryButton>
    </div>
  );
};
