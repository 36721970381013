import { useLayoutEffect, useState } from 'react';
import { Viewport } from 'reactflow';
import { useMeasure } from 'react-use';

import 'reactflow/dist/style.css';

import { NODE_HEIGHT, NODE_WIDTH } from './constants/graph';

import { GraphStateProvider } from './providers/GraphState';
import { ConnectionsGraph } from './containers/ConnectionsGraph';

import type { MoonrakerForm } from '@spektr/moonraker-types';
import type { Edge as GraphEdge } from './types/Edge';
import type { Node as GraphNode } from './types/Node';
import type { NodeRecordType as NodeRecordTypeInternal } from './types/NodeData';

import type {
  NavigateToNodeFn,
  SaveEdgeFn,
  SaveNodeFn,
} from './types/GraphState';

export namespace MoonrakerGraph {
  export type Node = GraphNode;
  export type Edge = GraphEdge;
  export type NodeRecordType = NodeRecordTypeInternal;

  export type Props = {
    className?: string;
    canAddNodes?: boolean;
    canEditNodes?: boolean;
    canEditEdges?: boolean;
    spektrId?: string;
    form?: MoonrakerForm;
    nodes: MoonrakerGraph.Node[];
    edges: MoonrakerGraph.Edge[];
    onSaveNode?: SaveNodeFn;
    onSaveEdge?: SaveEdgeFn;
    onNavigateToNode?: NavigateToNodeFn;
  };
}

export const MoonrakerGraph = ({
  className,
  edges,
  nodes,
  spektrId,
  ...props
}: MoonrakerGraph.Props) => {
  const [canvasRef, { height, width }] = useMeasure<HTMLDivElement>();

  // we cannot use `height` and `width` here on first render bc they are both 0 on first render
  const [viewPort, setViewPort] = useState<Viewport | undefined>(undefined);

  useLayoutEffect(() => {
    if (!height || !width) return;

    // set initial viewport position to be center of canvas
    setViewPort((prev) => ({
      zoom: prev?.zoom ?? 1,
      x: width * 0.5 - NODE_WIDTH * 0.5,
      y: height * 0.5 - NODE_HEIGHT * 0.5,
    }));
  }, [height, width]);

  return (
    <div ref={canvasRef} className={className}>
      <GraphStateProvider {...props}>
        <ConnectionsGraph
          spektrId={spektrId}
          defaultViewPort={viewPort}
          initialNodes={nodes}
          initialEdges={edges}
          onMoveEnd={setViewPort}
        />
      </GraphStateProvider>
    </div>
  );
};
