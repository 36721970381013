import { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { getSourcesFromRootNode } from '@spektr/shared/utils';

import {
  ActionButton,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  NodeIcon,
} from '@spektr/client/components';

import { NodeType, type ChannelSettings } from '@spektr/shared/validators';
import {
  type ProcessSource,
  type Process,
  type SpektrField,
} from '@spektr/shared/types';
import { RBAC } from '@spektr/shared/rbac';

import { apiToFormValues } from './utils/apiToFormValues';
import { formValuesToApi } from './utils/formValuesToApi';

import { SourceTabView } from './containers/SourceTabView';
import { ChannelTabView } from './containers/ChannelTabView';

import { TabNavigation } from './components/TabNavigation';

import { type SettingsTab } from './types/SettingsTab';
import {
  ChannelSettingsValidationSchema,
  type ChannelSettingsFormValues,
} from './types/ChannelSettingsFormValues';

export type SourceSettingsProps = {
  defaultTab?: SettingsTab;
  channelSettings?: ChannelSettings;
  isUpdatePending?: boolean;
  nodeTitle: string;
  nodeType: NodeType;
  process: Process;
  processes: Process[];
  spektrFields: SpektrField[];
  onClickUpdate: (
    sources: ProcessSource[],
    settings: ChannelSettings | null
  ) => void;
  onClose: () => void;
};

export const SourceSettings = ({
  defaultTab = 'source',
  channelSettings,
  isUpdatePending = false,
  nodeTitle,
  nodeType,
  process,
  processes,
  spektrFields,
  onClickUpdate,
  onClose,
}: SourceSettingsProps) => {
  const defaultSettings = useMemo(
    () => apiToFormValues(channelSettings),
    [channelSettings]
  );

  const formInstance = useForm<ChannelSettingsFormValues>({
    mode: 'onChange',
    defaultValues: defaultSettings,
    resolver: zodResolver(ChannelSettingsValidationSchema),
  });

  const [settingsTab, setSettingsTab] = useState<SettingsTab>(defaultTab);
  const [selectedSources, updateSelectedSources] = useState<ProcessSource[]>(
    getSourcesFromRootNode(process)
  );

  const handleClickUpdate = async () => {
    const settings = formValuesToApi(formInstance.getValues());
    onClickUpdate(selectedSources, settings);
  };

  const disabledReason = useMemo(() => {
    if (selectedSources.length === 0) {
      return 'Please select at least one source';
    }

    if (process.type === 'onboarding' && selectedSources.length > 1) {
      return 'Onboarding process can have only one source';
    }

    if (!formInstance.formState.isValid) {
      return 'Please fix the errors in the channel settings';
    }

    return '';
  }, [selectedSources.length, process.type, formInstance.formState.isValid]);

  return (
    <div>
      <DialogHeader className="flex flex-col gap-4 space-y-0">
        <div className="flex items-center gap-2">
          <NodeIcon size="lg" nodeType={nodeType} />
          <DialogTitle className="text-color-text-dialog-title">
            {nodeTitle}
          </DialogTitle>
          <div className="ml-auto flex flex-row gap-4">
            <DialogClose onClick={onClose} />
          </div>
        </div>
        <TabNavigation activeTab={settingsTab} onChangeTab={setSettingsTab} />
      </DialogHeader>
      <SourceTabView
        activeTab={settingsTab}
        process={process}
        processes={processes}
        onUpdateSelection={updateSelectedSources}
      />
      <ChannelTabView
        activeTab={settingsTab}
        formInstance={formInstance}
        spektrFields={spektrFields}
      />

      <DialogFooter>
        <ActionButton
          fullWidth
          color="red"
          rbacAction={RBAC.ACTIONS.PROCESS.UPDATE}
          className="mt-4"
          disabled={!!disabledReason}
          isPending={isUpdatePending}
          pendingLabel={'Updating...'}
          getDisabledReason={() => disabledReason}
          onClick={handleClickUpdate}
        >
          Update node
        </ActionButton>
      </DialogFooter>
    </div>
  );
};
