import { CountryFlag } from '@spektr/components-forms';

import type { NodeData } from '../../types/NodeData';

export namespace NodeSubText {
  export type Props = {
    data: NodeData;
  };
}

export const NodeSubText = ({ data }: NodeSubText.Props) => {
  if (!data?.subtext) {
    return null;
  }

  if (data.type === 'company') {
    return (
      <div className="flex items-center gap-1">
        {data?.data?.country_of_incorporation && (
          <CountryFlag
            countryCode={data.data.country_of_incorporation.toString()}
          />
        )}
        <span className="text-xs text-zinc-700 dark:text-zinc-300">
          {data?.subtext}
        </span>
      </div>
    );
  }

  if (data.type === 'individual') {
    return (
      data?.data?.nationality && (
        <CountryFlag countryCode={data.data.nationality.toString()} />
      )
    );
  }

  return (
    <span className="text-xs text-zinc-700 dark:text-zinc-300">
      {data?.subtext}
    </span>
  );
};
