import { useEffect } from 'react';
import { DropdownProps, useDayPicker, useNavigation } from 'react-day-picker';

import { cn } from '@spektr/style-utils';

import { Select } from '@spektr/components-forms';

import { monthNames } from '../../constants';

import type { SelectOption } from '../../types/SelectOption';

interface Props extends DropdownProps {
  getDate: (type: SelectOption, value: string) => Date;
  updateMonth: (newMonth: number) => void;
  updateYear: (newYear: number) => void;
}

export const CalendarDropdown = ({
  getDate,
  updateMonth,
  updateYear,
  name,
  caption,
}: Props) => {
  const { fromYear, toYear } = useDayPicker();
  const navigation = useNavigation();

  useEffect(() => {
    if (name === 'months') {
      updateMonth(monthNames.indexOf(caption as string));
    } else {
      updateYear(Number(caption));
    }
  }, [caption, name, updateMonth, updateYear]);

  if (!fromYear || !toYear) return null;

  const options =
    name === 'months'
      ? Array.from({ length: 12 }, (_, i) => ({
          value: monthNames[i],
          label: monthNames[i],
        }))
      : Array.from({ length: toYear - fromYear + 1 }, (_, i) => ({
          value: String(fromYear + i),
          label: String(fromYear + i),
        }));

  if (!options.length) return null;

  const handleValueChange = (value: string) => {
    navigation.goToMonth(getDate(name as SelectOption, value));
  };

  return (
    <Select
      key={caption as string}
      defaultValue={caption as string}
      options={options}
      name={name}
      onValueChange={handleValueChange}
      className={cn(
        'z-[1] flex h-auto items-center px-2 py-1',
        'rounded-md border-0',
        'text-white opacity-50 [&>span]:text-sm [&>span]:font-medium',
        'hover:bg-zinc-800 hover:opacity-100',
        'text-zinc-600',
        'focus-visible:ring-ring focus-visible:outline-none focus-visible:ring-1'
      )}
      triggerClassName={cn({
        'w-32': name === 'months',
        'w-24': name === 'years',
      })}
    />
  );
};
