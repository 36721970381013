import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import { LOOPS_SOURCE_URL } from '@spektr/shared/utils';
import { LoopSourceNode as LoopSourceNodeType } from '@spektr/shared/types';
import { NODE } from '@spektr/shared/components';

import { cn } from '@spektr/style-utils';

import { NodeIcon } from '@spektr/client/components';

import { GraphNodeProps } from './types';

export const LoopSourceNode = memo(
  ({ data }: GraphNodeProps<LoopSourceNodeType>) => {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate(LOOPS_SOURCE_URL);
    };

    return (
      <div
        className={cn(
          'grid border border-solid duration-200 sm:rounded-lg',
          'flex min-h-0 flex-col gap-4',
          'bg-color-bg-process-builder-item',
          'cursor-pointer duration-200',
          'hover:border-color-blue hover:shadow-lg'
        )}
        style={{
          width: NODE.WIDTH,
          height: NODE.HEIGHT,
        }}
        onClick={handleClick}
      >
        <div
          className="flex h-full w-full flex-row items-center gap-4 p-3"
          onClick={handleClick}
        >
          <NodeIcon size="xl" nodeType={data.node.nodeType} />
          <div className="flex flex-col">
            <h3 className="text-color-text-node text-sm font-medium">
              Loop settings
            </h3>
            <span className="text-color-text-node-subtext text-xs">{`${data.node.sources.length} sources`}</span>
          </div>
        </div>
        <Handle
          type="source"
          id="root"
          position={Position.Right}
          isConnectable={false}
          className={cn(
            'h-2 w-2',
            'border-color-border-process-builder-item border',
            'bg-color-bg-process-builder-item'
          )}
        />
      </div>
    );
  }
);
