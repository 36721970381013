import {
  ClientRecordFilter,
  PaginationOptions,
  TimelineEventsPaginated,
} from '@spektr/shared/validators';

import { InsightsApiClient } from '../client';
import { CUSTOMERS_KEYS } from '../queryKeys';

export function getAllCustomers(
  filters: ClientRecordFilter & PaginationOptions
) {
  // TODO(ST-2990): support k-v search via query params
  const arrayQueries: { [key: string]: string } = {};

  if (filters.datasetIds && filters.datasetIds.length > 0) {
    arrayQueries['datasetIds'] = filters.datasetIds.toString();
  }
  if (filters.status && filters.status.length > 0) {
    arrayQueries['status'] = filters.status.toString();
  }
  if (filters.tags && filters.tags.length > 0) {
    arrayQueries['tags'] = filters.tags.toString();
  }
  if (filters.alertPresence && filters.alertPresence.length > 0) {
    arrayQueries['alertPresence'] = filters.alertPresence.toString();
  }
  if (filters.alertType && filters.alertType.length > 0) {
    arrayQueries['alertType'] = filters.alertType.toString();
  }

  return {
    queryKey: CUSTOMERS_KEYS.ALL(filters),
    queryFn: () =>
      InsightsApiClient.getClient().getAllClientRecords({
        queries: {
          page: filters.page ?? 1,
          limit: filters.limit ?? 10,
          searchQuery: filters?.searchQuery,
          isUbo: filters.isUbo,
          createdAtStart: filters.createdAtStart,
          createdAtEnd: filters.createdAtEnd,
          updatedAtStart: filters.updatedAtStart,
          updatedAtEnd: filters.updatedAtEnd,
          dataKey: 'role',
          dataOperator: 'nin',
          dataValue: 'owner,representative',
          ...arrayQueries,
        },
      }),
  };
}

export function getCustomerDetails(customerId: string) {
  return {
    queryKey: CUSTOMERS_KEYS.BY_ID(customerId),
    queryFn: () =>
      InsightsApiClient.getClient().getClientRecordDetails({
        params: {
          clientRecordId: customerId,
        },
      }),
  };
}

export function getCustomerTimeline(customerId: string, limit = 10, page = 1) {
  return {
    queryKey: CUSTOMERS_KEYS.TIMELINE(customerId, page, limit),
    queryFn: ({ pageParam }: { pageParam: number }) =>
      InsightsApiClient.getClient().getClientRecordTimeline({
        params: {
          clientRecordId: customerId,
        },
        queries: {
          page: pageParam,
          limit,
        },
      }),
    initialPageParam: page,
    getNextPageParam: (lastPage: TimelineEventsPaginated) => lastPage.nextPage,
  };
}

export function getCustomerDocuments(customerId: string) {
  return {
    queryKey: CUSTOMERS_KEYS.DOCUMENTS(customerId),
    queryFn: () =>
      InsightsApiClient.getClient().getClientRecordDocuments({
        params: {
          clientRecordId: customerId,
        },
      }),
  };
}

export function getCustomerOwnersAndControllers(customerId: string) {
  return {
    queryKey: CUSTOMERS_KEYS.OWNERS_AND_CONTROLLERS(customerId),
    queryFn: () =>
      InsightsApiClient.getClient().getClientRecordOwnersAndControllers({
        params: {
          clientRecordId: customerId,
        },
      }),
  };
}

export function getServiceData(customerId: string, vendorSearchId: string) {
  return {
    queryKey: CUSTOMERS_KEYS.SERVICE_DATA(customerId, vendorSearchId),
    queryFn: () =>
      InsightsApiClient.getClient().getServiceData({
        params: {
          clientRecordId: customerId,
          vendorSearchId,
        },
      }),
  };
}

export function getOwnershipGraph(customerId: string) {
  return {
    queryKey: CUSTOMERS_KEYS.CONNECTIONS(customerId),
    queryFn: () =>
      InsightsApiClient.getClient().getOwnershipGraph({
        params: {
          id: customerId,
        },
      }),
  };
}
