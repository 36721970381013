import { useSuspenseQuery } from '@tanstack/react-query';

import { cn } from '@spektr/style-utils';

import { getApiKeysQuery } from '@spektr/client/services';

import { ActionButton, TableRow } from '@spektr/client/components';

import { RBAC } from '@spektr/shared/rbac';
import { ApiKeySchema } from '@spektr/shared/validators';

import { Trash2 } from 'lucide-react';

import { ApiKeysTable } from '../ApiKeysTable';
import { KeyPrivacy } from '../KeyPrivacy';
import { SettingsTableCell } from '../SettingsTableCell';

export type ListApiKeysProps = {
  onDelete: (id: string) => void;
};

export const ListApiKeys = ({ onDelete }: ListApiKeysProps) => {
  const { data } = useSuspenseQuery({
    ...getApiKeysQuery(),
    retry: false,
  });

  const renderTableRows = (apiKey: ApiKeySchema) => {
    return (
      <TableRow key={apiKey.id}>
        <SettingsTableCell>{apiKey.name}</SettingsTableCell>
        <SettingsTableCell>
          <KeyPrivacy value={apiKey.key} />
        </SettingsTableCell>
        <SettingsTableCell className="text-right">
          <ActionButton
            variant="text"
            rbacAction={RBAC.ACTIONS.USER.DELETE}
            className="group disabled:cursor-not-allowed disabled:opacity-50"
            onClick={() => onDelete(apiKey.id)}
          >
            <Trash2
              className={cn(
                'h-5 w-5',
                'text-color-text-icon-tertiary hover:text-color-red',
                'transition-colors duration-200 ease-in-out',
                'group-disabled:hover:text-color-text-icon-tertiary'
              )}
            />
          </ActionButton>
        </SettingsTableCell>
      </TableRow>
    );
  };

  return (
    <div className="overflow-x-auto">
      <ApiKeysTable>
        {data.length > 0 ? (
          data.map(renderTableRows)
        ) : (
          <TableRow>
            <SettingsTableCell>No API keys found</SettingsTableCell>
            <SettingsTableCell />
            <SettingsTableCell />
          </TableRow>
        )}
      </ApiKeysTable>
    </div>
  );
};
