import { type ChannelSettings } from '@spektr/shared/validators';

import { type ChannelSettingsFormValues } from '../types/ChannelSettingsFormValues';

export function apiToFormValues(
  settings?: ChannelSettings
): ChannelSettingsFormValues {
  switch (settings?.type) {
    case 'email':
      return {
        type: settings.type,
        recipientField: settings.mapping.email,
        messageBody: settings.messageBody,
      };

    case 'internalEmail':
      return {
        type: settings.type,
        emailAddress: settings.emailAddress,
        messageBody: settings.messageBody,
      };

    default:
      return { type: 'email' };
  }
}
