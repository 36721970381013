import { ScrollArea, toast } from '@spektr/client/components';

import { KyckrNode, UpdateKyckrNode } from '@spektr/shared/validators';
import { type NodeUpdateInput, type SpektrField } from '@spektr/shared/types';

import { useMonitorsContext } from '../../../../providers/Monitors';

import { FieldsMonitoring } from '../../../FieldsMonitoring';

import { FieldsMapping } from '../../components/FieldsMapping';
import { DialogFooterActions } from '../../../../components/DialogFooterActions';

type FieldsMonitoringContentProps = {
  node: KyckrNode;
  spektrFields: SpektrField[];
  isValidForm: boolean;
  isPendingUpdate?: boolean;
  getMapping: () => Record<string, string>;
  onUpdate: (data: NodeUpdateInput) => Promise<unknown>;
};

export const FieldsMonitoringContent = ({
  node,
  spektrFields,
  isPendingUpdate,
  isValidForm,
  onUpdate,
  getMapping,
}: FieldsMonitoringContentProps) => {
  const { monitors, areMonitorsValid } = useMonitorsContext();

  const handleSave = async () => {
    const mapping = getMapping();

    const parsedNode = KyckrNode.parse(node);

    const updatedNode = UpdateKyckrNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields: node.fields,
      mapping,
      monitors,
    });

    await onUpdate(updatedNode);

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  return (
    <>
      <ScrollArea className="max-h-[70vh] overflow-y-auto">
        <div className="flex flex-col gap-6">
          <FieldsMapping spektrFields={spektrFields} />
          <FieldsMonitoring serviceName="Kyckr" />
        </div>
      </ScrollArea>
      <DialogFooterActions
        btnColor="kyckr"
        disabled={!isValidForm || !areMonitorsValid}
        isPendingUpdate={isPendingUpdate}
        onSave={handleSave}
      />
    </>
  );
};
