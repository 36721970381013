import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  getChannelSettingsFromRootNode,
  getRootNode,
} from '@spektr/shared/utils';

import {
  getAllowedSpektrFieldsQuery,
  getProcessByIdQuery,
  getProcessesQuery,
} from '@spektr/client/services';

import { Dialog, DialogContent, toast } from '@spektr/client/components';

import { type ChannelSettings } from '@spektr/shared/validators';
import {
  type OnboardingProcessSourceNode,
  type NodeUpdateInput,
  type ProcessSource,
} from '@spektr/shared/types';

import { SourceSettings } from '../../../processes';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

export namespace OnboardingSourceDialog {
  export type Props = BaseDialogProps<OnboardingProcessSourceNode>;
}

export const OnboardingSourceDialog = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
  onClose,
}: OnboardingSourceDialog.Props) => {
  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: processes } = useSuspenseQuery(
    getProcessesQuery({
      types: ['onboarding'],
    })
  );
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(process.id, node.id)
  );

  const channelSettings = useMemo(() => {
    return getChannelSettingsFromRootNode(process)?.[0];
  }, [process]);

  const filteredProcesses = useMemo(
    () => processes.filter((p) => p.id !== process.id),
    [processes, process]
  );

  const handleUpdateOnboardingProcess = async (
    sources: ProcessSource[],
    settings: ChannelSettings | null
  ) => {
    const rootNode = process ? getRootNode(process) : undefined;
    const result = {
      nodeType: rootNode?.nodeType,
      sources,
      channelSettings: settings ? [settings] : [],
    } as NodeUpdateInput;

    await onUpdate(result);

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  return (
    <Dialog defaultOpen modal={false}>
      <DialogContent
        modal={false}
        className="bg-color-bg-dialog-default absolute flex flex-col"
        requestStartExitAnimation={onClose}
        onEndExitAnimation={onClose}
        onEscapeKeyDown={onClose}
      >
        <SourceSettings
          channelSettings={channelSettings}
          isUpdatePending={isPendingUpdate}
          nodeTitle="Process settings"
          nodeType={node.nodeType}
          process={process}
          processes={filteredProcesses}
          spektrFields={spektrFields}
          onClickUpdate={handleUpdateOnboardingProcess}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};
