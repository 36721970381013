import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { Item, Indicator } from '@radix-ui/react-radio-group';

import { cn } from '@spektr/style-utils';

export namespace RadioGroupItem {
  export type Ref = ElementRef<typeof Item>;
  export type Props = ComponentPropsWithoutRef<typeof Item>;
}

export const RadioGroupItem = forwardRef<
  RadioGroupItem.Ref,
  RadioGroupItem.Props
>(({ className, ...props }, ref) => {
  return (
    <Item
      ref={ref}
      className={cn(
        'h-4 w-4 shrink-0',
        'rounded-full border',
        'border-zinc-500',
        'aspect-square',
        'focus:outline-none',
        'focus:border-blue-500 focus-visible:shadow-md focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue-500',
        'disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    >
      <Indicator className="flex items-center justify-center">
        <div className={cn('h-2 w-2', 'rounded-full', 'bg-black')} />
      </Indicator>
    </Item>
  );
});
