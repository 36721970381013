import { Trash } from 'lucide-react';

import { cn } from '@spektr/style-utils';

export type ConfigHeaderProps = {
  title: string;
  onDelete: () => void;
};

export const ConfigHeader = ({ title, onDelete }: ConfigHeaderProps) => {
  return (
    <div
      className={cn(
        'flex flex-row justify-center',
        'p-3',
        'border-color-border-input border-b'
      )}
    >
      <label className="text-color-text-primary text-sm font-semibold capitalize">
        {title}
      </label>
      <button className="ml-auto" onClick={onDelete}>
        <Trash className="stroke-color-stroke-secondary hover:stroke-color-stroke-primary h-3.5 w-3.5" />
      </button>
    </div>
  );
};
