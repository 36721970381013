import { HTMLAttributes } from 'react';

export const VirkMinimalIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.68353 0C9.79679 0 10.6989 0.95361 10.6989 2.13033C10.6989 3.30611 9.79679 4.26006 8.68353 4.26006C7.57049 4.26006 6.66821 3.30611 6.66821 2.13033C6.66821 0.95361 7.57049 0 8.68353 0Z"
      fill="#0059B3"
    />
    <path
      d="M4.02035 7.99178C4.02035 9.1657 3.1197 10.1182 2.00961 10.1182C0.899522 10.1182 0 9.1657 0 7.99178C0 6.81967 0.899901 5.86829 2.00961 5.86829C3.12008 5.86829 4.02035 6.82004 4.02035 7.99178Z"
      fill="#0059B3"
    />
    <path
      d="M10.7237 7.40139C10.7237 8.60374 9.80387 9.57394 8.6683 9.57394C7.53036 9.57394 6.61035 8.60374 6.61035 7.40139C6.61035 6.20176 7.5307 5.22623 8.6683 5.22623C9.80387 5.22623 10.7237 6.20176 10.7237 7.40139Z"
      fill="#0059B3"
    />
    <path
      d="M17.3781 7.97657C17.3781 9.18115 16.4516 10.1578 15.3112 10.1578C14.1727 10.1578 13.2468 9.18081 13.2468 7.97657C13.2468 6.77089 14.1727 5.79276 15.3112 5.79276C16.4516 5.79276 17.3781 6.77089 17.3781 7.97657Z"
      fill="#0059B3"
    />
    <path
      d="M10.7201 12.4934C10.7201 13.6938 9.80115 14.6682 8.66468 14.6682C7.52726 14.6682 6.60669 13.6938 6.60669 12.4934C6.60669 11.2933 7.52764 10.3186 8.66468 10.3186C9.80115 10.3186 10.7201 11.2933 10.7201 12.4934Z"
      fill="#0059B3"
    />
    <path
      d="M15.7328 12.7949C15.7328 14.0146 14.7982 15 13.6456 15C12.4962 15 11.5609 14.0146 11.5609 12.7949C11.5609 11.578 12.4962 10.5917 13.6456 10.5917C14.7982 10.5917 15.7328 11.5783 15.7328 12.7949Z"
      fill="#0059B3"
    />
    <path
      d="M3.69047 10.6783C4.79376 10.6783 5.68844 11.6236 5.68844 12.7896C5.68844 13.9562 4.79376 14.9011 3.69047 14.9011C2.58723 14.9011 1.6925 13.9562 1.6925 12.7896C1.6925 11.6236 2.58723 10.6783 3.69047 10.6783Z"
      fill="#0059B3"
    />
  </svg>
);
