import { type ChannelSettings } from '@spektr/shared/validators';

import { type ChannelSettingsFormValues } from '../types/ChannelSettingsFormValues';

export function formValuesToApi(
  values: ChannelSettingsFormValues
): ChannelSettings {
  switch (values.type) {
    case 'email':
      return {
        type: values.type,
        mapping: {
          email: values.recipientField,
        },
        messageBody: values.messageBody as any,
      };
    case 'internalEmail':
      return {
        type: values.type,
        emailAddress: values.emailAddress as any,
        messageBody: values.messageBody as any,
      };

    default:
      throw new Error('Invalid channel type');
  }
}
