import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSuspenseQuery } from '@tanstack/react-query';

import { toast } from '@spektr/client/components';

import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';

import {
  type ConfigurableNode,
  type MitIdNode,
  mitIdNodeSchema,
  updateMitIdNodeSchema,
} from '@spektr/shared/validators';

import { mapSelectedFields } from '../../utils/mapSelectedFields';

import { useServiceFields } from '../../hooks/useServiceFields';
import { useSelectedFields } from '../../hooks/useSelectedFields';
import { DialogFooterActions } from '../../components/DialogFooterActions';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { MitIdForm } from './containers/MitIdForm';
import { ContentSkeleton } from './components/ContentSkeleton';

import { getSelectedMitIdFields } from './utils/getSelectedMitIdFields';

export namespace MitIdContent {
  export type Props = BaseDialogProps<MitIdNode>;
}

const MitIdContent = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
}: MitIdContent.Props) => {
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );

  const formInstance = useForm<Record<string, string>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });
  const [configuration, setConfiguration] = useState(
    (node as ConfigurableNode).configuration
  );

  const { fields, serviceFields } = useServiceFields(node);
  const { selectedFields, handleFieldChange } = useSelectedFields(
    node,
    serviceFields
  );

  const handleSave = async () => {
    const selectedServiceFields = getSelectedMitIdFields(selectedFields);
    const fieldsMap = mapSelectedFields(fields, selectedServiceFields);

    const parsedNode = mitIdNodeSchema.parse(node);

    const updatedNode = updateMitIdNodeSchema.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields: fieldsMap,
      configuration,
    });

    await onUpdate(updatedNode);

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  return (
    <FormProvider {...formInstance}>
      <MitIdForm
        key={spektrFields?.length}
        fields={fields}
        loginType={configuration?.loginType}
        selectedFields={selectedFields}
        onChangeField={handleFieldChange}
        onChangeLogin={(loginType) => setConfiguration({ loginType })}
      />
      <DialogFooterActions
        btnColor="mitId"
        disabled={!formInstance.formState.isValid}
        isPendingUpdate={isPendingUpdate}
        onSave={handleSave}
      />
    </FormProvider>
  );
};

export const MitIdDialog = withDialogWrapper(
  MitIdContent,
  <ContentSkeleton />,
  {
    className: 'max-w-[700px]',
  }
);
