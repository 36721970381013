import { HTMLAttributes } from 'react';

export const FlagGbIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="5"
    fill="none"
    viewBox="0 0 7 5"
    {...props}
  >
    <g id="flag-icons-gb" clipPath="url(#clip0_17646_4173)">
      <path id="Vector" fill="#012169" d="M0 0h7v5H0z"></path>
      <path
        id="Vector_2"
        fill="#fff"
        d="m.82 0 2.67 1.885L6.146 0H7v.646L4.375 2.51 7 4.365V5h-.875L3.5 3.135.886 5H0v-.625l2.614-1.854L0 .667V0z"
      ></path>
      <path
        id="Vector_3"
        fill="#C8102E"
        d="M4.638 2.927 7 4.583V5L4.036 2.927zm-2.013.208.066.365L.59 5H0zM7 0v.031L4.277 1.99l.021-.459L6.453 0zM0 0l2.614 1.833h-.656L0 .438z"
      ></path>
      <path
        id="Vector_4"
        fill="#fff"
        d="M2.636 0v5h1.75V0zM0 1.667v1.666h7V1.667z"
      ></path>
      <path
        id="Vector_5"
        fill="#C8102E"
        d="M0 2.01v1h7v-1zM2.986 0v5h1.05V0z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_17646_4173">
        <rect width="7" height="5" fill="#fff" rx="1"></rect>
      </clipPath>
    </defs>
  </svg>
);
