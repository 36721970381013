import { Box, Download, PlaySquareIcon, UploadCloud } from 'lucide-react';

import {
  Button,
  ButtonWithTooltip,
  CasesCustomerActions,
  IconButton,
  IconWithBackground,
  Spinner,
} from '@spektr/client/components';

const iconButtonClassName = 'h-9 w-9 !px-2';

type CustomerTopbarActionsListProps = {
  onList?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
  onDocumentUpload?: () => void;
  onDocumentDownload?: () => void;
  isDocumentDownloading?: boolean;
};

export const CustomerTopbarActionsList = ({
  onList,
  onDelete,
  onUpdate,
  onDocumentUpload,
  onDocumentDownload,
  isDocumentDownloading,
}: CustomerTopbarActionsListProps) => {
  return (
    <div className="flex items-center gap-2">
      {/* TODO: When enabling the button, change className to 'hidden sm:flex' */}
      <Button
        className="hidden w-36 p-2 font-medium"
        color="icon"
        startIcon={
          <IconWithBackground className="bg-color-cyan/20 text-color-cyan h-5 w-5 p-0">
            <Box className="h-2.5 w-2.5" />
          </IconWithBackground>
        }
      >
        <span className="flex w-full items-center justify-between">
          Fetch PII
          <PlaySquareIcon className="h-4 w-4" />
        </span>
      </Button>
      <IconButton
        color="icon"
        className={iconButtonClassName}
        onClick={onDocumentDownload}
        disabled={isDocumentDownloading}
      >
        {isDocumentDownloading ? (
          <Spinner size="sm" />
        ) : (
          <Download className="h-4 w-4" />
        )}
      </IconButton>
      <ButtonWithTooltip
        showTooltip
        tooltipProps={{
          content: 'Upload document',
        }}
        color="icon"
        className={iconButtonClassName}
        onClick={onDocumentUpload}
      >
        <UploadCloud className="h-4 w-4" />
      </ButtonWithTooltip>
      <CasesCustomerActions
        onList={onList}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    </div>
  );
};
