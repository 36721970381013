import { z } from 'zod';

import { BaseField } from './BaseField';
import { FieldValidation } from './FieldValidation';

export const ConsentField = BaseField.merge(
  z.object({
    type: z.literal('consent'),
    attributes: z.object({
      label: z.string(),
    }),
    validation: z.array(FieldValidation),
  })
);

export type ConsentField = z.infer<typeof ConsentField>;

export function isConsentField(field: unknown): field is ConsentField {
  return ConsentField.safeParse(field).success;
}
