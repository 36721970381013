import { useCallback, useMemo } from 'react';
import { FormProvider, useForm, type FormState } from 'react-hook-form';
import { produce } from 'immer';
import parsePhoneNumber from 'libphonenumber-js';

import {
  isEntitiesField,
  isWritableField,
  type Field,
  type FormValues,
} from '@spektr/moonraker-types';

import countryCodes from '../assets/files/countries.json';

import { getCountryDialCode } from './utils/countries';

import { FieldFactory } from './containers/FieldFactory';

export namespace MoonrakerFormViewer {
  export type Props = {
    children: (formState: FormState<FormValues>) => React.ReactNode;
    defaultFields: FormValues;
    fields: Record<string, Field>;
    order: string[];
    onSubmit: (data: FormValues) => Promise<void>;
  };
}

export const MoonrakerFormViewer = ({
  children,
  defaultFields,
  fields,
  order,
  onSubmit,
}: MoonrakerFormViewer.Props) => {
  const formInstance = useForm<FormValues>({
    defaultValues: defaultFields,
  });

  const handleSubmit = useCallback(
    async (data: FormValues) => {
      const values = produce(data, (draft) => {
        const keys = Object.keys(draft);
        const phoneNumbers = keys.filter((key) =>
          key.endsWith('-spektrCountryCode')
        );

        if (phoneNumbers.length > 0) {
          phoneNumbers.forEach((key) => {
            const countryCodeValue = draft[key];
            const [numberKey] = key.split('-spektrCountryCode');
            const phoneNumberValue = draft[numberKey];

            const countryCodeConfig = countryCodes.find(
              (country) => country.code === countryCodeValue
            );

            if (countryCodeConfig) {
              const parsedPhoneNumber = parsePhoneNumber(
                `${getCountryDialCode(countryCodeConfig)}${phoneNumberValue}`
              );

              if (parsedPhoneNumber) {
                draft[numberKey] = parsedPhoneNumber.number;
              }
            }

            delete draft[key];
          });
        }

        const entitiesKeys = Object.values(fields)
          .filter(isEntitiesField)
          .map((field) => field.config.spektrDataField);

        if (entitiesKeys.length > 0) {
          entitiesKeys.forEach((entityKey) => {
            const entityValue = draft[entityKey];

            if (entityValue && Array.isArray(entityValue)) {
              draft[entityKey] = entityValue.map((innerField) => {
                return Object.entries(innerField).reduce(
                  (acc, [key, value]) => {
                    return key === 'temporaryEntityId'
                      ? acc
                      : { ...acc, [key]: value };
                  },
                  {}
                );
              });
            }
          });
        }
      });

      await onSubmit(values);
    },
    [fields, onSubmit]
  );

  const orderedFields = useMemo(
    () =>
      order.map((fieldId) => {
        const field = fields[fieldId];
        const name = isWritableField(field)
          ? field.config.spektrDataField
          : undefined;

        return <FieldFactory key={field.id} field={field} name={name} />;
      }),
    [fields, order]
  );

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={formInstance.handleSubmit(handleSubmit)}>
        {orderedFields}
        {children(formInstance.formState)}
      </form>
    </FormProvider>
  );
};

export default MoonrakerFormViewer;
