import { forwardRef } from 'react';

import { cn } from '@spektr/style-utils';

import { Input, InputProps } from '../../forms';

export const ProcessNameInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    return (
      <Input
        {...props}
        ref={ref}
        className={cn(
          'min-w-80 truncate',
          'border-transparent bg-transparent',
          'text-sm font-medium',
          'enabled:hover:border-color-border-input--hover',
          'disabled:text-color-text-input disabled:cursor-default disabled:bg-transparent disabled:opacity-100'
        )}
        disabled={props.disabled}
        dimension="small"
        data-cy="process-name-input"
      />
    );
  }
);
