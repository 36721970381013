export function getEdgeLabel(value: number | null) {
  if (!value) {
    return 'Unknown';
  }
  if (Math.ceil(value) < 25) {
    return '0% to 25%';
  }
  if (Math.ceil(value) < 50) {
    return '25% to 50%';
  }
  if (Math.ceil(value) < 75) {
    return '50% to 75%';
  }
  return '75% to 100%';
}
