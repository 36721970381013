import { useTranslation } from 'react-i18next';

import {
  NodeServiceType,
  SpektrFieldTypedKey,
} from '@spektr/shared/validators';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@spektr/client/components';

import { NodeIcon } from '../NodeIcon/NodeIcon';

import { ServiceField } from './ServiceField';

type ServiceGroupProps = {
  serviceName: NodeServiceType;
  fields: SpektrFieldTypedKey[];
};

export const ServiceGroup = ({ serviceName, fields }: ServiceGroupProps) => {
  const { t } = useTranslation('process');

  return (
    <Accordion
      key={serviceName}
      collapsible
      defaultValue={`group-${serviceName}`}
      type="single"
      className="w-full"
    >
      <AccordionItem
        value={`group-${serviceName}`}
        className="relative border-none"
      >
        <AccordionTrigger className="justify-start gap-2 px-3 py-2 text-xs font-medium">
          {t(`nodes.${serviceName}.name`)}
          <NodeIcon className="ml-auto" nodeType={serviceName} />
        </AccordionTrigger>
        <AccordionContent className="pl-6">
          {fields.map(({ key }) => (
            <ServiceField key={key} value={key} service={serviceName} />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
