import { useEffect, useState } from 'react';

import { ProcessType } from '@spektr/shared/validators';

import { TextTruncate } from '../TextTruncate';

import { ProcessNameInput } from './ProcessNameInput';

export interface ProcessNameProps {
  name?: string;
  processType?: ProcessType;
  onNameUpdate?: (name: string) => void;
}

const processLabels = {
  risk: 'Risk process',
  score: 'Customer Score process',
  monitoring: 'Monitoring process',
  loop: 'Loop',
  onboarding: 'Onboarding process',
  enrichment: 'Enrichment process',
  custom: 'Custom process',
};

export function ProcessName({
  name = '',
  processType,
  onNameUpdate,
}: ProcessNameProps) {
  const [processName, updateProcessName] = useState(name);
  const isNameEditable = !!onNameUpdate;

  const handleNameUpdate = () => {
    if (!processName.trim() || processName === name) {
      return updateProcessName(name);
    }

    onNameUpdate?.(processName);
  };

  useEffect(() => {
    updateProcessName(name);
  }, [name]);

  if (name) {
    return (
      <div className="mr-4 flex w-full items-center gap-1 text-sm">
        {processType && (
          <span className="text-color-text-subtext shrink-0 font-semibold">{`${processLabels[processType]}: `}</span>
        )}
        {isNameEditable ? (
          <div className="mr-2 lg:mr-0">
            <ProcessNameInput
              defaultValue={name}
              onBlur={handleNameUpdate}
              onChange={(e) => updateProcessName(e.target.value)}
              value={processName}
            />
          </div>
        ) : (
          <TextTruncate className="line-clamp-1" text={name} />
        )}
      </div>
    );
  }

  return null;
}
