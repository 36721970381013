import { ReactNode } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

export type DrawerItemProps = VariantProps<typeof cardHighlightVariants> & {
  isDisabled?: boolean;
  description: string;
  highlight: ReactNode;
  label?: string;
  title: string;
  type: string;
  onClick: () => void;
};

const cardHighlightVariants = cva(
  [
    'relative',
    'flex h-[100px] w-full items-center justify-center',
    'rounded-md border-0 border-color-border-card',
  ],
  {
    variants: {
      variant: {
        cyan: 'dark:bg-color-cyan/5 bg-color-cyan/10',
        red: 'dark:bg-color-red/5 bg-color-red/10',
        yellow: 'dark:bg-color-yellow/40',
        purple: 'dark:bg-color-purple/5 bg-color-purple/10',
        complyAdvantage: 'bg-color-complyAdvantage',
        white: 'bg-color-white dark:border-0 !border',
        companiesHouse: 'bg-color-companiesHouse',
        bodacc: 'bg-color-bg-white dark:border-0 !border',
        kyckr: 'bg-color-bg-white dark:border-0 !border',
        veriff: 'bg-color-veriff',
        veriffIdv: 'bg-color-veriffIdv',
        mitId: 'bg-color-mitId',
        dunBradstreet: 'bg-color-dunBradstreet',
        alert: 'bg-color-pink/5',
        slack: 'bg-color-slack/40',
        virk: 'bg-color-virk/5 dark:bg-color-white',
      },
    },
    defaultVariants: {
      variant: 'cyan',
    },
  }
);

export const DrawerItem = ({
  isDisabled,
  description,
  highlight,
  label,
  title,
  variant,
  onClick,
}: DrawerItemProps) => {
  return (
    <div
      className={cn(
        'group',
        'flex flex-col',
        'p-4',
        'rounded-lg border',
        'bg-color-bg-primary',
        'select-none overflow-hidden',
        !isDisabled && 'cursor-pointer transition-colors',
        !isDisabled && 'hover:bg-color-bg-primary/80',
        !isDisabled && 'active:shadow-lg'
      )}
      onClick={!isDisabled ? onClick : undefined}
    >
      <div className={cardHighlightVariants({ variant })}>
        {label && (
          <div
            className={cn(
              'absolute right-1 top-1',
              'bg-color-bg-primary/80 rounded px-2 py-0.5',
              'text-color-text-primary text-[8px] font-medium uppercase tracking-wide'
            )}
          >
            {label}
          </div>
        )}
        {highlight}
      </div>

      <h3 className="text-color-text-primary mb-1 mt-2 text-sm font-medium">
        {title}
      </h3>

      <p className="text-color-text-primary line-clamp-4 text-[11px] leading-tight opacity-[0.6]">
        {description}
      </p>
    </div>
  );
};
