import { z } from 'zod';

import { messageBlockWithMentions } from '@spektr/shared/validators';

const ChannelSettingsFormValues = z.object({
  type: z.string(),
  recipientField: z.string().optional(),
  emailAddress: z.string().optional(),
  messageBody: z.array(messageBlockWithMentions).optional(),
  apiKey: z.string().optional(),
});

export type ChannelSettingsFormValues = z.infer<
  typeof ChannelSettingsFormValues
>;

export const ChannelSettingsValidationSchema =
  ChannelSettingsFormValues.superRefine((data, ctx) => {
    if (data.type === 'internalEmail') {
      if (
        data.emailAddress &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.emailAddress)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid email address',
          path: ['emailAddress'],
        });
      }
    }
  });
