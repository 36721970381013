import { Controller, useFormContext } from 'react-hook-form';

import { Label, Select } from '@spektr/client/components';

export const TypeField = () => {
  const { control, setValue } = useFormContext();

  const channelTypes = [
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'internalEmail',
      label: 'Internal email',
    },
  ];

  const handleTypeChange = (value: string) => {
    setValue('type', value);
    setValue('recipientField', '');
    setValue('emailAddress', '');
  };
  return (
    <>
      <Label>Type</Label>
      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <Select
            required
            {...field}
            defaultValue={'email'}
            placeholder="Select from dropdown..."
            options={channelTypes}
            onValueChange={handleTypeChange}
          >
            {channelTypes.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      />
    </>
  );
};
