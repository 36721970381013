import { forwardRef } from 'react';
import { Position, useEdges } from 'reactflow';

import { useGraphState } from '../../hooks/useGraphState';

import { CaseIcon } from '../CaseIcon';
import { NameWithTooltip } from '../NameWithTooltip';
import { NodeWrapper } from '../NodeWrapper';
import { StatefulHandle } from '../StatefulHandle';
import { NodeSubText } from '../NodeSubText';

import type { NodeData } from '../../types/NodeData';

export namespace BasicNodeContent {
  export type Props = {
    id: string;
    data: NodeData;
    className?: string;
  };
}

export const BasicNodeContent = forwardRef<
  HTMLDivElement,
  BasicNodeContent.Props
>(({ id, data, className }, ref) => {
  const edges = useEdges();

  const { canAddNodes } = useGraphState();

  const hasTargetEdge = edges.some((edge) => edge.target === id);
  const hasSourceEdge = canAddNodes || edges.some((edge) => edge.source === id);

  return (
    <NodeWrapper className={className} ref={ref} data={data}>
      <StatefulHandle
        className={!hasTargetEdge ? 'opacity-0' : undefined}
        id={id}
        position={Position.Top}
        status={data.status}
        type="target"
      />
      <CaseIcon status={data.status} type={data.type} />
      <div className="flex flex-col gap-0.5">
        <NameWithTooltip name={data.name} />
        <NodeSubText data={data} />
      </div>
      <StatefulHandle
        className={!hasSourceEdge ? 'opacity-0' : undefined}
        id={id}
        position={Position.Bottom}
        status={data.status}
        type="source"
      />
    </NodeWrapper>
  );
});
