import type { FormState } from '../providers/FormEngine/types';

import type { Field } from '@spektr/moonraker-types';
import type { SpektrField } from '@spektr/shared/types';

export const DEFAULT_STATE: FormState = {
  fields: {},
  order: [],
  selectedFieldsId: null,
  branding: {
    primaryColor: 'rgba(0, 0, 0, 1)',
    borderColor: 'rgba(231, 231, 233, 1)',
    textColor: 'rgba(0, 0, 0, 1)',
    subTextColor: 'rgba(114, 114, 126, 1)',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    fontFamily: 'Arial',
    borderRadius: 0,
    gapSpacing: 0,
  },
  assistingContent: {
    title: 'Welcome!',
    description: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'This is the content of the sidebar. You can briefly detail the use case of this page in here.',
          },
        ],
      },
    ],

    support: {
      display: true,
      title: 'Need any help?',
      content: [
        {
          type: 'paragraph',
          children: [
            {
              text: 'The Support Box',
              bold: true,
            },
            {
              text: ' allows you to add information like ',
            },
            {
              // TODO: (@Alex) - Fix SlateTextElement to accept SlateLinkElement
              // https://www.notion.so/Fix-Slate-types-1bd54820d851441a905a93e62a38db66?pvs=4
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              type: 'link',
              url: 'https://www.spektr.com/company/contact-us',
              children: [
                {
                  text: 'contact details',
                },
              ],
            },
            {
              text: ', to assist your customers. It provides them with ',
            },
            {
              text: 'guidance and support',
              italic: true,
            },
            {
              text: ' as they complete your form.',
            },
          ],
        },
      ],
      backgroundColor: 'rgba(0, 46, 115, 1)',
      textColor: 'rgba(255, 255, 255, 1)',
    },
  },
};

export const FIELD_TYPE_MAPPING: Partial<
  Record<Field['type'], SpektrField['type'][]>
> = {
  input: ['string', 'number', 'date', 'country'],
  checkbox: ['boolean'],
  radio: ['string'],
  select: ['string'],
  file: ['file'],
};
