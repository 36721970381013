import { ReactNode, useMemo, useState } from 'react';

import { DEFAULT_ROUTE } from '../../constants/visuals';

import { FormRouterContext } from './context';

import type { FormRoutes } from '../../types';

export type FormRouterProviderProps = {
  children: ReactNode | ReactNode[];
  route?: FormRoutes;
};

export const FormRouter = ({
  children,
  route = DEFAULT_ROUTE,
}: FormRouterProviderProps) => {
  const [currentRoute, setCurrentRoute] = useState<FormRoutes>(route);

  const value = useMemo(
    () => ({
      currentRoute,
      isActiveRoute: (routeId: FormRoutes) =>
        currentRoute === routeId || currentRoute.includes(routeId),
      navigateTo: setCurrentRoute,
    }),
    [currentRoute, setCurrentRoute]
  );

  return (
    <FormRouterContext.Provider value={value}>
      {children}
    </FormRouterContext.Provider>
  );
};
