import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useSuspenseQuery } from '@tanstack/react-query';

import { toast } from '@spektr/client/components';

import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';

import { UpdateVeriffIdvNode, VeriffIdvNode } from '@spektr/shared/validators';

import { ServiceCommonMappingForm } from '../../components/ServiceCommonMappingForm';
import { DialogFooterActions } from '../../components/DialogFooterActions';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { ContentSkeleton } from './components/ContentSkeleton';

export namespace VeriffIdvContent {
  export type Props = BaseDialogProps<VeriffIdvNode>;
}

export const VeriffIdvContent = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
}: VeriffIdvContent.Props) => {
  const { t } = useTranslation();

  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );

  const formInstance = useForm<Record<string, string>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });

  const formEntries = useMemo(
    () => [
      {
        key: 'file',
        label: t('fields:veriffIdv.file'),
        type: 'file',
        description: '',
      },
    ],
    [t]
  );

  const handleSave = async () => {
    const parsedNode = VeriffIdvNode.parse(node);

    const updatedNode = UpdateVeriffIdvNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields: node.fields,
      mapping: formInstance.getValues(),
    });

    await onUpdate(updatedNode);

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  return (
    <FormProvider {...formInstance}>
      <div className="my-2 flex flex-col gap-4 rounded-md border p-4">
        <ServiceCommonMappingForm
          spektrFields={spektrFields}
          form={formEntries}
          title={t('process:baseMapAttributesTitle')}
        />
      </div>
      <DialogFooterActions
        btnColor="veriffIdv"
        disabled={!formInstance.formState.isValid}
        isPendingUpdate={isPendingUpdate}
        onSave={handleSave}
      />
    </FormProvider>
  );
};

export const VeriffIdvDialog = withDialogWrapper(
  VeriffIdvContent,
  <ContentSkeleton />,
  {
    className: 'max-w-[700px]',
  }
);
