import { cn } from '@spektr/style-utils';

export const veriffIdvContained = cn(
  'bg-color-veriffIdv',
  'text-color-text-button-contained-vendor',
  'hover:bg-color-veriffIdv/80',
  'active:bg-color-veriffIdv/90'
);
export const veriffIdvOutlined = cn(
  veriffIdvContained,
  'border-color-veriffIdv',
  'text-color-veriffIdv',
  'hover:bg-color-veriffIdv/10',
  'active:bg-color-veriffIdv/20',
  'disabled:border-color-veriffIdv/50'
);
export const veriffIdvText = cn('text-color-veriffIdv');
