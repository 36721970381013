import { useRef, useState } from 'react';
import { Search } from 'lucide-react';
import { PopoverTriggerProps } from '@radix-ui/react-popover';

import { useDebouncedSearch } from '@spektr/platform-hooks';

import { cn } from '@spektr/style-utils';

import { Input, InputProps } from '../Input';
import { Popover } from '../../Popover';

export type ComboboxProps = {
  defaultValue?: string;
  value?: string;
  onChange: (value: string) => void;
  onSearch: (value: string) => void;
  inputProps?: InputProps;
  triggerProps?: PopoverTriggerProps & {
    'data-testid'?: string;
  };
  children: React.ReactNode;
  noResults?: boolean;
  noResultsText?: string;
  contentClassName?: string;
  className?: string;
};

export const Combobox = ({
  defaultValue = '',
  value = '',
  onSearch,
  onChange,
  inputProps,
  triggerProps,
  children,
  noResults = false,
  noResultsText = 'No results',
  contentClassName,
  className,
}: ComboboxProps) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { searchValue, setSearchValue } = useDebouncedSearch(value, onSearch);

  const handleChange = (newValue: string) => {
    if (newValue === defaultValue) {
      return;
    }

    onChange(newValue);
  };

  const handleClickInsideCombobox = (
    event: React.MouseEvent<HTMLUListElement> & {
      target: HTMLLIElement;
    }
  ) => {
    const optionValue = event.target.getAttribute('data-value');
    const optionLabel = event.target.getAttribute('data-label');

    if (optionValue) {
      setSearchValue(optionLabel ? optionLabel : optionValue);
      handleChange(optionValue);
      setPopoverOpen(false);
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      searchInputRef.current?.focus();
    }
    setPopoverOpen(open);
  };

  const handleSearchInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!isPopoverOpen) {
      handleChange(searchValue);
    }
    inputProps?.onBlur?.(e);
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const input = e.currentTarget;

    if (e.key === ' ' && isPopoverOpen) {
      e.preventDefault();
      const cursorPosition = input.selectionStart;
      if (cursorPosition !== null) {
        setSearchValue(
          (prevValue) =>
            prevValue.slice(0, cursorPosition) +
            ' ' +
            prevValue.slice(cursorPosition)
        );

        setTimeout(() => {
          input.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        }, 0);
      }
    } else if (e.key === 'Enter') {
      setPopoverOpen(false);
    }
  };

  return (
    <Popover
      className={cn(
        'w-80 overflow-hidden',
        'dark:border-color-border-primary border-color-border-secondary',
        'bg-color-bg-select-content',
        'text-color-text-select-content',
        className
      )}
      open={isPopoverOpen}
      onOpenChange={handleOpenChange}
      onOpenAutoFocus={(e) => {
        e.preventDefault();
      }}
      triggerProps={{
        ...triggerProps,
        className: cn('w-full', triggerProps?.className),
      }}
      trigger={
        <Input
          dimension="small"
          endIcon={
            <Search className={cn('h-3 w-3', !isPopoverOpen && 'hidden')} />
          }
          {...inputProps}
          data-cy="combobox-trigger-data-cy"
          className={cn('w-full px-2.5 py-3', inputProps?.className)}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            inputProps?.onChange?.(e);
          }}
          onBlur={handleSearchInputBlur}
          onKeyDown={handleKeydown}
          ref={searchInputRef}
        />
      }
    >
      <ul
        className={cn(
          'max-h-64 w-full px-2 py-1',
          'list-none',
          'overflow-y-auto',
          contentClassName
        )}
        onClick={handleClickInsideCombobox}
        onWheel={(e) => e.stopPropagation()}
      >
        {noResults ? (
          <li className="text-color-text-subtext p-2 text-xs">
            {noResultsText}
          </li>
        ) : (
          children
        )}
      </ul>
    </Popover>
  );
};
