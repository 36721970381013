import { useMemo } from 'react';
import { Check, CircleAlert } from 'lucide-react';

import { ServiceFieldClient } from '@spektr/client/types';
import { Button, SelectOption, Select } from '@spektr/client/components';

import { ServiceNodeMonitor } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';

import { SelectableServiceItem } from '../SelectableServiceItem/SelectableServiceItem';

type ComplyAdvantageItemProps = {
  activeMonitors: ServiceNodeMonitor[];
  field: ServiceFieldClient;
  userOptions: SelectOption[];
  onSelectionChange: (value: boolean) => void;
  onMonitorUpdate: (decision: 'accept' | 'escalate', assignee?: string) => void;
};

export const ComplyAdvantageItem = ({
  activeMonitors,
  field,
  userOptions,
  onSelectionChange,
  onMonitorUpdate,
}: ComplyAdvantageItemProps) => {
  const fieldMonitor = useMemo(
    () =>
      activeMonitors.find((monitor) => monitor.rule.rule.left === field.key),
    [activeMonitors, field.key]
  );
  const isSelected = !!fieldMonitor;
  const decision = fieldMonitor?.decision.type;
  const assignee =
    fieldMonitor?.decision.type === 'escalate'
      ? fieldMonitor.decision.assigneeId
      : undefined;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex items-center justify-between gap-2">
        <SelectableServiceItem
          checked={isSelected}
          field={field}
          onChange={onSelectionChange}
        />
        <div className="flex items-center gap-2">
          <Button
            className="flex items-center gap-2 p-3 !transition-none"
            variant="outlined"
            color={decision === 'accept' ? 'success' : 'secondary'}
            disabled={!isSelected}
            onClick={
              decision === 'accept'
                ? undefined
                : () => onMonitorUpdate('accept')
            }
          >
            <Check className="h-3 w-3" />
            Accept hit
          </Button>
          <Button
            className="flex items-center gap-2 p-3 !transition-none"
            variant="outlined"
            color={decision === 'escalate' ? 'red' : 'secondary'}
            disabled={!isSelected}
            onClick={
              decision === 'escalate'
                ? undefined
                : () => onMonitorUpdate('escalate')
            }
          >
            <CircleAlert className="h-3 w-3" />
            Escalate
          </Button>
        </div>
      </div>
      {decision === 'escalate' && (
        <div
          className={cn(
            'flex items-center justify-between gap-2',
            'px-4 py-3',
            'border-color-red rounded-md border border-dashed'
          )}
        >
          <div className="flex items-center gap-1.5 text-sm">
            <CircleAlert className="stroke-color-red h-3 w-3" />
            Assign Escalated alert
          </div>
          <Select
            className="w-64"
            value={assignee}
            options={userOptions}
            onValueChange={(value) => onMonitorUpdate(decision, value)}
            placeholder="Select team member"
          />
        </div>
      )}
    </div>
  );
};
