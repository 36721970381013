import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSuspenseQuery } from '@tanstack/react-query';

import { Label, Select, SelectOption, toast } from '@spektr/client/components';

import {
  getAllowedSpektrFieldsQuery,
  getDocumentOCRFieldsQuery,
} from '@spektr/client/services';

import {
  UpdateDocumentOCRSchema,
  type DocumentOCRNode,
} from '@spektr/shared/validators';

import { DialogFooterActions } from '../../components/DialogFooterActions';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { DocumentOCRSkeleton } from './components/DocumentOCRSkeleton';

export namespace DocumentOCRContent {
  export type Props = BaseDialogProps<DocumentOCRNode>;
}

export const DocumentOCRContent = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
}: DocumentOCRContent.Props) => {
  const { t } = useTranslation();

  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );

  const { data: ocrTemplates } = useSuspenseQuery(getDocumentOCRFieldsQuery());

  const options = useMemo<SelectOption[]>(
    () =>
      Object.entries(ocrTemplates).map(([key, value]) => ({
        label: value.template_name,
        value: key,
      })),
    [ocrTemplates]
  );

  const formInstance = useForm<Record<string, string>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });

  const handleSave = async () => {
    const parsed = UpdateDocumentOCRSchema.parse({
      nodeType: node.nodeType,
      title: node.title,
      mapping: formInstance.getValues(),
      fields: ocrTemplates[formInstance.getValues().template].fields,
    });
    await onUpdate(parsed);

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  const fileSpektrFields = useMemo(() => {
    return spektrFields
      ?.filter((field) => field.type === 'file')
      .map((field) => ({
        label: field.label,
        value: field.key,
      }));
  }, [spektrFields]);

  return (
    <FormProvider {...formInstance}>
      <div className="dark:bg-color-bg-primary my-2 flex flex-col gap-4 rounded-md border bg-white p-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor="template">{t('fields:documentOCR.template')}</Label>
          <Controller
            name="template"
            control={formInstance.control}
            rules={{
              required: 'Template is required',
            }}
            render={({ field }) => (
              <Select
                value={field.value}
                placeholder="Select template"
                options={options}
                name="template"
                className="dark:bg-color-bg-primary bg-white"
                onValueChange={field.onChange}
              />
            )}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="file">{t('fields:documentOCR.file')}</Label>
          <Controller
            name="file"
            control={formInstance.control}
            rules={{
              required: 'File is required',
            }}
            render={({ field }) => (
              <Select
                value={field.value}
                placeholder="Select file"
                className="dark:bg-color-bg-primary bg-white"
                name="file"
                options={fileSpektrFields}
                onValueChange={field.onChange}
              />
            )}
          />
        </div>
      </div>
      <DialogFooterActions
        btnColor="cyan"
        disabled={!formInstance.formState.isValid}
        isPendingUpdate={isPendingUpdate}
        onSave={handleSave}
      />
    </FormProvider>
  );
};

export const DocumentOCRDialog = withDialogWrapper(
  DocumentOCRContent,
  <DocumentOCRSkeleton />,
  {
    className: 'max-w-[700px]',
  }
);
