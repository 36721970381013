import { forwardRef, type HTMLAttributes, type ReactNode } from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

import type { NodeData } from '../../types/NodeData';

export namespace NodeWrapper {
  export type Props = HTMLAttributes<HTMLElement> & {
    children: ReactNode[];
    data: NodeData;
  };
}

export const NodeWrapper = forwardRef<HTMLDivElement, NodeWrapper.Props>(
  ({ children, data, className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={cn(variants({ status: data.status }), className)}
    >
      {children}
    </div>
  )
);

const variants = cva(
  [
    'relative',
    'flex flex-row items-center gap-3',
    'h-16 w-64 p-3',
    'rounded-lg border',
    'cursor-pointer duration-200',
    'hover:shadow-lg',
  ],
  {
    variants: {
      status: {
        pending: [
          'border-zinc-300 dark:border-zinc-700',
          'bg-white dark:bg-zinc-900',
          'hover:border-blue-500',
        ],
        approved: ['border-green-500', 'bg-green-500/5'],
        rejected: ['border-red-500', 'bg-red-500/5'],
      },
    },
    defaultVariants: {
      status: 'pending',
    },
  }
);
