import { predictIfIndividualOrCompany } from './predictIfIndividualOrCompany';

import type { FormValues } from '@spektr/moonraker-types';

export function generateNodeSubtext(values: FormValues) {
  const predictedType = predictIfIndividualOrCompany(values);
  if (predictedType === 'company') {
    return values?.company_number?.toString();
  }

  if (predictedType === 'individual') {
    return values?.nationality?.toString();
  }

  return;
}
