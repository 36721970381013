import z from 'zod';

export const SortOrder = z.enum(['asc', 'desc']);
export type SortOrder = z.infer<typeof SortOrder>;

export const FilterOptions = z
  .object({
    sortOrder: SortOrder.optional(),
    sortField: z.string().optional(),
    searchField: z.string().optional(),
    searchValue: z.coerce.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.searchValue && !val.searchField) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'searchField is required when searchValue is defined',
        path: ['searchField'],
      });
    }
  });
export type FilterOptions = z.infer<typeof FilterOptions>;
export const alertPresenceEnum = z.enum(['showAlertsOnly', 'showNoAlertsOnly']);
export type alertPresenceEnum = z.infer<typeof alertPresenceEnum>;

export const ClientRecordDataOperator = z.enum([
  'eq', // equal
  'ne', // not equal
  'in', // in array
  'nin', // not in array
]);

// TODO(ST-2990): support k-v search via query params
export const ClientRecordFilter = z
  .object({
    status: z.string().array(),
    tags: z.string().array(),
    alertPresence: alertPresenceEnum,
    alertType: z.string().array(),
    isUbo: z.string(),
    datasetIds: z.string().array(),
    createdAtStart: z.string(),
    createdAtEnd: z.string(),
    updatedAtStart: z.string(),
    updatedAtEnd: z.string(),
    searchQuery: z.string(),
    dataKey: z.string(),
    dataValue: z.string(),
    dataOperator: ClientRecordDataOperator,
  })
  .partial()
  .refine((filters) => {
    // enforce that all .data filters are present or none are
    if (filters.dataKey || filters.dataValue || filters.dataOperator) {
      return filters.dataKey && filters.dataValue && filters.dataOperator;
    }
    return true;
  });

export type ClientRecordFilter = z.infer<typeof ClientRecordFilter>;
