import { forwardRef } from 'react';
import { icons, Percent } from 'lucide-react';

import { Input, type InputProps, Label } from '@spektr/client/components';

export namespace PercentageInput {
  export type Props = InputProps & {
    icon: keyof typeof icons;
    label: string;
  };
}

export const PercentageInput = forwardRef<
  HTMLInputElement,
  PercentageInput.Props
>(({ icon, label, ...props }, ref) => {
  const Icon = icons[icon];
  return (
    <div className="flex flex-col gap-1.5">
      <Label className="flex flex-col gap-2">
        <Icon className="h-4 w-4" />
        {label}
      </Label>
      <Input
        {...props}
        ref={ref}
        endIcon={<Percent className="text-color-text-input h-3 w-3" />}
        type="number"
        min={0}
        max={100}
        step="any"
      />
    </div>
  );
});
