import axios from 'axios';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Banner, SpektrErrorBoundary } from '@spektr/client/components';

import { CustomerDocumentsList } from '../containers/CustomerDocumentsList';
import { SkeletonCustomerDocumentsList } from '../components';

export const CustomerDocuments = () => {
  const columnsToDisplay = [
    'description',
    'size',
    'creationDate',
    'expiryDate',
    'actions',
  ];

  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => {
        let errorMsg = 'An error occurred while retrieving the documents.';
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            errorMsg = 'There are no documents at the moment.';
          } else if (error.message) {
            errorMsg = error.message;
          }
        }
        return (
          <Banner type="error" iconName="TriangleAlert" message={errorMsg} />
        );
      }}
    >
      <div className="flex flex-col overflow-x-auto">
        <Suspense
          fallback={
            <SkeletonCustomerDocumentsList columns={columnsToDisplay} />
          }
        >
          <CustomerDocumentsList columns={columnsToDisplay} />
          <Outlet />
        </Suspense>
      </div>
    </SpektrErrorBoundary>
  );
};
