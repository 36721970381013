import { FormEventHandler, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { cn } from '@spektr/style-utils';

import { ApiKeySchema } from '@spektr/shared/validators';
import { RBAC } from '@spektr/shared/rbac';

import { UsersApiClient, getApiKeysQueryKey } from '@spektr/client/services';

import { Card } from '@spektr/shared/components';
import {
  Input,
  Label,
  Button,
  Spinner,
  toast,
  ActionButton,
} from '@spektr/client/components';

export type GenerateNewApiKeyProps = {
  className?: string;
};

export const GenerateNewApiKey = ({ className }: GenerateNewApiKeyProps) => {
  const queryClient = useQueryClient();
  const keyNameRef = useRef<HTMLInputElement>(null);

  const createApiKey = useMutation({
    mutationFn: async (name: string) => {
      await queryClient.cancelQueries({ queryKey: getApiKeysQueryKey() });

      await UsersApiClient.getClient().createApiKey({
        name,
      });

      queryClient.setQueryData(
        getApiKeysQueryKey(),
        (apiKeys: ApiKeySchema[]) => {
          return [
            ...apiKeys,
            {
              id: 'temp',
              name,
              key: 'temp',
              enabled: true,
            },
          ];
        }
      );
      return;
    },
    onError: () => {
      toast.error({
        title: 'Create API Key',
        description: 'An error occurred while creating the API key.',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getApiKeysQueryKey(),
      });
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (ev) => {
    ev.preventDefault();

    const keyName = keyNameRef.current?.value;

    if (!keyName) return;

    await createApiKey.mutateAsync(keyName);

    keyNameRef.current!.value = '';
  };

  return (
    <Card className={className}>
      <form
        className={cn('flex flex-col gap-4', 'w-full max-w-sm p-4')}
        onSubmit={handleSubmit}
      >
        <p className="text-color-text-primary text-base font-medium">
          Generate a new API key
        </p>
        <div className="flex flex-col gap-2">
          <Label htmlFor="fullName">API Key name</Label>
          <Input
            ref={keyNameRef}
            required
            id="fullName"
            placeholder="Enter name of key"
          />
        </div>
        <div className="inline">
          {createApiKey.isPending ? (
            <Button color="primary" disabled type="submit">
              <div className="flex gap-2">
                <Spinner size="sm" />
                <span>Generating...</span>
              </div>
            </Button>
          ) : (
            <ActionButton
              rbacAction={RBAC.ACTIONS.API_KEYS.CREATE}
              color="primary"
              type="submit"
            >
              Generate new API Key
            </ActionButton>
          )}
        </div>
      </form>
    </Card>
  );
};
