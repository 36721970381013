import { LayoutGrid, Mail } from 'lucide-react';

import { Tabs, TabsList, TabsTrigger } from '@spektr/client/components';

import { isSettingsTab, type SettingsTab } from '../../types/SettingsTab';

export type TabNavigationProps = {
  activeTab: SettingsTab;
  onChangeTab: (value: SettingsTab) => void;
};

export const TabNavigation = ({
  activeTab,
  onChangeTab,
}: TabNavigationProps) => {
  const handleChangeTab = (value: string) => {
    if (isSettingsTab(value)) {
      onChangeTab(value);
    } else {
      throw new Error(`Invalid tab value: ${value}`);
    }
  };

  return (
    <Tabs value={activeTab} className="text-xs" onValueChange={handleChangeTab}>
      <TabsList className="w-full">
        <TabsTrigger value="source" className="gap-1.5">
          <LayoutGrid className="h-4 w-4" /> Source
        </TabsTrigger>
        <TabsTrigger value="channel" className="gap-1.5">
          <Mail className="h-4 w-4" /> Channel
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
};
