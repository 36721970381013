import { RouteObject } from 'react-router-dom';

import {
  CASES_ALERT,
  CASES_ALERTS,
  CASES_ALERT_ACTIVITY,
  CASES_CUSTOMER_PARAM,
  CASES_CUSTOMERS,
  CASES_URL,
  CASES_ALERT_HIT,
  CASES_COMMENTS,
  CASES_CUSTOMER_DOCUMENTS,
  CASES_CUSTOMER_OWNERSHIP,
  CASES_CUSTOMER_CONNECTIONS,
  CASES_SERVICE_DATA,
  CASES_DOCUMENT,
} from '@spektr/shared/utils';

import {
  AlertActivityLog,
  AlertConfiguration,
  CasesCustomerDetails,
  AlertHitDetails,
  AdditionalHitInfo,
  AlertHitComments,
  CustomerAlerts,
  CustomerOwnership,
  CustomerComments,
  CustomerTimeline,
  CustomerDocuments,
  CustomerHeader,
  CustomerConnections,
  NodeRunDataDialog,
} from '@spektr/model-builder/containers';

import { CasesIndexPage } from './IndexPage';
import { CustomerDocumentDialog, CustomersIndexPage } from './customers';
import { AlertsIndexPage, AlertDialog } from './alerts';

export const casesRoutes: RouteObject = {
  path: CASES_URL,
  element: <CasesIndexPage />,
  handle: {
    title: 'Cases',
  },
  children: [
    {
      path: CASES_CUSTOMERS,
      children: [
        {
          index: true,
          element: <CustomersIndexPage />,
        },
        {
          path: CASES_CUSTOMER_PARAM,
          element: <CasesCustomerDetails />,
          handle: {
            renderLeft: () => <CustomerHeader />,
          },
          children: [
            {
              path: '',
              element: <CustomerTimeline />,
              children: [
                {
                  path: CASES_SERVICE_DATA,
                  element: <NodeRunDataDialog />,
                },
              ],
            },
            {
              path: CASES_ALERTS,
              element: <CustomerAlerts />,
              children: [
                {
                  path: CASES_ALERT,
                  element: <AlertDialog />,
                  children: [
                    {
                      index: true,
                      element: <AlertConfiguration />,
                    },
                    {
                      path: CASES_ALERT_ACTIVITY,
                      element: <AlertActivityLog />,
                    },
                    {
                      path: CASES_ALERT_HIT,
                      element: <AlertHitDetails />,
                      children: [
                        {
                          index: true,
                          element: <AdditionalHitInfo />,
                        },
                        {
                          path: CASES_COMMENTS,
                          element: <AlertHitComments />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: CASES_CUSTOMER_DOCUMENTS,
              element: <CustomerDocuments />,
              children: [
                {
                  path: CASES_DOCUMENT,
                  element: <CustomerDocumentDialog />,
                },
              ],
            },
            {
              path: CASES_CUSTOMER_OWNERSHIP,
              element: <CustomerOwnership />,
            },
            {
              path: CASES_CUSTOMER_CONNECTIONS,
              element: <CustomerConnections />,
            },
            {
              path: CASES_COMMENTS,
              element: <CustomerComments />,
            },
          ],
        },
      ],
    },
    {
      path: CASES_ALERTS,
      element: <AlertsIndexPage />,
      children: [
        {
          path: CASES_ALERT,
          element: <AlertDialog />,
          children: [
            {
              index: true,
              element: <AlertConfiguration />,
            },
            {
              path: CASES_ALERT_ACTIVITY,
              element: <AlertActivityLog />,
            },
            {
              path: CASES_ALERT_HIT,
              element: <AlertHitDetails />,
              children: [
                {
                  index: true,
                  element: <AdditionalHitInfo />,
                },
                {
                  path: CASES_COMMENTS,
                  element: <AlertHitComments />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
