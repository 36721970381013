import { useCallback, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  getAllowedSpektrFieldsQuery,
  getDefaultBrandingStyle,
  getProcessByIdQuery,
} from '@spektr/client/services';

import { toast } from '@spektr/client/components';
import { MoonrakerForm } from '@spektr/moonraker-form';

import {
  OwnershipTreeFormNode,
  UpdateOwnershipTreeFormNode,
} from '@spektr/shared/validators';
import { MoonrakerForm as MoonrakerFormData } from '@spektr/moonraker-types';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

export namespace OwnershipTreeFormDialog {
  export type Props = BaseDialogProps<OwnershipTreeFormNode>;
}

export const OwnershipTreeFormDialog = ({
  node,
  nodeId,
  processId,
  onClose,
  onUpdate,
  isPendingUpdate,
}: OwnershipTreeFormDialog.Props) => {
  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: allowedSpektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, nodeId)
  );
  const { data: defaultBranding } = useSuspenseQuery(getDefaultBrandingStyle());
  const [formTitle, setFormTitle] = useState(node.title);

  const handleUpdateNode = useCallback(
    async (data: MoonrakerFormData) => {
      const evaluated = UpdateOwnershipTreeFormNode.parse({
        nodeType: node.nodeType,
        title: formTitle,
        moonrakerForm: data,
      });

      await onUpdate(evaluated);

      toast.success({
        title: 'Successfully updated!',
        description: 'The node has been updated successfully.',
      });
    },
    [node.nodeType, formTitle, onUpdate]
  );

  return (
    <MoonrakerForm
      isOwnershipForm
      isSavePending={isPendingUpdate}
      node={{ ...node, title: formTitle }}
      process={process}
      defaultBranding={defaultBranding}
      spektrFields={allowedSpektrFields}
      onClose={onClose}
      onSave={handleUpdateNode}
      onUpdateNodeTitle={(nodeTitle) => setFormTitle(nodeTitle)}
    />
  );
};
