import { useForm } from 'react-hook-form';

import { Button } from '@spektr/client/components';
import { FileUploader } from '@spektr/model-builder/components';

type UploadDocumentFormValues = {
  description: string;
  file?: File;
};

type DocumentUploadProps = {
  maxSizeInBytes?: number;
  onUpload: (file?: File) => void;
};

export const DocumentUpload = ({
  maxSizeInBytes,
  onUpload,
}: DocumentUploadProps) => {
  const { setValue, formState, watch } = useForm<UploadDocumentFormValues>();
  const file = watch('file');

  const handleUpload = () => {
    onUpload(file);
  };

  return (
    <div className="flex flex-col gap-3">
      <FileUploader
        className="h-36 [&>svg]:mb-5 [&>svg]:h-6 [&>svg]:w-6"
        currentFile={file}
        maxSize={maxSizeInBytes}
        iconName="CloudUpload"
        variant="cyan"
        onDropAccepted={([acceptedFile]) => {
          setValue('file', acceptedFile);
        }}
        onRemove={() => setValue('file', undefined)}
      />

      <div className="ml-auto flex flex-row gap-4">
        <Button
          disabled={!formState.isValid || !file}
          variant="text"
          onClick={handleUpload}
        >
          Upload document
        </Button>
      </div>
    </div>
  );
};
