import { MarkerType } from 'reactflow';

import { getEdgeLabel, type MoonrakerGraph } from '@spektr/moonraker-graph';

import type {
  OwnershipGraphEdge,
  OwnershipGraphNode,
} from '@spektr/shared/validators';

export function createEdgeFromPayload(
  node: OwnershipGraphNode,
  adj: OwnershipGraphEdge
): MoonrakerGraph.Edge {
  const percentage =
    typeof adj.shareholding.percentage === 'string'
      ? parseFloat(adj.shareholding.percentage)
      : null;

  return {
    id: adj.id,
    source: node.spektrId,
    target: adj.spektrId,
    markerEnd: {
      type: MarkerType.Arrow,
    },
    data: {
      label: getEdgeLabel(percentage),
      ownershipShares: percentage,
      role: adj.shareholding.role?.toString() ?? null,
      // TODO: (@Robert) - We don't use SpektrData for `shareholding` because this will bring the entire Zod stuff in an agnostic lib.
      data: adj as any,
    },
    type: 'edgeWithLabel',
  };
}
