import { Country } from '../types/assets';

export function getCountryDialCode(country: Country) {
  return country.dialCode;
}

export function getCountryCode(country: Country) {
  return country.code;
}

export function getCountryName(country: Country) {
  return country.name;
}

export function getCountryDialWithLabel(country: Country) {
  return `${getCountryDialCode(country)} (${getCountryName(country)})`;
}
