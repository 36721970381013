import { PlusSquare } from 'lucide-react';

import { ActionButton } from '@spektr/client/components';
import { RBAC } from '@spektr/shared/rbac';
import { useState } from 'react';

import { ChooseNewProcessDialog } from './ChooseNewProcessDialog';

export const ChooseNewProcessButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ActionButton
        rbacAction={RBAC.ACTIONS.PROCESS.CREATE}
        className="whitespace-nowrap p-3.5 font-semibold"
        color="cyan"
        startIcon={<PlusSquare className="h-4 w-4 stroke-2" />}
        data-cy="create-process-button"
        onClick={() => setIsOpen(true)}
      >
        Add new process
      </ActionButton>
      <ChooseNewProcessDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
