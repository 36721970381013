import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

export type FormTitleProps = HTMLAttributes<HTMLHeadingElement> & {
  children: ReactNode;
};

export const FormTitle = ({
  children,
  className,
  ...props
}: FormTitleProps) => {
  return (
    <h1
      {...props}
      className={cn('my-3 text-xl font-medium text-black', className)}
    >
      {children}
    </h1>
  );
};
