import { Field } from '@spektr/moonraker-types';
import { FormNode } from '@spektr/shared/validators';

export const getFormFieldOrderIndex = (field: Field, node?: FormNode) => {
  if (!node || !node.moonrakerForm) {
    return 0;
  }

  const formFields = node.moonrakerForm.fields;
  const fieldsOrder = node.moonrakerForm.order;

  const sortedFormFields = Object.values(formFields)
    .filter((formField) => formField.type === field.type)
    .sort((a, b) => {
      const aIndex = fieldsOrder.findIndex((id) => id === a.id);
      const bIndex = fieldsOrder.findIndex((id) => id === b.id);
      return aIndex - bIndex;
    });

  return sortedFormFields.findIndex((formField) => formField.id === field.id);
};
