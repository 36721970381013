import camelCase from 'lodash/camelCase';

import { VendorSearchSchema } from '@spektr/shared/validators';

export const computeVendorData = (
  data: VendorSearchSchema,
  processName: string
) => {
  const commonData = {
    fileName: `${camelCase(processName)}-${data.vendor}-data.json`,
    fileType: 'application/json',
    data: data.response,
  };

  switch (data.vendor) {
    case 'complyAdvantageKyb':
    case 'complyAdvantageKyc':
      return {
        ...commonData,
        data: (data.response as any).content.data,
      };

    default:
      return commonData;
  }
};
