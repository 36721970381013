import { Controller, useFormContext } from 'react-hook-form';

import { Label, SpektrfieldCombobox } from '@spektr/client/components';
import { SpektrField } from '@spektr/shared/types';

export type RecipientFieldProps = {
  spektrFields: SpektrField[];
};

export const RecipientField = ({ spektrFields }: RecipientFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Label>Recipient Field</Label>
      <Controller
        name="recipientField"
        render={({ field }) => (
          <SpektrfieldCombobox
            fields={spektrFields}
            defaultValue={field.value ?? ''}
            inputProps={{
              dimension: 'default',
              required: true,
              placeholder: 'Select from dropdown...',
              error: !!errors?.recipientField?.message,
            }}
            onChange={(value) => {
              const isSpektrField = spektrFields.some(
                (field) => field.key === value
              );

              field.onChange(isSpektrField ? value : '');
            }}
          />
        )}
        control={control}
      />
    </>
  );
};
