import { Link } from 'react-router-dom';

import { loopDetailsUrl, processBuilderUrl } from '@spektr/shared/utils';
import {
  ClientRecordTagModel,
  ProcessRunEventData,
} from '@spektr/shared/validators';

import { TextTruncate } from '@spektr/client/components';

import { CustomerTimelineCell } from '../CustomerTimelineCell';
import { CustomerTagsList } from '../CustomerTagsList';
import { CustomerTimelineRow } from '../CustomerTimelineRow';

import { ServiceRunEvent } from './ServiceRunEvent';

export type ProcessRunEventProps = {
  event: ProcessRunEventData;
};

export const ProcessRunEvent = ({ event }: ProcessRunEventProps) => {
  return (
    <>
      <CustomerTimelineCell
        iconName="LayoutGrid"
        iconColor="cyan"
        className="col-span-4"
        label="Process"
        value={
          <Link
            to={
              event.eventData.processType === 'loop'
                ? loopDetailsUrl(event.eventData.processId)
                : processBuilderUrl(event.eventData.processId)
            }
          >
            <TextTruncate text={event.eventData.processName} />
          </Link>
        }
      />
      <CustomerTimelineCell
        className="col-span-2"
        label="Tags"
        value={
          event.eventData.tags.length > 0 ? (
            <CustomerTagsList
              tags={event.eventData.tags as ClientRecordTagModel[]}
            />
          ) : (
            '-'
          )
        }
      />
      <CustomerTimelineCell
        label="Score"
        value={<TextTruncate text={String(event.eventData.score)} />}
      />
      {event.serviceRuns.length > 0 && (
        <>
          <CustomerTimelineCell
            label="# of services"
            value={event.serviceRuns.length}
          />
          <div className="col-span-12 rounded-md border">
            {event.serviceRuns.map((serviceRunEvent, index) => (
              <CustomerTimelineRow
                isLast={index === event.serviceRuns.length - 1}
                className="grid-cols-10 border-solid p-4"
              >
                <ServiceRunEvent event={serviceRunEvent} />
              </CustomerTimelineRow>
            ))}
          </div>
        </>
      )}
    </>
  );
};
