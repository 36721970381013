// TODO: @Alex - this might need to be moved to a different file
// depending on the api implementation

import { z } from 'zod';

import { baseChildSchemaDto, messageBlockSchemaDto } from './comments';

export const mentionChildSchemaDto = z.object({
  type: z.literal('mention'),
  entity: z.string(),
  children: z.array(baseChildSchemaDto).optional(),
});

export const messageBlockWithMentions = messageBlockSchemaDto.extend({
  children: z.array(z.union([baseChildSchemaDto, mentionChildSchemaDto])),
});

export const emailSettingsSchema = z.object({
  type: z.literal('email'),
  mapping: z.record(z.literal('email'), z.string()),
  messageBody: z.array(messageBlockWithMentions),
});

export const internalEmailSettingsSchema = z.object({
  type: z.literal('internalEmail'),
  emailAddress: z.string(),
  messageBody: z.array(messageBlockWithMentions),
});

export const channelSettingsSchema = z.discriminatedUnion('type', [
  emailSettingsSchema,
  internalEmailSettingsSchema,
]);

export type EmailSettings = z.infer<typeof emailSettingsSchema>;
export type InternalEmailSettings = z.infer<typeof internalEmailSettingsSchema>;

export type ChannelSettings = z.infer<typeof channelSettingsSchema>;
