import type { FormValues } from '@spektr/moonraker-types';

export function generateNodeLabel(values: FormValues) {
  if (values.company_name) {
    return values.company_name.toString();
  }

  if (values.full_name) {
    return values.full_name.toString();
  }

  if (values.first_name && values.last_name) {
    return `${values.first_name} ${values.last_name}`;
  }

  return 'Unknown';
}
