import {
  isReadOnlyField,
  isWritableField,
  type Field,
} from '@spektr/moonraker-types';

import { ReadableFieldFactory } from '../ReadableFieldFactory';
import { WritableFieldFactory } from '../WritableFieldFactory';

export type FieldFactoryProps = {
  field: Field;
  name?: string;
};

export const FieldFactory = ({ field, name }: FieldFactoryProps) => {
  if (isReadOnlyField(field)) {
    return <ReadableFieldFactory field={field} />;
  }

  if (isWritableField(field)) {
    return <WritableFieldFactory field={field} name={name} />;
  }

  return null;
};
