import { MarkerType } from 'reactflow';

import type { Edge } from '../types/Edge';

export function createEdge(
  id: string,
  source: string,
  target: string,
  label: string,
  role: string
): Edge {
  return {
    id,
    source,
    target,
    type: 'edgeWithLabel',
    markerEnd: {
      type: MarkerType.Arrow,
    },
    data: {
      role,
      label,
      ownershipShares: null,
    },
  };
}
