import { useState } from 'react';
import {
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  NodeProps,
  useReactFlow,
} from 'reactflow';
import { Trash2 } from 'lucide-react';
import isEmpty from 'lodash/isEmpty';

import { IconButton, Popover } from '@spektr/client/components';

import { cn } from '@spektr/style-utils';

import { useGraphState } from '../../hooks/useGraphState';

import { BasicNodeContent } from '../BasicNodeContent';
import { EditableNodePopover } from '../EditableNodePopover';

import type { NodeData } from '../../types/NodeData';
import type { EdgeData } from '../../types/EdgeData';

export const EditableNode = ({ id, data }: NodeProps<NodeData>) => {
  const { form } = useGraphState();
  const [open, setOpen] = useState(false);

  const { setNodes, setEdges } = useReactFlow<NodeData, EdgeData>();

  const handleRemoveNode = () => {
    setNodes((prevNodes) => {
      const node = prevNodes.find((n) => n.id === id);
      if (!node) return prevNodes;

      setEdges((prevEdges) => {
        const incomers = getIncomers(node, prevNodes, prevEdges);
        const outgoers = getOutgoers(node, prevNodes, prevEdges);
        const connectedEdges = getConnectedEdges([node], prevEdges);
        const remainingEdges = prevEdges.filter(
          (edge) => !connectedEdges.includes(edge)
        );

        const newEdges = incomers.flatMap(({ id: source }) =>
          outgoers.map(({ id: target }) => ({
            id: `${source}->${target}`,
            source,
            target,
            type: 'edgeWithLabel',
          }))
        );
        return [...remainingEdges, ...newEdges];
      });

      return prevNodes.filter((node) => node.id !== id);
    });
  };
  const isEmptyForm = isEmpty(form?.fields);

  return (
    <Popover
      align="center"
      alignOffset={0}
      className="w-full min-w-80 max-w-96 bg-white dark:bg-zinc-900"
      side="right"
      sideOffset={10}
      triggerProps={{
        asChild: true,
      }}
      open={open}
      trigger={
        <div className="group relative">
          <BasicNodeContent id={id} data={data} />
          <IconButton
            className={cn(
              'hidden',
              'group/button',
              'absolute right-0 top-1/2 p-1',
              '-translate-y-1/2 translate-x-full',
              'group-hover:block'
            )}
            variant="text"
            onClick={handleRemoveNode}
          >
            <Trash2 className="h-4 w-4 text-zinc-400 group-hover/button:text-red-500" />
          </IconButton>
        </div>
      }
      onOpenChange={isEmptyForm ? undefined : setOpen}
    >
      <EditableNodePopover id={id} data={data} onClose={() => setOpen(false)} />
    </Popover>
  );
};
