import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Handle, NodeProps, Position } from 'reactflow';

import { loopDetailsUrl, processBuilderUrl } from '@spektr/shared/utils';
import { TopologyProcess } from '@spektr/shared/types';

import { cn } from '@spektr/style-utils';
import { Badge } from '@spektr/client/components';

import { ProcessTypeBadge } from 'libs/client/components/src/libs/ProcessTypeBadge';

import { useIsVersionLive } from '../../hooks';

export const BasicNode = memo(
  ({
    data,
  }: NodeProps<
    TopologyProcess & {
      isSelected: boolean;
    }
  >) => {
    const navigate = useNavigate();

    const isLive = useIsVersionLive(data.id);

    const url =
      data.type === 'loop'
        ? loopDetailsUrl(data.id)
        : processBuilderUrl(data.id);

    return (
      <div
        className={cn(
          'h-auto w-[300px]',
          'rounded-md border',
          data.isSelected && 'border-color-cyan',
          'bg-color-bg-primary hover:bg-color-bg-accent',
          'cursor-pointer shadow-lg duration-200'
        )}
        onDoubleClick={() => {
          navigate(url);
        }}
      >
        <Handle
          type="target"
          id={data.id}
          position={Position.Left}
          className="opacity-0"
        />

        <div className="flex flex-col gap-2 p-4">
          <div className="flex justify-between">
            <ProcessTypeBadge type={data.type} />
            {isLive && <Badge>Live</Badge>}
            {isLive === false && <Badge>Not Live</Badge>}
          </div>
          <h3 className="text-color-text-primary text-sm font-normal">
            {data.name}
          </h3>
        </div>

        <Handle
          type="source"
          id={data.id}
          position={Position.Right}
          className="opacity-0"
        />
      </div>
    );
  }
);
