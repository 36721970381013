import { cn } from '@spektr/style-utils';

import { NameWithTooltip } from '../NameWithTooltip';
import { CaseIcon } from '../CaseIcon';

import type { NodeRecordType } from '../../types/NodeData';

export namespace EntityLink {
  export type Props = {
    parent: Entity;
    child: Entity;
  };

  export type Entity = {
    name: string;
    subtext: string;
    type: NodeRecordType;
  };
}

export const EntityLink = ({ parent, child }: EntityLink.Props) => {
  return (
    <div className="relative flex flex-col gap-6">
      <div
        className={cn(
          'absolute bottom-9 left-[18px] top-9',
          'w-[1px]',
          'bg-zinc-300 dark:bg-zinc-700',
          'translate-x-1/2'
        )}
      />
      <div className="flex flex-row gap-3">
        <CaseIcon status="pending" type={parent.type} />
        <div className="flex flex-col">
          <NameWithTooltip name={parent.name} maxWidth={190} />
          <span className="text-xs text-zinc-700 dark:text-zinc-300">
            {parent.subtext}
          </span>
        </div>
      </div>
      <div className="flex flex-row gap-3">
        <CaseIcon status="pending" type={child.type} />
        <div className="flex flex-col">
          <NameWithTooltip name={child.name} maxWidth={190} />
          <span className="text-xs text-zinc-700 dark:text-zinc-300">
            {child.subtext}
          </span>
        </div>
      </div>
    </div>
  );
};
