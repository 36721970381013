import { z } from 'zod';
import { SpektrData } from '@spektr/shared/validators';

export const OwnershipGraphEdge = z.object({
  id: z.string(),
  spektrId: z.string(),
  directOrIndirect: z.enum(['direct', 'indirect']),
  shareholding: SpektrData,
});
export type OwnershipGraphEdge = z.infer<typeof OwnershipGraphEdge>;

export const OwnershipGraphNode = z.object({
  spektrId: z.string(),
  status: z.string(),
  details: SpektrData,
  adj: z.array(OwnershipGraphEdge),
});
export type OwnershipGraphNode = z.infer<typeof OwnershipGraphNode>;

export const OwnershipGraph = z.object({
  payload: z.array(OwnershipGraphNode),
});
export type OwnershipGraph = z.infer<typeof OwnershipGraph>;
