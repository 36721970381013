import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

export type FormTextProps = HTMLAttributes<HTMLParagraphElement> & {
  children: ReactNode;
};

export const FormText = ({ children, className, ...props }: FormTextProps) => {
  return (
    <p {...props} className={cn('text-sm text-black', className)}>
      {children}
    </p>
  );
};
