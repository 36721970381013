import { useMemo } from 'react';
import { useReactFlow } from 'reactflow';

import { X } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { Button, IconButton } from '@spektr/client/components';
import { MoonrakerFormViewer } from '@spektr/moonraker-form-viewer';

import { generateNodeLabel } from '../../utils/generateNodeLabel';
import { generateNodeSubtext } from '../../utils/generateNodeSubtext';
import { predictIfIndividualOrCompany } from '../../utils/predictIfIndividualOrCompany';

import { useGraphState } from '../../hooks/useGraphState';

import type { FormValues } from '@spektr/moonraker-types';
import type { NodeData } from '../../types/NodeData';
import type { EdgeData } from '../../types/EdgeData';

export namespace EditableNodePopover {
  export type Props = {
    id: string;
    data: NodeData;
    onClose: () => void;
  };
}

export const EditableNodePopover = ({
  id,
  data,
  onClose,
}: EditableNodePopover.Props) => {
  const { form } = useGraphState();

  const { setNodes } = useReactFlow<NodeData, EdgeData>();

  const defaultValues = useMemo(() => data.data ?? {}, [data]);

  const handleSubmit = (values: FormValues) => {
    setNodes((prevNodes) => {
      const node = prevNodes.find((n) => n.id === id);
      if (!node) return prevNodes;

      return prevNodes.map((n) => {
        if (n.id === id) {
          return {
            ...n,
            data: {
              ...n.data,
              name: generateNodeLabel(values),
              subtext: generateNodeSubtext(values),
              type: predictIfIndividualOrCompany(values, n.data.type),
              data: values,
            },
          };
        }

        return n;
      });
    });

    onClose();

    return Promise.resolve();
  };

  return (
    <div
      className={cn(
        'relative',
        'flex flex-col gap-6',
        'bg-white',
        'rounded-lg',
        'p-6'
      )}
    >
      <IconButton
        className="absolute right-4 top-4"
        variant="text"
        onClick={onClose}
      >
        <X className="h-5 w-5" />
      </IconButton>
      <MoonrakerFormViewer
        defaultFields={defaultValues}
        fields={form?.fields ?? {}}
        order={form?.order ?? []}
        onSubmit={handleSubmit}
      >
        {(formState) => (
          <div className="flex w-full justify-end">
            <Button disabled={formState.isSubmitting || !formState.isValid}>
              Save
            </Button>
          </div>
        )}
      </MoonrakerFormViewer>
    </div>
  );
};
