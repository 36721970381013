import { ComboboxProps, SpektrfieldCombobox } from '@spektr/client/components';

import { SpektrField } from '@spektr/shared/types';

import { HelperTextTooltip } from '../../HelperTextTooltip';

export type SpektrComboboxFieldProps = Omit<
  ComboboxProps,
  'onSearch' | 'children'
> & {
  name: string;
  label: string;
  helperText?: string;
  required?: boolean;
  options: SpektrField[];
};

export const SpektrComboboxField = ({
  name,
  label,
  helperText,
  options,
  required,
  ...props
}: SpektrComboboxFieldProps) => {
  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={name}
        >
          {label}
          {required && <span className="text-color-red ml-1">*</span>}
        </label>
        {helperText && <HelperTextTooltip helperText={helperText} />}
      </div>
      <SpektrfieldCombobox
        fields={options}
        inputProps={{
          placeholder: 'Select or create a custom data field',
          dimension: 'default',
        }}
        {...props}
      />
    </div>
  );
};
