import {
  type ReadOnlyField,
  isTitleField,
  isParagraphField,
  isDividerField,
} from '@spektr/moonraker-types';

import { FormTitle } from '../../components/FormTitle';
import { FormText } from '../../components/FormText';

export type FieldFactoryProps = {
  field: ReadOnlyField;
};

export const ReadableFieldFactory = ({ field }: FieldFactoryProps) => {
  if (isTitleField(field)) {
    return <FormTitle>{field.attributes.content}</FormTitle>;
  }

  if (isParagraphField(field)) {
    return <FormText>{field.attributes.content}</FormText>;
  }

  if (isDividerField(field)) {
    return <div className="my-6 w-full border-t border-zinc-200" />;
  }

  return <div>unsupported</div>;
};
