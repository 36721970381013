import { SpektrFieldWithInfo } from '../../goldenRecord';
import { commonSpektrFields } from '../commonSpektrFields/commonSpektrFields';

export enum MidshipProofOfAddressFieldKey {
  country,
  city,
  fullAddress,
  documentType,
  personName,
}

export const midshipProofOfAddressFields: Record<
  MidshipProofOfAddressFieldKey,
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = {
  [MidshipProofOfAddressFieldKey.country]: {
    key: 'documentOCR_proof_of_address_country',
    label: 'Proof of address country',
    type: 'string',
    source: 'documentOCR',
    commonSpektrFieldKeys: [commonSpektrFields.country.key],
    group: 'Proof of address',
  },
  [MidshipProofOfAddressFieldKey.city]: {
    key: 'documentOCR_proof_of_address_city',
    label: 'Proof of address city',
    type: 'string',
    source: 'documentOCR',
    commonSpektrFieldKeys: [commonSpektrFields.city.key],
    group: 'Proof of address',
  },
  [MidshipProofOfAddressFieldKey.fullAddress]: {
    key: 'documentOCR_proof_of_address_full_address',
    label: 'Proof of address full address',
    type: 'string',
    source: 'documentOCR',
    commonSpektrFieldKeys: [commonSpektrFields.fullAddress.key],
    group: 'Proof of address',
  },
  [MidshipProofOfAddressFieldKey.documentType]: {
    key: 'documentOCR_proof_of_address_document_type',
    label: 'Proof of address document type',
    type: 'string',
    source: 'documentOCR',
    commonSpektrFieldKeys: [],
    group: 'Proof of address',
  },
  [MidshipProofOfAddressFieldKey.personName]: {
    key: 'documentOCR_proof_of_address_person_name',
    label: 'Proof of address person name',
    type: 'string',
    source: 'documentOCR',
    commonSpektrFieldKeys: [commonSpektrFields.fullName.key],
    group: 'Proof of address',
  },
};

export const midshipTemplateAndFieldsMap = {
  'c3f9ab0c-1e95-4aab-be70-4045834af68e': {
    fields: {},
    template_name: 'Article of incorporation',
  },
  'ca7dc04f-9e21-4e64-bad4-5c752fbedade': {
    fields: {},
    template_name: 'Proof of identity',
  },
  '5a831b63-8f91-4ab2-8f5e-2685e0c75731': {
    fields: Object.values(midshipProofOfAddressFields).reduce(
      (acc, field) => {
        acc[field.key] = true;
        return acc;
      },
      {} as Record<string, boolean>
    ),
    template_name: 'Proof of address',
  },
  'a2b3faa4-55ec-47ba-ac74-bad24327f481': {
    fields: {},
    template_name: 'Shareholder registry',
  },
  '0c9c4c27-0933-4b93-bf90-db834e228ed1': {
    fields: {},
    template_name: 'Source of funds',
  },
};

export const midshipSpektrFields = [
  ...Object.values(midshipProofOfAddressFields),
];
