import { cn } from '@spektr/style-utils';

export const EmptyList = () => {
  return (
    <div
      className={cn(
        'my-1 w-full rounded-md p-2',
        'bg-white',
        'text-center text-xs font-medium text-zinc-400'
      )}
    >
      <span>No entities added yet</span>
    </div>
  );
};
