import * as RadixPopover from '@radix-ui/react-popover';

import { cn } from '@spektr/style-utils';

import { PopoverContent } from './PopoverContent';
import { PopoverOverlay } from './PopoverOverlay';

export type Popover = RadixPopover.PopoverContentProps & {
  modal?: boolean;
  open?: boolean;
  children: React.ReactNode;
  trigger: React.ReactNode;
  triggerProps?: RadixPopover.PopoverTriggerProps;
  overlayZIndex?: number;
  onOpenChange?: (open: boolean) => void;
};

const PopoverTrigger = RadixPopover.Trigger;
const PopoverPortal = RadixPopover.Portal;

const Popover = ({
  className,
  modal = true,
  children,
  trigger,
  triggerProps,
  overlayZIndex = 50,
  open,
  onOpenChange,
  ...props
}: Popover) => {
  return (
    <RadixPopover.Root open={open} onOpenChange={onOpenChange} modal={modal}>
      <PopoverTrigger {...triggerProps}>{trigger}</PopoverTrigger>
      <PopoverPortal>
        <>
          <PopoverOverlay className={`z-[${overlayZIndex}]`} />
          <PopoverContent
            className={cn(`!z-[${overlayZIndex + 1}]`, className)}
            sideOffset={6}
            {...props}
          >
            {children}
          </PopoverContent>
        </>
      </PopoverPortal>
    </RadixPopover.Root>
  );
};

export { Popover, PopoverTrigger, PopoverPortal };
