import { Controller, useFormContext } from 'react-hook-form';

import { Input, Label } from '@spektr/client/components';

export const InternalEmailField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Label>Email</Label>
      <Controller
        name="emailAddress"
        render={({ field }) => (
          <Input
            required
            defaultValue={field.value ?? ''}
            type="email"
            error={!!errors?.emailAddress?.message}
            dimension="default"
            placeholder="Enter a email address..."
            onChange={(value) => {
              field.onChange(value);
            }}
          />
        )}
        control={control}
      />
    </>
  );
};
