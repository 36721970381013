import { useController, useFormContext } from 'react-hook-form';

import { FormField, Select } from '@spektr/components-forms';

import type { DropdownField as DropdownFieldProp } from '@spektr/moonraker-types';

export type DropdownFieldProps = {
  field: DropdownFieldProp;
  name: string;
};

export const DropdownField = ({ field, name }: DropdownFieldProps) => {
  const context = useFormContext();

  const requiredValidation = field.validation?.find(
    (v) => v.type === 'required'
  );
  const {
    field: formField,
    fieldState: { invalid, error },
  } = useController({
    name,
    control: context.control,
    rules: {
      required: requiredValidation?.message,
    },
  });

  return (
    <FormField
      label={field.attributes.label}
      required={!!requiredValidation}
      helperText={error ? error.message : field.attributes.helperText}
      name={name}
      hasError={invalid}
    >
      <Select
        ref={formField.ref}
        options={field.attributes.options}
        name={name}
        value={formField.value}
        onValueChange={formField.onChange}
      />
    </FormField>
  );
};
