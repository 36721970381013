import { z } from 'zod';

import { objectIdSchema } from '../common';

import { edgeSchema } from './edgeSchema';
import { createNodeBaseSchema } from './createNodeBaseSchema';

export const adverseMediaAuthorityLevels = z.enum([
  'nothing',
  'suggestion',
  'solve_hits',
  'solve_alerts',
]);
export type AdverseMediaAuthorityLevels = z.infer<
  typeof adverseMediaAuthorityLevels
>;

export const allowedAlertsField = z.enum(['adverseMedia', 'pep', 'sanctions']);
export type AllowedAlertsField = z.infer<typeof allowedAlertsField>;

export const AiAmlAlertNodeInput = z.object({
  nodeType: z.literal('aiAmlAlert'),
  title: z.string().min(1),
  userId: z.string().default(''), // id of the user which hit resolutions will be acredited to. Is meant to be the spektr-ai user. Defaults to '' for backward compatibility
  adverseMedia: adverseMediaAuthorityLevels.optional().default('suggestion'),
  pep: adverseMediaAuthorityLevels.optional().default('suggestion'),
  sanctions: adverseMediaAuthorityLevels.optional().default('suggestion'),
});
export type AiAmlAlertNodeInput = z.infer<typeof AiAmlAlertNodeInput>;

export const CreateAiAmlAlertNode =
  AiAmlAlertNodeInput.merge(createNodeBaseSchema);
export type CreateAiAmlAlertNode = z.infer<typeof CreateAiAmlAlertNode>;

export const UpdateAiAmlAlertNode = AiAmlAlertNodeInput;
export type UpdateAiAmlAlertNode = z.infer<typeof UpdateAiAmlAlertNode>;

export const AiAmlAlertNode = AiAmlAlertNodeInput.merge(
  z.object({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);
export type AiAmlAlertNode = z.infer<typeof AiAmlAlertNode>;
