import { ReactNode } from 'react';
import {
  ArrowUpDown,
  FileSearch,
  FileText,
  ListChecks,
  NetworkIcon,
  SearchCode,
  ServerCog,
  Siren,
  Sparkles,
  SquareCheck,
  SquareX,
} from 'lucide-react';

import {
  IconBox,
  IconBoxVariants,
  BodaccIcon,
  ComplyAdvantageIcon,
  KyckrMinimalIcon,
  MitIdMinimalIcon,
  OpenCorporatesIcon,
  VeriffMinimalIcon,
  SpektrDataflowIcon,
  CalculatorIcon,
  SlackMinimalIcon,
  VirkMinimalIcon,
  IconBoxProps,
} from '@spektr/client/components';

import { NodeType } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';

type NodeIconProps = Omit<IconBoxProps, 'renderIcon'> & {
  nodeType: NodeType;
  fields?: Record<string, boolean>;
};

const nodeTypeColors: Partial<Record<NodeType, IconBoxVariants['color']>> = {
  bodacc: 'bodacc',
  complyAdvantageKyb: 'complyAdvantage',
  complyAdvantageKyc: 'complyAdvantage',
  kyckr: 'kyckr',
  mitId: 'mitId',
  openCorporatesMonitoring: 'openCorporates',
  openCorporatesRisk: 'openCorporates',
  veriff: 'veriff',
  veriffIdv: 'veriffIdv',
  virk: 'virk',
  form: 'cyan',
  alert: 'pink',
  manualReview: 'red',
  aiAmlAlert: 'purple',
  router: 'red',
  slack: 'gray',
  calculation: 'cyan',
  returningProcess: 'cyan',
  customerStatus: 'cyan',
  monitoringDataset: 'cyan',
  filter: 'cyan',
  loopSource: 'cyan',
  onboardingProcessSource: 'cyan',
  documentOCR: 'cyan',
  ownershipTreeForm: 'cyan',
};

export const NodeIcon = ({ nodeType, fields, ...props }: NodeIconProps) => {
  const renderIcon = (className: string): ReactNode => {
    switch (nodeType) {
      case 'router':
        return <SpektrDataflowIcon className={className} />;
      case 'slack':
        return <SlackMinimalIcon className={cn(className, 'stroke-0')} />;
      case 'calculation':
        return <CalculatorIcon className={className} />;
      case 'documentOCR':
        return <FileSearch className={className} />;
      case 'returningProcess':
        return <ArrowUpDown className={className} />;
      case 'customerStatus':
        if (fields && fields['customerStatus.approved']) {
          return <SquareCheck className={className} />;
        } else return <SquareX className={className} />;
      case 'monitoringDataset':
        return <SearchCode className={className} />;
      case 'filter':
      case 'loopSource':
      case 'onboardingProcessSource':
        return <ServerCog className={className} />;
      case 'bodacc':
        return <BodaccIcon className={cn(className, 'h-auto')} />;
      case 'complyAdvantageKyb':
      case 'complyAdvantageKyc':
        return <ComplyAdvantageIcon className={className} />;
      case 'kyckr':
        return <KyckrMinimalIcon className={className} />;
      case 'mitId':
        return <MitIdMinimalIcon className={className} />;
      case 'openCorporatesMonitoring':
      case 'openCorporatesRisk':
        return <OpenCorporatesIcon className={className} />;
      case 'veriff':
      case 'veriffIdv':
        return <VeriffMinimalIcon className={className} />;
      case 'virk':
        return <VirkMinimalIcon />;
      case 'form':
        return <FileText className={className} />;
      case 'ownershipTreeForm':
        return <NetworkIcon className={className} />;
      case 'alert':
        return <Siren className={className} />;
      case 'manualReview':
        return <ListChecks className={className} />;
      case 'aiAmlAlert':
        return <Sparkles className={className} />;
      default:
        return null;
    }
  };

  const color =
    nodeType === 'customerStatus'
      ? fields && fields['customerStatus.approved']
        ? 'cyan'
        : 'red'
      : nodeTypeColors[nodeType];

  return (
    <IconBox
      color={color}
      {...props}
      renderIcon={({ className }) => renderIcon(className)}
    />
  );
};
