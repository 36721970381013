import { z } from 'zod';

import { Field } from '@spektr/moonraker-types';

export const Block = z.object({
  id: z.string(),
  category: z.string(),
  icon: z.string(),
  title: z.string(),
  description: z.string(),
  components: z.array(Field),
});

export type Block = z.infer<typeof Block>;
