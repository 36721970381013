import { ButtonHTMLAttributes, forwardRef } from 'react';
import { Plus } from 'lucide-react';

import { cn } from '@spektr/style-utils';

export type AddNewEntryButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const AddNewEntryButton = forwardRef<
  HTMLButtonElement,
  AddNewEntryButtonProps
>(({ className, children = 'Press to add an entity', ...props }, ref) => {
  return (
    <button
      {...props}
      ref={ref}
      className={cn('flex flex-row items-center gap-1', className)}
    >
      <Plus className="h-3.5 w-3.5" />
      <span className="text-xs font-medium">{children}</span>
    </button>
  );
});
