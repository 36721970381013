import { useMemo } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { useNavigate } from 'react-router-dom';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';

import { RBAC } from '@spektr/shared/rbac';

import {
  casesCustomerParamsSchema,
  casesCustomerDetailsUrl,
} from '@spektr/shared/utils';
import { MoonrakerGraph } from '@spektr/moonraker-graph';

import { getOwnershipGraph, InsightsApiClient } from '@spektr/client/services';

import { useParsedParams } from '@spektr/shared/hooks';

import { usePermissionsContext } from '@spektr/client/providers';

import { createNodeFromPayload } from '../../utils/createNodeFromPayload';
import { createEdgeFromPayload } from '../../utils/createEdgeFromPayload';

import type { ClientRecordRelationCreate } from '@spektr/shared/validators';

export const RelationsGraph = () => {
  const navigate = useNavigate();
  const { spektrId } = useParsedParams(casesCustomerParamsSchema);

  const { hasPermission } = usePermissionsContext();
  const isActionAllowed = hasPermission(RBAC.ACTIONS.DATASET.UPDATE);

  const { data } = useSuspenseQuery(getOwnershipGraph(spektrId));
  const updateClientRecordsMutation = useMutation({
    mutationFn: (payload: {
      id: string;
      data: Partial<ClientRecordRelationCreate>;
    }) =>
      InsightsApiClient.getClient().patchClientRecordRelation(payload.data, {
        params: {
          id: payload.id,
        },
      }),
  });

  const handleSaveEdge = async (
    id: string,
    edgeData: MoonrakerGraph.Edge['data']
  ) => {
    return updateClientRecordsMutation.mutate({
      id,
      data: {
        shareholding: {
          percentage: edgeData?.ownershipShares,
          role: edgeData?.role,
        },
      },
    });
  };

  const handleNavigateToNode = (node: MoonrakerGraph.Node['data']) => {
    navigate(casesCustomerDetailsUrl(node.spektrId));
  };

  const graphData = useMemo(() => {
    const nodes: MoonrakerGraph.Node[] = [];
    const edges: MoonrakerGraph.Edge[] = [];

    if (data.payload.length > 0) {
      data.payload.forEach((node) => {
        if (node.details) {
          nodes.push(createNodeFromPayload(node));

          if (node.adj.length > 0) {
            node.adj.forEach((adj) => {
              edges.push(createEdgeFromPayload(node, adj));
            });
          }
        }
      });
    }

    return {
      nodes,
      edges,
    };
  }, [data]);

  return (
    <ReactFlowProvider>
      <MoonrakerGraph
        className="h-[695px] w-full"
        canEditEdges={isActionAllowed}
        edges={graphData.edges}
        nodes={graphData.nodes}
        spektrId={spektrId}
        onNavigateToNode={handleNavigateToNode}
        onSaveEdge={handleSaveEdge}
      />
    </ReactFlowProvider>
  );
};
