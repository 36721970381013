import { useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';

import { InsightsApiClient } from '@spektr/client/services';
import { toast } from '@spektr/client/components';

import { useCustomerDetails } from '../providers/CustomerDetailsProvider';
import { simulateDocumentDownload } from '../../utils';

export const useGetCustomerRecordProfile = () => {
  const { customer } = useCustomerDetails();

  const downloadCustomerRecordProfile = useMutation({
    mutationFn: async () => {
      return await InsightsApiClient.getClient().downloadClientRecordProfile({
        params: {
          clientRecordId: customer.spektrId,
        },
      });
    },
    onSuccess: (data) => {
      simulateDocumentDownload(
        data,
        `customer-record-${customer.name}`,
        'application/json'
      );
    },
    onError: (error) => {
      console.error(error);

      toast.error({
        title: 'Something went wrong!',
        description:
          'An error occurred when trying to download the file, please try again.',
      });
    },
  });
  return useMemo(
    () => downloadCustomerRecordProfile,
    [downloadCustomerRecordProfile]
  );
};
