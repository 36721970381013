import { useController, useFormContext } from 'react-hook-form';

import { FileInput, FormField } from '@spektr/components-forms';

import type { FileField as FileFieldProp } from '@spektr/moonraker-types';

export type FileFieldProps = {
  field: FileFieldProp;
  name: string;
};

export const FileField = ({ field, name }: FileFieldProps) => {
  const { control, setValue } = useFormContext();

  const requiredValidation = field.validation?.find(
    (v) => v.type === 'required'
  );
  const isRequired = requiredValidation?.message;

  const {
    field: formField,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: {
      required: isRequired,
    },
  });

  return (
    <FormField
      label={field.attributes.label}
      required={!!requiredValidation}
      helperText={error ? error.message : field.attributes.helperText}
      name={name}
      hasError={invalid}
    >
      <FileInput
        currentFile={formField.value}
        description={field.attributes.placeholder}
        error={invalid}
        onBlur={formField.onBlur}
        onChange={formField.onChange}
        onDrop={(acceptedFiles) => {
          const file = acceptedFiles[0];

          if (!file) return;

          const fileName = file.name;
          const fileType = file.type;
          const blob = new Blob([file], { type: file.type });

          setValue(name, new File([blob], fileName, { type: fileType }), {
            shouldValidate: true,
          });
        }}
        onClear={() => {
          setValue(name, undefined);
        }}
      />
    </FormField>
  );
};
