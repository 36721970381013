import { cn } from '@spektr/style-utils';

export const dunBradstreetContained = cn(
  'bg-color-dunBradstreet',
  'text-color-text-button-contained-vendor',
  'hover:bg-color-dunBradstreet/80',
  'active:bg-color-dunBradstreet/90'
);
export const dunBradstreetOutlined = cn(
  dunBradstreetContained,
  'border-color-dunBradstreet',
  'text-color-dunBradstreet',
  'hover:bg-color-dunBradstreet/10',
  'active:bg-color-dunBradstreet/20',
  'disabled:border-color-dunBradstreet/50'
);
export const dunBradstreetText = cn('text-color-dunBradstreet');
