import { CheckedState } from '@radix-ui/react-checkbox';
import { useController, useFormContext } from 'react-hook-form';

import { FormField, Checkbox } from '@spektr/components-forms';

import type { CheckboxField as CheckboxFieldProp } from '@spektr/moonraker-types';

export type CheckboxFieldProps = {
  field: CheckboxFieldProp;
  name: string;
};

export const CheckboxField = ({ field, name }: CheckboxFieldProps) => {
  const context = useFormContext();

  const requiredValidation = field.validation?.find(
    (v) => v.type === 'required'
  );

  const {
    field: formField,
    fieldState: { invalid, error },
  } = useController({
    name,
    control: context.control,
    defaultValue: [],
    rules: {
      required: requiredValidation?.message,
    },
  });

  const handleChange = (option: string) => (checked: CheckedState) => {
    if (typeof checked === 'string') return;

    if (checked) {
      formField.onChange([...formField.value, option]);
    } else {
      formField.onChange(formField.value.filter((v: string) => v !== option));
    }
  };

  return (
    <FormField
      className="gap-2"
      label={field.attributes.label}
      required={!!requiredValidation}
      helperText={error ? error.message : field.attributes.helperText}
      helperTextPosition="top"
      name={name}
      hasError={invalid}
    >
      <div className="flex flex-row flex-wrap gap-4">
        {field.attributes.options.map((option) => (
          <Checkbox
            key={option}
            label={option}
            value={option}
            checked={formField.value.includes(option)}
            onCheckedChange={handleChange(option)}
          />
        ))}
      </div>
    </FormField>
  );
};
