import { ProcessType } from '../process';
import { NodeType } from '../node/node';

export const NODE_ACCESS_MATRIX: Readonly<
  Record<NodeType, Array<ProcessType>>
> = {
  alert: ['risk', 'monitoring', 'loop', 'score', 'onboarding', 'enrichment'],
  form: ['loop', 'onboarding'],
  ownershipTreeForm: ['loop', 'onboarding'],
  bodacc: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  calculation: [
    'risk',
    'monitoring',
    'loop',
    'score',
    'onboarding',
    'enrichment',
  ],
  documentOCR: ['enrichment', 'loop', 'onboarding'],
  complyAdvantageKyb: [
    'risk',
    'monitoring',
    'loop',
    'score',
    'onboarding',
    'enrichment',
  ],
  complyAdvantageKyc: [
    'risk',
    'monitoring',
    'loop',
    'score',
    'onboarding',
    'enrichment',
  ],
  filter: ['risk', 'monitoring', 'score', 'enrichment'],
  kyckr: ['risk', 'monitoring', 'loop', 'score', 'onboarding', 'enrichment'],
  loopSource: ['loop'],
  onboardingProcessSource: ['onboarding'],
  mitId: ['loop', 'onboarding'],
  monitoringDataset: ['risk', 'monitoring', 'score'],
  openCorporatesMonitoring: ['monitoring'],
  openCorporatesRisk: ['risk', 'loop', 'score', 'onboarding', 'enrichment'],
  outcome: ['risk', 'monitoring', 'loop', 'score', 'onboarding', 'enrichment'],
  returningProcess: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  router: ['risk', 'monitoring', 'loop', 'score', 'onboarding', 'enrichment'],
  slack: ['risk', 'monitoring', 'loop', 'score', 'onboarding', 'enrichment'],
  veriff: ['loop', 'onboarding'],
  veriffIdv: ['loop', 'onboarding', 'enrichment'],
  manualReview: [
    'risk',
    'monitoring',
    'loop',
    'score',
    'onboarding',
    'enrichment',
  ],
  aiAmlAlert: [
    'risk',
    'monitoring',
    'loop',
    'score',
    'onboarding',
    'enrichment',
  ],
  customerStatus: ['risk', 'monitoring', 'loop', 'score', 'onboarding'],
  virk: ['onboarding', 'enrichment', 'loop', 'monitoring'],
};
