import { forwardRef } from 'react';

import { cn } from '@spektr/style-utils';

import { VariantProps } from 'class-variance-authority';

import { Button, ButtonProps } from '@spektr/client/components';

import { ServiceVariantProps } from '../types/ServiceVariantProps';

import { AsyncButton } from '../AsyncButton';

import { BaseVariantProps } from '../types/BaseVariantProps';

import { serviceButtonVariants } from './serviceButtonVariants';

export type ServiceButtonProps = Omit<ButtonProps, keyof BaseVariantProps> &
  ServiceVariantProps &
  Omit<AsyncButton.Props, 'color'>;

export const ServiceButton = forwardRef<HTMLButtonElement, ServiceButtonProps>(
  (
    {
      children,
      className,
      variant: variantProps = 'contained',
      color = 'primary',
      size = 'default',
      isPending,
      pendingLabel,
      label,
      ...props
    },
    ref
  ) => {
    const variant = `${variantProps}-${color}`;
    const buttonClassName = cn(
      serviceButtonVariants({
        variant: variant as VariantProps<
          typeof serviceButtonVariants
        >['variant'],
        size,
        className,
      })
    );

    if (pendingLabel) {
      return (
        <AsyncButton
          {...props}
          className={buttonClassName}
          disabled={props.disabled}
          isPending={isPending}
          label={label}
          pendingLabel={pendingLabel}
        />
      );
    }

    return (
      <Button {...props} ref={ref} className={buttonClassName}>
        {children}
      </Button>
    );
  }
);
