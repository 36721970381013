import { useMemo, useRef, useState } from 'react';
import { Search } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { useDebouncedSearch } from '../../hooks/useDebouncedSearch';

import { Popover } from '../Popover';
import { Select } from '../Select';
import { Input } from '../Input';

import { TriggerContent } from './components/TriggerContent';
import { OptionItem } from './components/OptionItem';
import { CountryFlag } from './components/CountryFlag';

export namespace CountrySelect {
  export type Props = Pick<
    Select.Props,
    'name' | 'value' | 'options' | 'placeholder' | 'autoComplete'
  > & {
    defaultValue?: string;
    inputProps?: Input.Props;
    onSearch: (value: string) => void;
    onValueChange: (value: string) => void;
  };
}

export const CountrySelect = ({
  defaultValue,
  options,
  placeholder,
  value,
  onValueChange,
  inputProps,
  onSearch,
  autoComplete,
}: CountrySelect.Props) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { searchValue, setSearchValue } = useDebouncedSearch(
    defaultValue,
    onSearch
  );

  const handleChange = (value: string) => {
    if (value === defaultValue) {
      return;
    }

    onValueChange(value);
  };

  const handleClickInsideCombobox = (
    event: React.MouseEvent<HTMLUListElement> & {
      target: HTMLLIElement;
    }
  ) => {
    const value = event.target.getAttribute('data-value');

    if (value) {
      setSearchValue(value);
      handleChange(value);
      setPopoverOpen(false);
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      searchInputRef.current?.focus();
      searchInputRef.current?.select();
    }
    setPopoverOpen(open);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!isPopoverOpen) {
      handleChange(searchValue);
    }
    inputProps?.onBlur?.(e);
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const input = e.currentTarget;

    if (e.key === ' ' && isPopoverOpen) {
      e.preventDefault();
      const cursorPosition = input.selectionStart;
      if (cursorPosition !== null) {
        setSearchValue(
          (prevValue: string) =>
            prevValue.slice(0, cursorPosition) +
            ' ' +
            prevValue.slice(cursorPosition)
        );

        setTimeout(() => {
          input.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
        }, 0);
      }
    } else if (e.key === 'Enter') {
      setPopoverOpen(false);
    }
  };

  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );

  const displayValue = useMemo(() => {
    return selectedOption ? (
      <div
        className={cn('flex items-center gap-2', 'pl-3', 'text-sm font-medium')}
      >
        <CountryFlag countryCode={selectedOption.value} />
        <span className="line-clamp-1 text-left">{selectedOption.label}</span>
      </div>
    ) : null;
  }, [selectedOption]);

  return (
    <Popover
      align="start"
      open={isPopoverOpen}
      onOpenChange={handleOpenChange}
      onOpenAutoFocus={(e) => e.preventDefault()}
      trigger={
        <TriggerContent
          data-cy="country"
          autoComplete={autoComplete}
          prefix={(<Search className={cn('h-4 w-4 shrink-0')} />) as any}
          value={searchValue}
          placeholder={placeholder}
          shouldDisplayValue={isPopoverOpen}
          displayValue={displayValue}
          onChange={handleSearchInputChange}
          onBlur={handleSearchInputBlur}
          onKeyDown={handleKeydown}
          ref={searchInputRef}
        />
      }
    >
      {options.length === 0 ? (
        <div className="p-2 text-center text-sm text-gray-600">
          No options available
        </div>
      ) : (
        <ul
          className={cn(
            'max-h-64 w-full px-2 py-1',
            'list-none',
            'overflow-y-auto'
          )}
          onClick={handleClickInsideCombobox}
          onWheel={(e) => e.stopPropagation()}
        >
          {options.map((option) => (
            <OptionItem
              key={option.value}
              option={option}
              isSelected={option.value === value}
            />
          ))}
        </ul>
      )}
    </Popover>
  );
};
