import { View } from '../../../../components/View';
import { fieldsRoutes, type Widget } from '../../../../types';
import { DraggableItemsList } from '../../../../components/DraggableItemsList';

import { WidgetItem } from '../WidgetItem';
import { FieldTypeCard } from '../FieldTypeCard';

type FieldsViewsProsp = {
  widgets: Widget[];
};

export const FieldsViews = ({ widgets }: FieldsViewsProsp) => {
  return (
    <div>
      <View route="fields">
        <div className="flex flex-col gap-3">
          {fieldsRoutes.map((route) => (
            <FieldTypeCard key={route} route={route} />
          ))}
        </div>
      </View>

      {fieldsRoutes.map((route) => (
        <View key={route} route={route}>
          <DraggableItemsList
            data={widgets.filter((widget) => widget.type === route)}
            renderItem={(block) => <WidgetItem widget={block as Widget} />}
          />
        </View>
      ))}
    </div>
  );
};
