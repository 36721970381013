import { Handle, type NodeProps, Position } from 'reactflow';

import { cn } from '@spektr/style-utils';
import { Skeleton } from '@spektr/client/components';

export const SkeletonNode = ({ id }: NodeProps) => {
  return (
    <div
      className={cn(
        'relative',
        'flex flex-row items-center gap-3',
        'h-16 w-64 p-3',
        'rounded-lg border border-zinc-300 dark:border-zinc-700',
        'bg-white dark:bg-zinc-900'
      )}
    >
      <Handle
        className={cn(
          'h-2 w-2',
          'border border-zinc-300 dark:border-zinc-700',
          'bg-white dark:bg-zinc-900'
        )}
        id={id}
        position={Position.Top}
        type="target"
      />
      <div
        className={cn(
          'flex shrink-0 items-center justify-center',
          'h-9 w-9',
          'rounded-md border border-zinc-300 dark:border-zinc-700',
          'bg-zinc-100/20 dark:bg-zinc-800/20'
        )}
      >
        <Skeleton className="h-4 w-4" />
      </div>
      <div className="flex flex-col gap-1">
        <Skeleton className="h-3.5 w-36" />
        <Skeleton className="h-3 w-20" />
      </div>
      <Handle
        className={cn(
          'h-2 w-2',
          'border border-zinc-300 dark:border-zinc-700',
          'bg-white dark:bg-zinc-900'
        )}
        id={id}
        position={Position.Bottom}
        type="source"
      />
    </div>
  );
};
