import { z } from 'zod';

const primitivesTypes = [
  'input',
  'select',
  'radio',
  'checkbox',
  'dropdown',
] as const;

export type PrimitiveType = (typeof primitivesTypes)[number];

export const Primitive = z.object({
  id: z.string(),
  category: z.string(),
  title: z.string(),
  icon: z.string(),
  isStructured: z.boolean(),
  type: z.enum([
    'entity',
    'input',
    'select',
    'radio',
    'checkbox',
    'file',
    'title',
    'paragraph',
    'divider',
    'dropdown',
    'consent',
  ]),
});

export type Primitive = z.infer<typeof Primitive>;
