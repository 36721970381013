import {
  JsonView,
  Props as JsonViewProps,
  allExpanded,
  darkStyles,
  defaultStyles,
} from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import { useTheme } from '@spektr/client/providers';
export namespace JSONViewer {
  export type Props = JsonViewProps & {
    data: Record<string, unknown>;
  };
}

export const JSONViewer = ({ data, style, ...props }: JSONViewer.Props) => {
  const { theme } = useTheme();
  return (
    <JsonView
      data={data}
      shouldExpandNode={allExpanded}
      style={{
        ...(theme === 'dark' ? darkStyles : defaultStyles),
        container: 'bg-transparent',
        ...style,
      }}
      {...props}
    />
  );
};
