import { useMemo } from 'react';
import { useAsyncFn } from 'react-use';
import { useReactFlow } from 'reactflow';
import { useForm } from 'react-hook-form';

import { AsyncButton } from '@spektr/client/components';

import { PercentageInput } from '../PercentageInput';
import { EntityLink } from '../EntityLink';

import { getEdgeLabel } from '../../utils/getEdgeLabel';

import { useGraphState } from '../../hooks/useGraphState';

import type { EdgeData } from '../../types/EdgeData';
import type { NodeData } from '../../types/NodeData';

export namespace ConnectionPopover {
  export type Props = {
    id: string;
    connection: EdgeData;
    source: string;
    target: string;
    onClose: () => void;
  };
}

function createEntityProp(data?: NodeData): EntityLink.Entity {
  return {
    name: data?.name ?? 'Untitled',
    subtext: data?.subtext ?? 'Unknown',
    type: data?.type ?? 'unknown',
  };
}

type FormValues = Omit<EdgeData, 'label'>;

export const ConnectionPopover = ({
  id,
  connection,
  source,
  target,
  onClose,
}: ConnectionPopover.Props) => {
  const { canEditEdges, onSaveEdge } = useGraphState();
  const [state, performAction] = useAsyncFn(onSaveEdge);
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      ownershipShares: connection.ownershipShares,
      role: connection.role,
    },
  });

  const { getNode, setEdges } = useReactFlow();

  const sourceNode = useMemo<EntityLink.Entity>(
    () => createEntityProp(getNode(source)?.data),
    [getNode, source]
  );
  const targetNode = useMemo<EntityLink.Entity>(
    () => createEntityProp(getNode(target)?.data),
    [getNode, target]
  );

  const handleUpdateEdge = async (values: FormValues) => {
    const nextEdgeData = {
      ...connection,
      ...values,
      label: getEdgeLabel(values.ownershipShares),
    };

    setEdges((edges) =>
      edges.map((edge) => {
        if (edge.id === id) {
          return {
            ...edge,
            data: nextEdgeData,
          };
        }

        return edge;
      })
    );

    await performAction(id, nextEdgeData);

    onClose();
  };

  return (
    <form
      className="flex flex-col p-6"
      onSubmit={handleSubmit(handleUpdateEdge)}
    >
      <p className="text-sm font-medium text-black dark:text-white">
        Relationship
      </p>
      <div className="my-4 flex flex-col gap-6">
        <EntityLink parent={sourceNode} child={targetNode} />

        <PercentageInput
          icon={'Newspaper'}
          label="Ownership of shares"
          disabled={!canEditEdges || state.loading}
          placeholder="Input ownership of shares"
          {...register('ownershipShares')}
        />
      </div>

      <AsyncButton
        color="secondary"
        className="w-full"
        disabled={!canEditEdges}
        isPending={state.loading}
        label="Save"
        pendingLabel="Saving..."
        type="submit"
      />
    </form>
  );
};
