import { useMutation } from '@tanstack/react-query';
import camelCase from 'lodash/camelCase';

import { InsightsApiClient } from '@spektr/client/services';

import { simulateDocumentDownload } from '../../utils';

export const useDownloadServiceJson = (
  processName: string,
  serviceName: string,
  customerId: string,
  vendorSearchId: string
) => {
  const downloadServiceDataJson = useMutation({
    mutationFn: async () => {
      return await InsightsApiClient.getClient().getServiceData({
        params: {
          clientRecordId: customerId,
          vendorSearchId,
        },
      });
    },
    onSuccess: (data) => {
      simulateDocumentDownload(
        data.response,
        `${camelCase(processName)}-${serviceName}-data.json`,
        'application/json'
      );
    },
  });

  return downloadServiceDataJson;
};
