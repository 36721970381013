import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { getTagsQuery } from '@spektr/client/services';
import { useGetDatasets } from '@spektr/platform-hooks';
import { DropdownOption, StatusWithIcon, Tag } from '@spektr/client/components';
import {
  alertPresence,
  alertTypes,
  ClientRecordFilter,
  clientRecordStatuses,
  PaginationOptions,
} from '@spektr/shared/validators';

import { getCustomerStatusIcon, getCustomerStatusVariant } from '../constants';

export const useGetCustomersDropdownFiltersOptions = (
  filters: ClientRecordFilter & PaginationOptions
) => {
  const customerFilters = filters;

  const { t } = useTranslation('cases');
  const datasets = useGetDatasets();
  const { data: tags } = useSuspenseQuery(getTagsQuery());

  const separatorSubOption: DropdownOption = useMemo(
    () => ({
      type: 'separator',
      value: '',
    }),
    []
  );

  const customerSourcesFilterOptions: DropdownOption[] = datasets
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((dataset, index) => {
      const sourceOptions: DropdownOption[] = [];

      const sourceOption: DropdownOption = {
        label: dataset.name,
        type: 'item',
        value: dataset.id,
        selected: customerFilters.datasetIds?.[0]?.includes(dataset.id),
      };

      sourceOptions.push(sourceOption);
      if (index !== datasets.length - 1) {
        sourceOptions.push(separatorSubOption);
      }

      return sourceOptions;
    })
    .flat();

  const customerStatusesFilterOptions: DropdownOption[] = clientRecordStatuses
    .map((status, index) => {
      const statusesOptions: DropdownOption[] = [];

      const statusOption: DropdownOption = {
        label: (
          <StatusWithIcon
            color={getCustomerStatusVariant(status)}
            label={status}
            icon={getCustomerStatusIcon(status)}
          />
        ),
        type: 'item',
        value: status,
        selected: filters.status?.[0]?.includes(status),
      };

      statusesOptions.push(statusOption);
      if (index !== clientRecordStatuses.length - 1) {
        statusesOptions.push(separatorSubOption);
      }

      return statusesOptions;
    })
    .flat();

  const customerTagFilterOptions: DropdownOption[] = tags
    .map((tag, index) => {
      const tagsOptions: DropdownOption[] = [];

      const tagOption: DropdownOption = {
        label: (
          <Tag label={tag.label} color={tag.color} isDeleted={tag.isDeleted} />
        ),
        type: 'item',
        value: tag.id,
        selected: customerFilters.tags?.[0]?.includes(tag.id),
      };

      tagsOptions.push(tagOption);
      if (index !== tags.length - 1) {
        tagsOptions.push(separatorSubOption);
      }

      return tagsOptions;
    })
    .flat();

  const customerAlertPresenceFilterOptions: DropdownOption[] = alertPresence
    .map((presence, index) => {
      const alertPresenceOptions: DropdownOption[] = [];

      const alertPresenceOption: DropdownOption = {
        label: t(`customers.alertPresence.${presence}`),
        type: 'item',
        value: presence,
        selected: customerFilters.alertPresence === presence,
      };

      alertPresenceOptions.push(alertPresenceOption);
      if (index !== alertPresence.length - 1) {
        alertPresenceOptions.push(separatorSubOption);
      }

      return alertPresenceOptions;
    })
    .flat();

  const customerAlertTypesFilterOptions: DropdownOption[] = alertTypes
    .map((alertType, index) => {
      const alertTypesOptions: DropdownOption[] = [];

      const alertTypeOption: DropdownOption = {
        label: t(`alertTypes.${alertType}`),
        type: 'item',
        value: alertType,
        selected: filters.alertType?.[0]?.includes(alertType),
      };

      alertTypesOptions.push(alertTypeOption);
      if (index !== alertTypes.length - 1) {
        alertTypesOptions.push(separatorSubOption);
      }

      return alertTypesOptions;
    })
    .flat();

  const customerUboFilterOptions: DropdownOption[] = useMemo(
    () => [
      {
        label: t('customers.ubo.true'),
        value: 'true',
        type: 'item',
        selected: customerFilters.isUbo === 'true',
      },
      separatorSubOption,
      {
        label: t('customers.ubo.false'),
        value: 'false',
        type: 'item',
        selected: customerFilters.isUbo === 'false',
      },
    ],
    [customerFilters.isUbo, separatorSubOption, t]
  );

  const dropdownOptions: { [key: string]: DropdownOption[] } = useMemo(
    () => ({
      datasetIds: customerSourcesFilterOptions,
      tags: customerTagFilterOptions,
      status: customerStatusesFilterOptions,
      alertPresence: customerAlertPresenceFilterOptions,
      alertType: customerAlertTypesFilterOptions,
      isUbo: customerUboFilterOptions,
    }),
    [
      customerSourcesFilterOptions,
      customerTagFilterOptions,
      customerStatusesFilterOptions,
      customerAlertPresenceFilterOptions,
      customerAlertTypesFilterOptions,
      customerUboFilterOptions,
    ]
  );

  return dropdownOptions;
};
