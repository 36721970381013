import { HTMLAttributes, ReactNode } from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

export namespace HelperText {
  export type Props = HTMLAttributes<HTMLSpanElement> &
    VariantProps<typeof variants> & {
      children: ReactNode;
    };
}

export const HelperText = ({
  children,
  className,
  error,
  ...props
}: HelperText.Props) => {
  return (
    <span className={cn(variants({ error }), className)} {...props}>
      {children}
    </span>
  );
};

const variants = cva('text-xs font-medium', {
  variants: {
    error: {
      true: 'text-red-500',
      false: 'text-zinc-500',
    },
  },
  defaultVariants: {
    error: false,
  },
});
