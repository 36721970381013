import { forwardRef, ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

import { Input } from '../../../Input';

export namespace TriggerContent {
  export type Props = Input.Props & {
    shouldDisplayValue?: boolean;
    displayValue?: ReactNode;
  };
}

export const TriggerContent = forwardRef<
  HTMLInputElement,
  TriggerContent.Props
>(({ shouldDisplayValue = false, displayValue, ...props }, ref) => {
  return (
    <div className="relative h-full w-full">
      {displayValue && (
        <div
          className={cn('absolute inset-0.5', 'hidden', 'bg-white', {
            flex: shouldDisplayValue === false,
          })}
        >
          {displayValue}
        </div>
      )}
      <Input {...props} ref={ref} />
    </div>
  );
});
