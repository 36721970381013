import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

import { HelperText } from '../HelperText';
import { Label } from '../Label';

export namespace FormField {
  export type Props = {
    children: ReactNode;
    className?: string;
    helperText?: string;
    helperTextPosition?: 'top' | 'bottom';
    label: string;
    name: string;
    required?: boolean;
    hasError?: boolean;
  };
}

export const FormField = ({
  children,
  className,
  helperText,
  helperTextPosition = 'bottom',
  label,
  name,
  required = false,
  hasError = false,
}: FormField.Props) => {
  return (
    <div className={cn('flex flex-col gap-1 py-3', className)}>
      <Label
        htmlFor={name}
        className="mb-1"
        error={hasError}
        required={required}
      >
        {label}
      </Label>
      {helperTextPosition === 'top' && (
        <HelperText error={hasError}>{helperText}</HelperText>
      )}
      {children}
      {helperTextPosition === 'bottom' && (
        <HelperText error={hasError}>{helperText}</HelperText>
      )}
    </div>
  );
};
