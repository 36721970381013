import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { maxRateLimitUnits } from '@spektr/shared/validators';

import {
  ActionButton,
  Input,
  Label,
  PasswordInput,
  Select,
} from '@spektr/client/components';

import { RBAC } from '@spektr/shared/rbac';

import { StatusCheck } from '../../../../ProcessScheduleDialog/components/StatusCheck';

import { UserSettings, userSettingsValidationSchema } from '../../types';

type ExportMethodFormProps = {
  data?: UserSettings;
  isSubmitDisabled?: boolean;
  isUpdate?: boolean;
  onSubmit: (data: UserSettings) => void;
};

const intervalOptions = maxRateLimitUnits.map((interval) => ({
  label: interval,
  value: interval,
}));
const deliveryMethodOptions = [
  {
    label: 'AWS SQS',
    value: 'awsSqs',
    disabled: true,
  },
  {
    label: 'HTTPS Push',
    value: 'httpsPush',
  },
  {
    label: 'Slack',
    value: 'slack',
  },
  {
    label: 'None',
    value: 'none',
  },
];

export const ExportMethodForm = ({
  data,
  isSubmitDisabled = false,
  onSubmit,
  isUpdate = false,
}: ExportMethodFormProps) => {
  const {
    register,
    watch,
    control,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<UserSettings>({
    resolver: zodResolver(userSettingsValidationSchema),
    defaultValues: { ...data, deliveryMethod: data?.deliveryMethod || 'none' },
  });

  const deliveryMethod = watch('deliveryMethod');

  const handleSubmit = (data: UserSettings) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      {/* TODO: @Alex Remove as part of ST-658. Issue https://issues.chromium.org/issues/41239842 */}
      <input
        name="fake-field"
        type="password"
        className="hidden"
        autoComplete="new-password"
      ></input>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1.5">
          <div className="flex items-center gap-1.5">
            <div className="flex basis-1/2 flex-col gap-1.5">
              <Label htmlFor="numberOfCalls">Rate limit</Label>
              <Input
                id="numberOfCalls"
                type="number"
                placeholder="Number of calls"
                required
                error={Boolean(errors.numberOfCalls)}
                {...register('numberOfCalls', { required: true })}
                autoComplete="export-settings-number-of-calls"
                data-cy="maxRateLimit"
              />
            </div>

            <div
              className="flex basis-1/2 flex-col gap-1.5"
              data-cy="maxRateLimitUnit"
            >
              <Label>Interval</Label>
              <Controller
                name="interval"
                render={({ field }) => (
                  <Select
                    required
                    {...field}
                    placeholder="Time interval"
                    options={intervalOptions}
                    onValueChange={field.onChange}
                  />
                )}
                control={control}
              />
            </div>
          </div>
          {errors.numberOfCalls && (
            <span className="text-color-red text-sm">
              {errors.numberOfCalls.message}
            </span>
          )}
        </div>
        <div className="flex flex-col gap-1.5" data-cy="deliveryMethod">
          <Label>Delivery method</Label>
          <Controller
            name="deliveryMethod"
            render={({ field }) => (
              <Select
                required
                {...field}
                placeholder="Select export method"
                options={deliveryMethodOptions}
                onValueChange={field.onChange}
                selectTriggerProps={{
                  id: 'deliveryMethod',
                }}
              />
            )}
            control={control}
          />
        </div>

        {deliveryMethod === 'httpsPush' && (
          <>
            <div className="flex flex-col gap-1.5">
              <Label>HTTPS endpoint</Label>
              <Input
                required
                placeholder="Enter your https endpoint"
                error={Boolean(errors.httpsEndpoint)}
                {...register('httpsEndpoint')}
                autoComplete="export-settings-https-endpoint"
                data-cy="httpsPushEndpoint"
              />
              {errors.httpsEndpoint && (
                <span className="text-color-red text-sm">
                  {errors.httpsEndpoint.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-1.5">
              <Label>API key</Label>
              <PasswordInput
                required
                placeholder="Enter your API key"
                {...register('apiKey')}
                autoComplete="export-settings-api-key"
                data-cy="httpsPushApiKey"
              />
            </div>
          </>
        )}

        {deliveryMethod === 'slack' && (
          <>
            <div className="flex flex-col gap-1.5">
              <Label>Channel ID</Label>
              <Input
                required
                placeholder="Enter your slack channel ID"
                error={Boolean(errors.slackChannelId)}
                {...register('slackChannelId')}
                autoComplete="export-settings-slack-channelId"
                data-cy="slackChannelId"
              />
              {errors.slackChannelId && (
                <span className="text-color-red text-sm">
                  {errors.slackChannelId.message}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-1.5">
              <Label>Token</Label>
              <PasswordInput
                required
                placeholder="Enter your slack token"
                {...register('slackToken')}
                autoComplete="export-settings-slack-token"
                data-cy="slackToken"
              />
              {errors.slackToken && (
                <span className="text-color-red text-sm">
                  {errors.slackToken.message}
                </span>
              )}
            </div>
          </>
        )}

        {data?.spektrIps?.length && (
          <div className="flex flex-col gap-1.5">
            <Label>spektr IPs</Label>
            <ul className="flex max-h-[80px] flex-col gap-1 overflow-y-auto rounded-md border p-2">
              {data.spektrIps.map((ip) => (
                <li key={ip} className="text-color-text-subtext text-sm">
                  {ip}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="grid grid-cols-2 gap-2">
          <ActionButton
            rbacAction={RBAC.ACTIONS.USER_SETTINGS.SET}
            disabled={isSubmitDisabled}
            type="submit"
            color="primary"
            data-cy={isUpdate ? 'update-settings' : 'create-settings'}
          >
            {isUpdate ? 'Update' : 'Create'}
          </ActionButton>
          {isUpdate && deliveryMethod !== 'none' && (
            <StatusCheck
              animated
              className="ml-auto"
              color="cyan"
              label="Active"
            />
          )}
        </div>
      </div>
    </form>
  );
};
