import { ChevronDown } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef,
} from 'react';

import { Icon, Trigger } from '@radix-ui/react-select';

import { cn } from '@spektr/style-utils';

export type SelectTriggerProps = ComponentPropsWithoutRef<typeof Trigger> & {
  icon?: ReactNode;
  showChevron?: boolean;
};

export const SelectTrigger = forwardRef<
  ElementRef<typeof Trigger>,
  SelectTriggerProps
>(({ className, children, icon, showChevron = true, ...props }, ref) => (
  <Trigger
    ref={ref}
    className={cn(
      'flex items-center gap-2',
      'h-9 w-full px-3 py-2',
      'rounded-md border border-zinc-500',
      'bg-white',
      'text-black',
      'whitespace-nowrap text-sm font-medium',
      'focus:border-blue-500 focus-visible:shadow-md focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue-500',
      'disabled:cursor-not-allowed disabled:border-none disabled:text-zinc-400 disabled:opacity-50',
      className
    )}
    {...props}
  >
    {icon && (
      <Icon asChild className="shrink-0">
        {icon}
      </Icon>
    )}
    <span className="line-clamp-1">{children}</span>
    {showChevron && (
      <Icon asChild className="ml-auto shrink-0">
        <ChevronDown className="h-4 w-4 text-zinc-500" />
      </Icon>
    )}
  </Trigger>
));
