import { type ComponentType } from 'react';
import { cva } from 'class-variance-authority';
import {
  CircleDashed,
  CircleHelp,
  Store,
  User,
  type LucideProps,
} from 'lucide-react';

import { cn } from '@spektr/style-utils';

import type { ClientRecordStatus } from '@spektr/shared/validators';
import type { NodeRecordType } from '../../types/NodeData';

export namespace CaseIcon {
  export type Props = {
    className?: string;
    status?: ClientRecordStatus;
    type: NodeRecordType;
  };
}

const MAP_ICONS: Record<NodeRecordType, ComponentType<LucideProps>> = {
  company: Store,
  individual: User,
  unknown: CircleHelp,
  pending: CircleDashed,
};

export const CaseIcon = ({
  className,
  status = 'pending',
  type,
}: CaseIcon.Props) => {
  const CaseIcon = MAP_ICONS[type];

  return (
    <div className={cn(variants({ status }), className)}>
      <CaseIcon className="h-4 w-4" />
    </div>
  );
};

const variants = cva(
  [
    'flex shrink-0 items-center justify-center',
    'h-9 w-9',
    'rounded-md border',
    'bg-zinc-100/20',
  ],
  {
    variants: {
      status: {
        approved: ['border-green-500/50', 'text-green-500'],
        pending: 'dark:bg-zinc-900',
        rejected: ['border-red-500/50', 'text-red-500'],
      },
    },
  }
);
