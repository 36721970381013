import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  getProcessesQuery,
  getTeamMembersQuery,
} from '@spektr/client/services';
import { InfiniteScroll } from '@spektr/client/components';

import { ClientRecordStatusChangeEventData } from '@spektr/shared/validators';

import { useGetTimeline } from '../../hooks';

import {
  CustomerTimelineList,
  CustomerTimelineRow,
  TimelineEventFactory,
} from '../../components';
import { CustomerTimelineDateCell } from '../../components/CustomerTimelineDateCell';

export const CustomerTimelineContainer = () => {
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());
  const { data: processes } = useSuspenseQuery(getProcessesQuery());
  const {
    timeline,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useGetTimeline();

  const filteredTimeline = useMemo(
    () =>
      timeline.filter((event) => {
        if (event.type === 'customer_status_change') {
          const statusChangeEvent = event as ClientRecordStatusChangeEventData;
          return (
            statusChangeEvent.eventData.oldStatus !==
            statusChangeEvent.eventData.newStatus
          );
        }

        return true;
      }),
    [timeline]
  );

  return filteredTimeline.length > 0 ? (
    <InfiniteScroll
      className="flex flex-col gap-6"
      fetchMoreItems={fetchNextPage}
      hasMoreItems={hasNextPage}
      isFetching={isFetching || isFetchingNextPage}
      loadMoreText="Loading more comments..."
    >
      <CustomerTimelineList>
        {filteredTimeline.map((event, index) => (
          <CustomerTimelineRow
            key={`${event.type}-${index}`}
            isLast={index === filteredTimeline.length - 1}
          >
            <CustomerTimelineDateCell date={event.createdAt} />
            <TimelineEventFactory
              event={event}
              users={users}
              processes={processes}
            />
          </CustomerTimelineRow>
        ))}
      </CustomerTimelineList>
      <Outlet />
    </InfiniteScroll>
  ) : (
    <div className="bg-color-bg-card-default flex justify-center rounded-md border px-3 py-7 text-sm font-medium">
      No timeline events.
    </div>
  );
};
