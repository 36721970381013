import { cn } from '@spektr/style-utils';

import { Select } from '../../../Select';

import { CountryFlag } from '../CountryFlag';

export namespace OptionItem {
  export type Props = {
    option: Select.Option;
    isSelected?: boolean;
  };
}

export const OptionItem = ({
  option,
  isSelected = false,
}: OptionItem.Props) => (
  <li
    key={option.value}
    className={cn(
      'w-full',
      'rounded-sm',
      'text-sm font-medium',
      'color-black',
      'hover:bg-zinc-300'
    )}
  >
    <button
      type="button"
      className={cn('flex items-center gap-2', 'w-full p-1 pl-2', 'text-left', {
        'font-semibold': isSelected,
      })}
      data-value={option.value}
    >
      <CountryFlag countryCode={option.value} />
      {option.label}
    </button>
  </li>
);
