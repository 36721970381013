import { z } from 'zod';

import { SpektrData } from '../spektrfield';
import { timelineEvent } from '../events';

import {
  ClientRecordDocumentModel,
  ClientRecordStatuses,
} from './clientRecord';

export const DownloadClientRecordDocument = z.object({
  clientRecord: z.object({
    status: ClientRecordStatuses,
    docs: z.array(ClientRecordDocumentModel),
    data: SpektrData,
  }),
  timeline: z.array(timelineEvent),
  alerts: z.array(
    z.object({
      name: z.string(),
      status: z.string(),
      resolvedBy: z.string().optional(),
      type: z.string(),
    })
  ),
});
export type DownloadClientRecordDocument = z.infer<
  typeof DownloadClientRecordDocument
>;
