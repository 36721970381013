import { useState } from 'react';
import { NodeProps } from 'reactflow';

import { Popover } from '@spektr/client/components';

import { BasicNodeContent } from '../BasicNodeContent';
import { ViewerNodePopover } from '../ViewerNodePopover';

import type { NodeData } from '../../types/NodeData';

export const BasicNode = ({ id, data }: NodeProps<NodeData>) => {
  const [open, setOpen] = useState(false);
  return (
    <Popover
      align="center"
      alignOffset={0}
      className="w-full min-w-80 max-w-96 bg-white dark:bg-zinc-900"
      side="right"
      sideOffset={10}
      triggerProps={{
        asChild: true,
      }}
      open={open}
      trigger={
        <div>
          <BasicNodeContent id={id} data={data} />
        </div>
      }
      onOpenChange={setOpen}
    >
      <ViewerNodePopover data={data} onClose={() => setOpen(false)} />
    </Popover>
  );
};
