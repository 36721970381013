import { useTranslation } from 'react-i18next';
import { Info } from 'lucide-react';

import { Label, Tooltip } from '@spektr/client/components';

type PasswordInputLabelProps = {
  htmlFor: string;
  label?: string;
};

export const NewPasswordInputLabel = ({
  htmlFor,
  label,
}: PasswordInputLabelProps) => {
  const { t } = useTranslation('common');

  return (
    <Label htmlFor={htmlFor} className="flex items-center gap-2">
      {label ?? 'New password'}
      <Tooltip
        content={t('messages.passwordRequirements')}
        align="start"
        tooltipTriggerProps={{
          asChild: true,
        }}
      >
        <Info className="h-3 w-3" />
      </Tooltip>
    </Label>
  );
};
