import { useCallback, useRef } from 'react';
import ReactFlow, {
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  Background,
  type EdgeTypes,
  type NodeTypes,
  type OnConnect,
  type OnConnectEnd,
  type OnConnectStart,
  type Viewport,
} from 'reactflow';
import { v4 as uuid } from 'uuid';

import { createEditableNode } from '../../utils/createNode';
import { createEdge } from '../../utils/createEdge';

import { useGraphLayout } from '../../hooks/useGraphLayout';
import { useGraphState } from '../../hooks/useGraphState';

import { BasicNode } from '../../components/BasicNode';
import { EditableNode } from '../../components/EditableNode';
import { SkeletonNode } from '../../components/SkeletonNode';
import { EdgeWithLabel } from '../../components/EdgeWithLabel';
import { SkeletonEdge } from '../../components/SkeletonEdge';
import { RootNode } from '../../components/RootNode';

import type { Edge } from '../../types/Edge';
import type { Node } from '../../types/Node';

const nodeTypes: NodeTypes = {
  rootNode: RootNode,
  basicNode: BasicNode,
  editableNode: EditableNode,
  skeletonNode: SkeletonNode,
};

const edgeTypes: EdgeTypes = {
  edgeWithLabel: EdgeWithLabel,
  skeletonEdge: SkeletonEdge,
};

export namespace ConnectionsGraph {
  export type Props = {
    spektrId?: string;
    defaultViewPort: Viewport | undefined;
    initialNodes: Node[];
    initialEdges: Edge[];
    onMoveEnd: (viewport: Viewport) => void;
  };
}

export const ConnectionsGraph = ({
  spektrId,
  defaultViewPort,
  initialEdges,
  initialNodes,
  onMoveEnd,
}: ConnectionsGraph.Props) => {
  const connectingNodeId = useRef<string | null>(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const { screenToFlowPosition } = useReactFlow();
  const { canAddNodes } = useGraphState();

  const onConnect = useCallback<OnConnect>(
    (params) => {
      if (!canAddNodes) return;

      // reset the start node on connections
      connectingNodeId.current = null;
      setEdges((eds) => addEdge(params, eds));
    },
    [canAddNodes, setEdges]
  );

  const onConnectStart = useCallback<OnConnectStart>((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback<OnConnectEnd>(
    (event) => {
      if (!canAddNodes) return;

      if (!connectingNodeId.current || !event.target) return;

      const targetIsPane = (event.target as any).classList.contains(
        'react-flow__pane'
      );

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const newEdgeId = uuid();
        const newNodeId = uuid();

        const newNode = createEditableNode(
          newNodeId,
          screenToFlowPosition({
            x: (event as any).clientX,
            y: (event as any).clientY,
          }),
          {
            spektrId: newNodeId,
            name: 'Untitled',
            status: 'pending',
            type: 'unknown',
          }
        );
        const newEdge = createEdge(
          newEdgeId,
          connectingNodeId.current,
          newNodeId,
          'Unknown',
          'owner'
        );

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) => eds.concat(newEdge));
      }
    },
    [canAddNodes, screenToFlowPosition, setEdges, setNodes]
  );

  useGraphLayout(spektrId);

  if (!defaultViewPort) {
    // don't render until we have a default viewport
    return null;
  }

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      className="border-color-border-primary rounded-md border"
      proOptions={{ account: 'paid-pro', hideAttribution: true }}
      defaultViewport={defaultViewPort}
      minZoom={0.2}
      zoomOnDoubleClick={false}
      deleteKeyCode={null}
      nodesDraggable={canAddNodes}
      nodesConnectable={canAddNodes}
      onMoveEnd={(_event, viewPort) => onMoveEnd(viewPort)}
      onConnect={onConnect}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectEnd}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
    >
      <Background className="bg-color-bg-primary" />
    </ReactFlow>
  );
};
