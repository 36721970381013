import {
  generateNodeLabel,
  generateNodeSubtext,
  predictIfIndividualOrCompany,
  MoonrakerGraph,
} from '@spektr/moonraker-graph';
import { type FormValues } from '@spektr/moonraker-types';
import {
  isClientRecordStatus,
  type OwnershipGraphNode,
} from '@spektr/shared/validators';

export function createNodeFromPayload(
  node: OwnershipGraphNode
): MoonrakerGraph.Node {
  return {
    id: node.spektrId,
    type: 'basicNode',
    data: {
      spektrId: node.spektrId,
      type: predictIfIndividualOrCompany(
        node.details as FormValues
        // TODO: (@Robert) - uncomment this when the `node.type` is different from `''`
        // (node.type as MoonrakerGraph.NodeRecordType) || 'unknown'
      ),
      name: generateNodeLabel(node.details),
      subtext: generateNodeSubtext(node.details),
      status: isClientRecordStatus(node.status) ? node.status : 'pending',
      data: node.details,
    },
    position: { x: 0, y: 0 },
  };
}
