import { z } from 'zod';

import { DatasetScanableOrigin } from '../dataset';

export const scanRequestSchema = z.object({
  datasetId: z.string().min(1),
  datasetKey: z.string().min(1),
  datasetOrigin: DatasetScanableOrigin,
  workspaceId: z.string().optional(),
});

export const createRequestSchema = z.object({
  datasetId: z.string().min(1),
  datasetKey: z.string().min(1),
  datasetOrigin: DatasetScanableOrigin,
  workspaceId: z.string().optional(),
  fieldNames: z.array(z.string()),
  fieldTypes: z.array(z.string()),
});
