import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import { ScrollArea, toast } from '@spektr/client/components';

import {
  getAllowedSpektrFieldsQuery,
  getTriggersForProcessQuery,
  getTriggersForProcessQueryKey,
} from '@spektr/client/services';

import { type ClientTrigger } from '@spektr/client/types';
import {
  ServiceNodeMonitor,
  OpenCorporatesMonitoringNode,
  UpdateOpenCorporatesMonitoringNode,
} from '@spektr/shared/validators';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { ServiceNode } from '@spektr/shared/types';

import { useServiceFields } from '../../hooks/useServiceFields';
import { TriggerFrequencies } from '../../providers/TriggerFrequencies';
import { MonitorsProvider } from '../../providers/Monitors';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { FieldsMonitoring } from '../FieldsMonitoring';
import { withDialogWrapper } from '../WithDialogWrapper';

import { useUpdateTriggers } from './hooks/useUpdateTriggers';

import { ServiceFieldsWithFrequencyList } from './containers/ServiceFieldsWithFrequencyList';
import { DialogFooter } from './containers/DialogFooter';

import { FieldMapping } from './components/FieldMapping';
import { ContentSkeleton } from './components/ContentSkeleton';

export namespace OpenCorporateMonitoringContent {
  export type Props = BaseDialogProps<OpenCorporatesMonitoringNode>;
}
const OpenCorporateMonitoringContent = ({
  isPendingUpdate,
  processId,
  node,
  onUpdate,
}: OpenCorporateMonitoringContent.Props) => {
  const { newServiceConfig } = useFeatureFlags();
  const formInstance = useForm<Record<string, string>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });
  const queryClient = useQueryClient();
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );
  const { data: triggers } = useSuspenseQuery(
    getTriggersForProcessQuery(processId)
  );

  const handleSaveTriggers = useUpdateTriggers(triggers);
  const { fields } = useServiceFields(node);

  const getUpdatedFields = (
    node: ServiceNode,
    fields: Record<string, boolean>,
    mapping: Record<string, string>,
    monitors?: ServiceNodeMonitor[]
  ) => {
    const parsedNode = OpenCorporatesMonitoringNode.parse(node);
    return UpdateOpenCorporatesMonitoringNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields,
      mapping,
      monitors,
    });
  };

  // TODO: @Alex - ST-2688 - remove after newServiceConfig feature flag is removed
  const handleSaveChanges = async (nextTriggers: ClientTrigger[]) => {
    const mapping = formInstance.getValues();
    const hasFrequencyOnAnyField = !!nextTriggers.some((trigger) =>
      fields.some((field) => trigger.fields?.includes(field.key))
    );

    const fieldsMap = fields.reduce<Record<string, boolean>>((acc, field) => {
      // When a "main" spektrField has a frequency set (e.g. has_sanctions), all it's subfields
      // (e.g. has_sanctions_fields.*) should have that frequency set as well
      const fieldStartingKey = field.key.split('_fields.').shift() as string;
      const hasFrequency = !!triggers.find((trigger) =>
        trigger.fields?.includes(fieldStartingKey)
      );

      acc[field.key] = hasFrequencyOnAnyField ? true : hasFrequency;

      return acc;
    }, {});

    await handleSaveTriggers(nextTriggers);

    await onUpdate(getUpdatedFields(node, fieldsMap, mapping));

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });

    queryClient.invalidateQueries({
      queryKey: getTriggersForProcessQueryKey(processId),
    });
  };

  const saveChanges = async (monitors: ServiceNodeMonitor[]) => {
    const mapping = formInstance.getValues();
    const fieldsMap = fields.reduce<Record<string, boolean>>((acc, field) => {
      acc[field.key] = false;

      return acc;
    }, {});

    await onUpdate(getUpdatedFields(node, fieldsMap, mapping, monitors));

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  const currentNodeTriggers = useMemo(
    () => triggers?.filter((trigger) => trigger.nodeId === node.id) ?? [],
    [node.id, triggers]
  );

  return (
    <FormProvider {...formInstance}>
      {newServiceConfig ? (
        <MonitorsProvider
          node={node}
          serviceFields={fields}
          spektrFields={spektrFields}
        >
          <ScrollArea className="max-h-[70vh] overflow-y-auto">
            <div className="flex flex-col gap-6">
              <FieldMapping spektrFields={spektrFields} />
              <FieldsMonitoring serviceName="OpenCorporates" />
            </div>
          </ScrollArea>
          <DialogFooter
            isPendingUpdate={isPendingUpdate}
            onSave={saveChanges}
          />
        </MonitorsProvider>
      ) : (
        <TriggerFrequencies
          fields={fields}
          initialTriggers={currentNodeTriggers}
          nodeId={node.id}
          processId={processId}
        >
          <ServiceFieldsWithFrequencyList
            key={spektrFields?.length}
            fields={fields}
            spektrFields={spektrFields ?? []}
          />
          <DialogFooter
            isPendingUpdate={isPendingUpdate}
            onSave={handleSaveChanges}
          />
        </TriggerFrequencies>
      )}
    </FormProvider>
  );
};

export const OpenCorporateMonitoringDialog = withDialogWrapper(
  OpenCorporateMonitoringContent,
  <ContentSkeleton />,
  {
    className: 'max-w-[700px]',
  }
);
