import { ElementRef, ReactNode, forwardRef } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { Label } from '../Label';

import { RadioGroupItem } from './RadioGroupItem';

export namespace RadioButton {
  export type Ref = ElementRef<typeof RadioGroupPrimitive.Item>;

  export type Props = RadioGroupItem.Props & {
    id: string;
    label?: ReactNode;
  };
}

export const RadioButton = forwardRef<RadioButton.Ref, RadioButton.Props>(
  ({ label, ...props }, ref) => {
    if (label) {
      return (
        <div className="flex items-center space-x-2">
          <RadioGroupItem ref={ref} {...props} />
          <Label htmlFor={props.id}>{label}</Label>
        </div>
      );
    }
    return <RadioGroupItem ref={ref} {...props} />;
  }
);
