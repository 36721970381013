import { useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { type InputField as InputFieldProp } from '@spektr/moonraker-types';

import {
  CountrySelect,
  FormField,
  Input,
  Select,
} from '@spektr/components-forms';

// TODO: Move this in a shared location
import countries from '../../../assets/files/countries.json';

import { getCountryCode, getCountryDialWithLabel } from '../../utils/countries';

export type PhoneInputFieldProps = {
  field: InputFieldProp;
  name: string;
};

export const PhoneInputField = ({ field, name }: PhoneInputFieldProps) => {
  const context = useFormContext();
  const [searchValue, setSearchValue] = useState('');

  const requiredValidation = field.validation?.find(
    (v) => v.type === 'required'
  );

  const {
    field: codeFormField,
    fieldState: { invalid: codeInvalid, error: codeError },
  } = useController({
    name: `${name}-spektrCountryCode`,
    control: context.control,
    rules: {
      required: requiredValidation?.message,
    },
  });
  const {
    field: numberFormField,
    fieldState: { invalid: numberInvalid, error: numberError },
  } = useController({
    name,
    control: context.control,
    rules: {
      required: requiredValidation?.message,
    },
  });

  const codeAutocompleteInvalid = useMemo(
    () => !codeFormField.value && !!searchValue && !searchValue.startsWith('+'),
    [codeFormField.value, searchValue]
  );

  const options = useMemo<Select.Option[]>(() => {
    return countries
      .map((country) => ({
        value: getCountryCode(country),
        label: getCountryDialWithLabel(country),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  const filteredOptions = useMemo<Select.Option[]>(() => {
    return options.filter(
      (option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
        option.value.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [options, searchValue]);

  const errorMessage = codeError?.message ?? numberError?.message;

  const handleCountryChange = (countryCode: string) => {
    codeFormField.onChange(countryCode);
  };

  return (
    <FormField
      className="w-full"
      label={field.attributes.label}
      required={!!requiredValidation}
      helperText={errorMessage ?? field.attributes.helperText}
      name={`${name}-code`}
      hasError={codeInvalid || codeAutocompleteInvalid || numberInvalid}
    >
      <div className="flex flex-row items-end gap-4">
        <div className="w-36 shrink-0">
          <CountrySelect
            autoComplete="tel-country-code"
            options={filteredOptions}
            value={codeFormField.value}
            defaultValue={codeFormField.value}
            placeholder={field.attributes.placeholder}
            onValueChange={handleCountryChange}
            onSearch={setSearchValue}
          />
        </div>
        <Input
          ref={numberFormField.ref}
          className="w-full"
          name={name}
          autoComplete="tel tel-national"
          type="text"
          hasError={numberInvalid}
          value={numberFormField.value}
          placeholder={field.attributes.placeholder}
          onChange={numberFormField.onChange}
        />
      </div>
    </FormField>
  );
};
