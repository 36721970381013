import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CasesStatus, DropdownOption } from '@spektr/client/components';
import {
  alertStatuses,
  alertTypes,
  ClientRecordFilter,
  PaginationOptions,
} from '@spektr/shared/validators';

import { AlertsListFilters } from '../types';

export const useGetAlertsDropdownFiltersOptions = (
  filters: ClientRecordFilter & PaginationOptions
) => {
  const alertsFilters = filters as AlertsListFilters;
  const { t } = useTranslation('cases');

  const separatorSubOption = useMemo<DropdownOption>(
    () => ({
      type: 'separator',
      value: '',
    }),
    []
  );

  const alertStatusesFilterOptions: DropdownOption[] = alertStatuses
    .map((status, index) => {
      const statusesOptions: DropdownOption[] = [];

      const statusOption: DropdownOption = {
        label: (
          <CasesStatus status={status} className="h-[22px] max-w-fit text-xs" />
        ),
        type: 'item',
        value: status,
        selected: alertsFilters.status?.[0]?.includes(status),
      };

      statusesOptions.push(statusOption);
      if (index !== alertStatuses.length - 1) {
        statusesOptions.push(separatorSubOption);
      }

      return statusesOptions;
    })
    .flat();

  const alertTypesFilterOptions: DropdownOption[] = alertTypes
    .map((alertType, index) => {
      const alertTypesOptions: DropdownOption[] = [];

      const alertTypeOption: DropdownOption = {
        label: t(`alertTypes.${alertType}`),
        type: 'item',
        value: alertType,
        selected: alertsFilters.alertType?.[0]?.includes(alertType),
      };

      alertTypesOptions.push(alertTypeOption);
      if (index !== alertTypes.length - 1) {
        alertTypesOptions.push(separatorSubOption);
      }

      return alertTypesOptions;
    })
    .flat();

  const dropdownOptions: { [key: string]: DropdownOption[] } = useMemo(
    () => ({
      userId: [],
      status: alertStatusesFilterOptions,
      alertType: alertTypesFilterOptions,
    }),
    [alertStatusesFilterOptions, alertTypesFilterOptions]
  );

  return useMemo(() => dropdownOptions, [dropdownOptions]);
};
