import { useController, useFormContext } from 'react-hook-form';

import { FormField, RadioButton, RadioGroup } from '@spektr/components-forms';

import type { CheckboxField as CheckboxFieldProp } from '@spektr/moonraker-types';

export type RadioFieldProps = {
  field: CheckboxFieldProp;
  name: string;
};

export const RadioField = ({ field, name }: RadioFieldProps) => {
  const { control } = useFormContext();

  const requiredValidation = field.validation?.find(
    (v) => v.type === 'required'
  );

  const {
    field: formField,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: {
      required: requiredValidation?.message,
    },
  });

  const handleChange = (option: string) => {
    formField.onChange(option);
  };

  return (
    <FormField
      className="gap-2"
      label={field.attributes.label}
      required={!!requiredValidation}
      helperText={error ? error.message : field.attributes.helperText}
      helperTextPosition="top"
      name={name}
      hasError={invalid}
    >
      <RadioGroup
        onValueChange={handleChange}
        value={formField.value}
        className="flex flex-row flex-wrap gap-4"
      >
        {field.attributes.options.map((option) => (
          <RadioButton id={option} key={option} label={option} value={option} />
        ))}
      </RadioGroup>
    </FormField>
  );
};
