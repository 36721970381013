import { cn } from '@spektr/style-utils';
import { RichTextEditor, RichTextEditorProps } from '@spektr/client/components';

import { type SlateDescendant } from '@spektr/shared/slate-types';

export type PageDescriptionProps = RichTextEditorProps & {
  description: SlateDescendant[] | string;
};

export const PageDescription = ({
  description,
  className,
  ...props
}: PageDescriptionProps) => (
  <RichTextEditor
    {...props}
    initialValue={description as SlateDescendant[]}
    className={cn('max-h-96 text-base font-normal leading-6', className)}
    isReadOnly
    resetOnUpdate
  />
);
