import { useTranslation } from 'react-i18next';

import { Badge } from '@spektr/client/components';

import { HitDecisionStatus } from '@spektr/shared/validators';

export type HitStatusProps = {
  className?: string;
  hitType: 'aml' | 'kyb';
  decision: HitDecisionStatus;
};

export const HitStatus = ({
  className,
  hitType = 'kyb',
  decision,
}: HitStatusProps) => {
  const { t } = useTranslation('cases');

  const label = t(`alerts.hit.decision.${hitType}.${decision}`);
  let variant: Badge.Props['variant'] = 'default';

  if (decision === 'false-positive') {
    variant = hitType === 'aml' ? 'cyan' : 'red';
  } else if (decision === 'true-positive') {
    variant = hitType === 'aml' ? 'red' : 'cyan';
  }

  return (
    <Badge className={className} variant={variant} data-color={variant}>
      {label}
    </Badge>
  );
};
