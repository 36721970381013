import { z } from 'zod';

export const vendorBaseSchema = z.object({
  service: z.string(),
  field: z.string(),
});

export const bodaccSimpleHitDataSchema = z.object({
  dateparution: z.string(),
  familleavis_lib: z.string(),
  commercant: z.string(),
  listepersonnes: z.object({
    typePersonne: z.string().optional(),
  }),
  modificationsgenerales: z.string().optional(),
});

export const bodaccPageHitDataSchema = z.object({
  departement_nom_officiel: z.string().optional(),
  region_nom_officiel: z.string().optional(),
  tribunal: z.string().optional(),
  ville: z.string().optional(),
  cp: z.string().optional(),
  listepersonnes: z.object({
    nom: z.string().optional(),
    prenom: z.string().optional(),
    administration: z.string().optional(),
    sigle: z.string().optional(),
    denomination: z.string().optional(),
    formeJuridique: z.string().optional(),
    capital: z.string().optional(),
    numeroImmatriculation: z.string().optional(),
    activite: z.string().optional(),
    enseigne: z.string().optional(),
    adresseSiegeSocial: z.string().optional(),
  }),
  listeetablissements: z.array(
    z.object({
      adresse: z.string().optional(),
      activite: z.string().optional(),
    })
  ),
  acte: z.object({
    dateCommencementActivite: z.string().optional(),
    descriptif: z.string().optional(),
    dateImmatriculation: z.string().optional(),
    categorieCreation: z.string().optional(),
  }),
  listeprecedentproprietaire: z.array(
    z.object({
      nom: z.string().optional(),
      typePersonne: z.string().optional(),
      prenom: z.string().optional(),
    })
  ),
  radiationaurcs: z.object({
    dateCessation: z.string().optional(),
    rediationPM: z.string().optional(),
  }),
  jugement: z.object({
    date: z.string().optional(),
    type: z.string().optional(),
    complementJugement: z.string().optional(),
    famille: z.string().optional(),
    nature: z.string().optional(),
  }),
});

export const caSimpleHitDataSchema = z.object({
  last_updated_utc: z.string(),
  entity_type: z.string(),
  name: z.string(),
  types: z.array(z.string()),
});

const commonFieldsSchema = z.object({
  name: z.string(),
  source: z.string(),
});

const associatesSchema = z.object({
  name: z.string(),
  association: z.string(),
});

const sourceNotesSchema = z.record(
  z.object({
    name: z.string(),
    url: z.string(),
    aml_types: z.array(z.string()),
    country_codes: z.array(z.string()),
    listing_started_utc: z.string().optional(),
  })
);

export const caPageHitDataDtoSchema = z.object({
  sanction: z
    .object({
      aka: z.array(z.string()),
      fields: z.array(commonFieldsSchema),
      associates: z.array(associatesSchema),
      source_notes: sourceNotesSchema,
      match_types: z.any(),
    })
    .optional(),
  pep: z
    .object({
      fields: z.array(commonFieldsSchema),
      associates: z.array(associatesSchema),
      source_notes: sourceNotesSchema,
      match_types: z.any(),
    })
    .optional(),
  adverse_media: z
    .object({
      fields: z.array(commonFieldsSchema),
      associates: z.array(associatesSchema),
      source_notes: sourceNotesSchema,
      match_types: z.any(),
    })
    .optional(),
  warning: z
    .object({
      fields: z.array(commonFieldsSchema),
      sources: z.array(z.string()),
      source_notes: sourceNotesSchema,
      match_types: z.any(),
    })
    .optional(),
});

export const ocSimpleHitDataSchema = z.object({
  name: z.string(),
  company_number: z.string(),
  jurisdiction_code: z.string(),
  current_status: z.string(),
});

export const ocPageHitDataSchema = z.object({
  incorporation_date: z.string(),
  dissolution_date: z.string(),
  company_type: z.string(),
  updated_at: z.string(),
  sources: z.array(z.string()),
  alternative_names: z.array(z.string()),
  previous_names: z.array(z.string()),
  native_company_number: z.string(),
  industry_codes: z.array(
    z.object({
      industry_code: z.string(),
      source: z.string(),
    })
  ),
  identifiers: z.object({}),
  registered_address: z.any(),
});

export const valuesChangedHitDataSchema = z.object({
  field: z.string(),
  fieldType: z.any(),
  oldValue: z.any().optional(),
  newValue: z.any().optional(),
  isDatasetComparison: z.boolean().optional(),
});

export type VendorBaseDto = z.infer<typeof vendorBaseSchema>;
export type BodaccSimpleHitDataDto = z.infer<typeof bodaccSimpleHitDataSchema>;
export type BodaccPageHitDataDto = z.infer<typeof bodaccPageHitDataSchema>;
export type CaSimpleHitDataDto = z.infer<typeof caSimpleHitDataSchema>;
export type CaPageHitDataDto = z.infer<typeof caPageHitDataDtoSchema>;
export type OcSimpleHitDataDto = z.infer<typeof ocSimpleHitDataSchema>;
export type OcPageHitDataDto = z.infer<typeof ocPageHitDataSchema>;
export type ValuesChangedHitDataDto = z.infer<
  typeof valuesChangedHitDataSchema
>;
