import { FilePlus2, GoalIcon, SearchIcon, SmilePlus } from 'lucide-react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { processBuilderNewUrl } from '@spektr/shared/utils';

import {
  CalculatorIcon,
  IconWithBackground,
  Tooltip,
} from '@spektr/client/components';
import { ProcessType } from '@spektr/shared/validators';

import { cardVariants, iconBackgroundVariants } from './variants';

type ProcessTypeWithoutLoop = Exclude<ProcessType, 'loop'>;

const variantColors: Record<ProcessTypeWithoutLoop, string> = {
  risk: 'text-color-yellow',
  score: 'text-color-red',
  monitoring: 'text-color-green',
  onboarding: 'text-color-blue',
  enrichment: 'text-color-cyan',
};

const iconVariant: Record<ProcessTypeWithoutLoop, ReactNode> = {
  risk: <CalculatorIcon className="stroke-color-yellow h-7 w-7 stroke-1" />,
  score: <GoalIcon className="stroke-color-red h-7 w-7 stroke-1" />,
  monitoring: (
    <SearchIcon className="stroke-color-green h-7 w-7 scale-x-[-1] stroke-1" />
  ),
  onboarding: <SmilePlus className="stroke-color-blue h-7 w-7 stroke-1" />,
  enrichment: <FilePlus2 className="stroke-color-cyan h-7 w-7 stroke-1" />,
};

export type ProcessCardProps = {
  variant: ProcessTypeWithoutLoop;
  disabled?: boolean;
};

const ProcessCardContent = ({ variant, disabled }: ProcessCardProps) => {
  const { t } = useTranslation('process');
  return (
    <div className={cardVariants({ variant, disabled })}>
      <IconWithBackground className={iconBackgroundVariants({ variant })}>
        {iconVariant[variant]}
      </IconWithBackground>
      <div className="flex max-w-80 flex-grow flex-col lg:max-w-64">
        {disabled && (
          <div className="bg-color-bg-card--disabled/80 text-md absolute inset-0 flex items-center justify-center rounded-md">
            {t(`common:messages.comingSoon`)}
          </div>
        )}
        <p className="text-color-text-primary text-base">
          {t(`process:chooseNewProcess.${variant}.titleComponent`, {
            defaultValue: 'Create a',
          })}{' '}
          <span className={variantColors[variant]}>
            {t(`process:chooseNewProcess.${variant}.title`, {
              defaultValue: `${variant.charAt(0).toUpperCase() + variant.slice(1)} Process`,
            })}
          </span>
        </p>
        <p className="text-color-text-subtext w-full truncate text-sm">
          {t(`process:chooseNewProcess.${variant}.description`, {
            defaultValue: '',
          })}
        </p>
      </div>
    </div>
  );
};

export const ProcessCard = ({
  variant,
  disabled = false,
}: ProcessCardProps) => {
  const { t } = useTranslation('process');
  return (
    <Tooltip
      content={t(`process:chooseNewProcess.${variant}.description`)}
      tooltipTriggerProps={{
        asChild: true,
      }}
    >
      {disabled ? (
        <ProcessCardContent variant={variant} disabled={disabled} />
      ) : (
        <Link
          to={{
            pathname: processBuilderNewUrl(),
            search: `?processType=${variant}`,
          }}
          state={{
            processType: variant,
          }}
          data-cy={`create-${variant}-process`}
        >
          <ProcessCardContent variant={variant} disabled={disabled} />
        </Link>
      )}
    </Tooltip>
  );
};
