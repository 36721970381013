import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Sparkles } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { Checkbox, Spinner, Tooltip } from '@spektr/client/components';

import {
  HitDecision,
  VendorBaseDto,
  HitResolvedDecision,
  AnyHitData,
  SpektrAiDetails,
} from '@spektr/shared/validators';

import { computeHitCardDetails } from '../../utils';

import { useHitResolutionReason } from '../../hooks/useHitResolutionReason';

import { useAlertDetails, useHitsProviderContext } from '../../providers';

import { HitStatus } from '../HitStatus';
import { HitResolutionButtons } from '../HitResolutionButtons';
import { BasicDetailsTable } from '../BasicDetailsTable';
import { HitResolutionComment } from '../HitResolutionComment';
import { MessageCount } from '../MessageCount';

export type HitCardProps = {
  className?: string;
  data: AnyHitData;
  commentCount?: number;
  decision: HitDecision | HitResolvedDecision;
  vendorDetails: VendorBaseDto;
  id: string;
  alertId: string;
  hitType: 'aml' | 'kyb';
  isCheckboxVisible?: boolean;
  isChecked?: boolean;
  isDisabled?: boolean;
  spektrAI?: SpektrAiDetails;
  onFalsePositiveClick: (reason: string) => (hitId: string) => void;
  onTruePositiveClick: (reason: string) => (hitId: string) => void;
  onClickHit: (hitId: string) => void;
  onCheckedChange?: (hitId: string, isChecked: boolean) => void;
};

export const HitCard = ({
  id,
  className,
  data,
  commentCount = 0,
  decision,
  spektrAI,
  hitType = 'kyb',
  isChecked = false,
  isDisabled = false,
  vendorDetails,
  onClickHit,
  onFalsePositiveClick,
  onTruePositiveClick,
  onCheckedChange,
}: HitCardProps) => {
  const { t } = useTranslation();
  const { users } = useHitsProviderContext();
  const { reason, updateReason, user, resolvedAt } = useHitResolutionReason(
    decision,
    users
  );
  const { customerName } = useAlertDetails();
  const hitName = data.data?.valuesChangedHit
    ? t(`fields:${data.data.valuesChangedHit.field}`)
    : customerName;

  const renderColumn = (key: string) => {
    return t(`fields:${key}`);
  };

  const renderValue = (_: string, value: unknown) => {
    if (Array.isArray(value)) {
      return value.join(', ');
    }

    if (typeof value === 'string') {
      return <span>{value}</span>;
    }

    return JSON.stringify(value);
  };

  const cleanData = useMemo(
    () => computeHitCardDetails(vendorDetails, data),
    [data, vendorDetails]
  );

  return (
    <div
      className={cn(
        'flex flex-col',
        'px-6',
        'rounded-md border',
        'bg-color-bg-dialog-content dark:bg-color-bg-dialog-content/20',
        'hover:bg-color-bg-dialog-content/40',
        'transition-colors',
        className
      )}
    >
      <div className={cn('flex flex-row', 'py-6')}>
        <button
          className="cursor-pointer text-base font-semibold"
          onClick={() => onClickHit(id)}
        >
          {hitName}
        </button>
        <div className={cn('flex flex-row items-center gap-4', 'ml-auto')}>
          {spektrAI?.status === 'failed' && (
            <Tooltip content="spektr-ai failed to solve the hit">
              <Sparkles className="stroke-color-red h-4 w-4 p-0.5" />
            </Tooltip>
          )}
          {spektrAI?.status === 'pending' && decision.status === 'pending' ? (
            <Tooltip content="spektr-ai is resolving...">
              <Spinner className="opacity-30" size="md" />
            </Tooltip>
          ) : (
            <HitStatus hitType={hitType} decision={decision.status} />
          )}
          <Checkbox
            className="bg-transparent"
            disabled={isDisabled}
            checked={isChecked}
            onCheckedChange={(checked) => {
              if (typeof checked === 'boolean') {
                onCheckedChange?.(id, checked);
              }
            }}
          />
        </div>
      </div>
      <div onClick={() => onClickHit(id)} className="cursor-pointer">
        {data && (
          <BasicDetailsTable
            data={cleanData}
            columnClassName="w-1/3"
            renderColumn={renderColumn}
            renderValue={renderValue}
          />
        )}
      </div>
      <div className={cn('flex flex-col gap-4', 'mt-auto pb-5 pt-4')}>
        <div className="flex w-full gap-4">
          <MessageCount hitId={id} count={commentCount} />
          <HitResolutionComment
            resolvedAt={resolvedAt}
            value={reason}
            user={user}
            onUpdateComment={updateReason}
          />
        </div>
        <HitResolutionButtons
          id={id}
          spektrAiDetails={spektrAI}
          hitType={hitType}
          decision={decision}
          isDisabled={isDisabled}
          onFalsePositiveClick={onFalsePositiveClick(reason)}
          onTruePositiveClick={onTruePositiveClick(reason)}
        />
      </div>
    </div>
  );
};
