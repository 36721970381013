import { Suspense } from 'react';

import { handleAxiosError } from '@spektr/client/utils';
import { Banner, SpektrErrorBoundary } from '@spektr/client/components';

import { GraphSkeleton } from './components/GraphSkeleton';
import { RelationsGraph } from './containers/RelationsGraph';

export const CustomerConnectionsGraph = () => {
  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => (
        <Banner
          type="error"
          iconName="TriangleAlert"
          message={handleAxiosError(
            error,
            'An error has occurred while retrieving the connections!'
          )}
        />
      )}
    >
      <Suspense fallback={<GraphSkeleton />}>
        <RelationsGraph />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
