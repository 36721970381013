import { useMemo } from 'react';

import { NodeType, ProcessType } from '@spektr/shared/validators';

import {
  ComplyAdvantageIcon,
  KyckrIcon,
  MitIdIcon,
  OpenCorporatesIcon,
  SlackIcon,
  VeriffIcon,
  VeriffIdvIcon,
  VirkIcon,
  IconWithBackground,
} from '@spektr/client/components';
import { FileSearch } from 'lucide-react';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { DrawerItemType } from '../types/DrawerItemType';
import { getAvailableItemsForProcess } from '../utils/getAvailableItemsForProcess';

export const useServices = (processType?: ProcessType): DrawerItemType[] => {
  const { virkCvr, veriffIdv, midship } = useFeatureFlags();

  return useMemo(() => {
    const services: DrawerItemType[] = [
      {
        type: NodeType.enum.complyAdvantageKyb,
        title: 'ComplyAdvantage',
        variant: 'complyAdvantage',
        label: 'Corporate',
        icon: <ComplyAdvantageIcon className="m-6 w-full" />,
        description:
          'Check for AML hits, sanctions and adverse media on your corporate customers.',
      },
      {
        type: NodeType.enum.complyAdvantageKyc,
        title: 'ComplyAdvantage',
        variant: 'complyAdvantage',
        label: 'Individual',
        icon: <ComplyAdvantageIcon className="m-6 w-full" />,
        description:
          'Check for AML hits, sanctions, adverse media and PEP on your individual customers.',
      },
      {
        type: NodeType.enum.openCorporatesRisk,
        title: 'OpenCorporates',
        icon: <OpenCorporatesIcon className="m-6 w-full" />,
        variant: 'white',
        label: undefined,
        description:
          'Verify a company, and get access numerous datapoints to build logic upon.',
      },
      {
        type: NodeType.enum.openCorporatesMonitoring,
        title: 'OpenCorporates',
        icon: <OpenCorporatesIcon className="m-6 w-full" />,
        variant: 'white',
        label: undefined,
        description:
          'Verify a company, and get access numerous datapoints to build logic upon.',
      },
      // {
      //   type: NodeType.enum.bodacc,
      //   title: 'BODACC',
      //   icon: <BodaccIcon />,
      //   variant: 'bodacc',
      //   label: undefined,
      //   description:
      //     'Check for changes in the legal status of a company, such as bankruptcy or liquidation.',
      // },
      {
        type: NodeType.enum.kyckr,
        title: 'Kyckr',
        icon: <KyckrIcon className="m-6 w-full" />,
        variant: 'kyckr',
        label: undefined,
        description:
          'Discover Ultimate Beneficial Owners of your corporate customers.',
      },
      {
        type: NodeType.enum.slack,
        icon: <SlackIcon className="m-6 w-full" />,
        title: 'Slack',
        variant: 'white',
        description:
          'Create slack push node that will forward results of the process execution',
      },
      {
        type: NodeType.enum.veriff,
        title: 'Veriff',
        icon: <VeriffIcon className="m-6 w-full" />,
        variant: 'veriff',
        label: undefined,
        description:
          'Perform identity verification and fraud prevention checks on individuals with Veriff.',
      },
      {
        type: NodeType.enum.veriffIdv,
        title: 'Veriff Upload',
        icon: <VeriffIdvIcon className="m-6 w-full" />,
        variant: 'veriffIdv',
        label: undefined,
        description:
          "Upload identity documents for Veriff to verify. This step doesn't require a liveness check.",
        hidden: !veriffIdv,
      },
      {
        type: NodeType.enum.mitId,
        title: 'MitId',
        icon: <MitIdIcon className="m-6 w-full" />,
        variant: 'mitId',
        label: undefined,
        description: "Verify individuals' identity from Denmark with MitID.",
      },
      {
        type: NodeType.enum.virk,
        title: 'Virk',
        icon: <VirkIcon className="m-6 w-full" />,
        variant: 'virk',
        label: undefined,
        description: 'Verify companies from Denmark with Virk.',
        hidden: !virkCvr,
      },
      {
        type: NodeType.enum.documentOCR,
        icon: (
          <IconWithBackground className="bg-color-cyan/10 border-color-cyan">
            <FileSearch className="stroke-color-cyan stroke-1.8 h-4 w-4" />
          </IconWithBackground>
        ),
        title: 'Document OCR',
        variant: 'cyan' as const,
        description:
          'Add Document OCR to your process to get data from uploaded documents',
        hidden: !midship,
      },
    ];

    return getAvailableItemsForProcess(services, processType);
  }, [virkCvr, processType, veriffIdv]);
};
