import { useMemo } from 'react';
import { ChevronRight, Trash } from 'lucide-react';
import { useFormContext } from 'react-hook-form';

import { isWritableField, type EntitiesField } from '@spektr/moonraker-types';

import { cn } from '@spektr/style-utils';

import { FieldFactory } from '../../../../containers/FieldFactory';

export type EntityItemProps<T> = {
  entityField: EntitiesField;
  field: T;
  isCollapsed?: boolean;
  name: string;
  onClickDelete: (fieldId: string) => void;
  onClickToggle: (fieldId: string) => void;
};

export const EntityItem = <T extends Record<string, any>>({
  entityField,
  field,
  isCollapsed = false,
  name,
  onClickDelete,
  onClickToggle,
}: EntityItemProps<T>) => {
  const { watch } = useFormContext();
  const formValues = watch();

  const orderedFields = useMemo(() => {
    return entityField.form.order.map((fieldId) => {
      return entityField.form.fields[fieldId];
    });
  }, [entityField.form]);

  const displayedKey = entityField.attributes.labelField ?? 'id';

  const entityFields = useMemo(
    () =>
      formValues.entity.find(
        (entity: any) => entity.temporaryEntityId === field.temporaryEntityId
      ),
    [formValues.entity, field.temporaryEntityId]
  );

  const label = entityFields?.[displayedKey] || 'Untitled entity';

  return (
    <div className="flex w-full flex-col">
      <button
        type="button"
        className={cn(
          'flex flex-row items-center gap-2',
          'p-2',
          'rounded-md',
          'bg-white'
        )}
        onClick={() => onClickToggle(field.id)}
      >
        <span className="truncate text-xs font-medium">{label}</span>
        <div className={cn('flex flex-row gap-2', 'ml-auto', 'text-black')}>
          {isCollapsed && (
            <button
              className="hover:text-red-500"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onClickDelete(field.id);
              }}
            >
              <Trash className="h-3 w-3" />
            </button>
          )}
          <ChevronRight
            className={cn('h-4 w-4 transition-transform', {
              'rotate-90': isCollapsed,
            })}
          />
        </div>
      </button>
      <div
        className={cn('h-auto px-0.5', 'overflow-hidden', {
          'h-0': !isCollapsed,
        })}
      >
        {orderedFields.map((innerField) => (
          <FieldFactory
            key={innerField.id}
            field={innerField}
            name={
              isWritableField(innerField)
                ? `${name}.${innerField.config.spektrDataField}`
                : undefined
            }
          />
        ))}
      </div>
    </div>
  );
};
