import { useTranslation } from 'react-i18next';

import { ScrollArea, toast } from '@spektr/client/components';

import { KyckrNode, UpdateKyckrNode } from '@spektr/shared/validators';
import { type NodeUpdateInput, type SpektrField } from '@spektr/shared/types';

import { useEnrichedServiceFields } from '../../../../providers/EnrichedServiceFields';

import { CustomerProfileEnrichmentFields } from '../../../CustomerProfileEnrichmentFields';

import { FieldsMapping } from '../../components/FieldsMapping';
import { DialogFooterActions } from '../../../../components/DialogFooterActions';

type FieldsEnrichmentContentProps = {
  node: KyckrNode;
  spektrFields: SpektrField[];
  isValidForm: boolean;
  isPendingUpdate?: boolean;
  getMapping: () => Record<string, string>;
  onUpdate: (data: NodeUpdateInput) => Promise<unknown>;
};

export const FieldsEnrichmentContent = ({
  node,
  spektrFields,
  getMapping,
  isValidForm,
  isPendingUpdate,
  onUpdate,
}: FieldsEnrichmentContentProps) => {
  const { getServiceFields } = useEnrichedServiceFields();
  const { t } = useTranslation('process');

  const handleSave = async () => {
    const mapping = getMapping();
    const fields = getServiceFields();

    const parsedNode = KyckrNode.parse(node);

    const updatedNode = UpdateKyckrNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields,
      mapping,
    });

    await onUpdate(updatedNode);

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  return (
    <>
      <ScrollArea className="max-h-[70vh] overflow-y-auto">
        <div className="flex flex-col gap-6">
          <FieldsMapping spektrFields={spektrFields} />
          <CustomerProfileEnrichmentFields
            title={t('customerProfileEnrichment.sectionTitle')}
          />
        </div>
      </ScrollArea>
      <DialogFooterActions
        btnColor="kyckr"
        disabled={!isValidForm}
        isPendingUpdate={isPendingUpdate}
        onSave={handleSave}
      />
    </>
  );
};
