import { useTranslation } from 'react-i18next';

import { cn } from '@spektr/style-utils';

import { type AlertField } from '@spektr/client/types';
import { NodeIcon } from '@spektr/client/components';

import { type AdverseMediaAuthorityLevels } from '@spektr/shared/validators';

import { AuthorityDropdown } from '../AuthorityDropdown';

export type FieldItemProps = {
  isPendingUpdate?: boolean;
  field: AlertField;
  value?: AdverseMediaAuthorityLevels;
  onAuthorityChange: (
    field: AlertField,
    authority: AdverseMediaAuthorityLevels
  ) => void;
};

export const FieldItem = ({
  isPendingUpdate,
  field,
  value,
  onAuthorityChange,
}: FieldItemProps) => {
  const { t } = useTranslation();

  const translationKey = `fields:${field.nodeType}.${field.name}`;

  return (
    <li className={cn('flex flex-row items-center', 'w-full py-2')}>
      <div className={cn('flex grow flex-row items-center gap-2.5')}>
        {field.nodeType && <NodeIcon nodeType={field.nodeType} />}
        <span className="text-sm font-medium">{t(translationKey)}</span>
      </div>
      <div className="ml-auto">
        <AuthorityDropdown
          disabled={isPendingUpdate}
          placeholder="Select spektrAI authority"
          value={value}
          onChange={(value) => onAuthorityChange(field, value)}
        />
      </div>
    </li>
  );
};
