import { useFormContext } from 'react-hook-form';

import { SpektrField } from '@spektr/shared/types';

import {
  TypeField,
  InternalEmailField,
  MessageBodyField,
  RecipientField,
} from '../LoopChannelSettingsFields';

type LoopChannelSettingsProps = {
  spektrFields: SpektrField[];
};

export const LoopChannelSettings = ({
  spektrFields,
}: LoopChannelSettingsProps) => {
  const { watch } = useFormContext();

  const channelType = watch('type');

  const renderFields = () => {
    switch (channelType) {
      case 'email':
        return (
          <>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-2">
                <TypeField />
              </div>
              <div className="flex flex-col gap-2">
                <RecipientField spektrFields={spektrFields} />
              </div>
            </div>
            <MessageBodyField spektrFields={spektrFields} />
          </>
        );
      case 'internalEmail':
        return (
          <>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-2">
                <TypeField />
              </div>
              <div className="flex flex-col gap-2">
                <InternalEmailField />
              </div>
            </div>
            <MessageBodyField spektrFields={spektrFields} />
          </>
        );
      default:
        return null;
    }
  };

  return <form className="flex flex-col gap-4">{renderFields()}</form>;
};
