// TODO: @Alex - ST-2688 - to be removed after newServiceConfig feature flag is removed

import { toast } from '@spektr/client/components';

import { KyckrNode, UpdateKyckrNode } from '@spektr/shared/validators';
import { NodeUpdateInput, SpektrField } from '@spektr/shared/types';

import { useServiceFields } from '../../../../hooks/useServiceFields';
import { mapSelectedFields } from '../../../../utils/mapSelectedFields';
import { useKyckrSelectedFields } from '../../hooks/useKyckrSelectedFields';
import { getSelectedServiceFields } from '../../utils/getSelectedServiceFields';

import { DialogFooterActions } from '../../../../components/DialogFooterActions';
import { FieldsMapping } from '../../components/FieldsMapping';

import { PersonOfInterestForm } from '../PersonOfInterestForm';

type LegacyContentProps = {
  node: KyckrNode;
  spektrFields: SpektrField[];
  isValidForm: boolean;
  isPendingUpdate?: boolean;
  getMapping: () => Record<string, string>;
  onUpdate: (data: NodeUpdateInput) => Promise<unknown>;
};

export const LegacyContent = ({
  node,
  spektrFields,
  isValidForm,
  isPendingUpdate,
  getMapping,
  onUpdate,
}: LegacyContentProps) => {
  const { fields, serviceFields } = useServiceFields(node);
  const { selectedFields, handleFieldChange } =
    useKyckrSelectedFields(serviceFields);

  const handleSave = async () => {
    const mapping = getMapping();
    const selectedServiceFields = getSelectedServiceFields(
      fields,
      selectedFields
    );
    const fieldsMap = mapSelectedFields(fields, selectedServiceFields);

    const parsedNode = KyckrNode.parse(node);

    const updatedNode = UpdateKyckrNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields: fieldsMap,
      mapping,
    });

    await onUpdate(updatedNode);

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  return (
    <>
      <div className="flex flex-col gap-6">
        <FieldsMapping spektrFields={spektrFields} />
        <PersonOfInterestForm
          key={selectedFields.join(',')}
          selectedFields={selectedFields}
          onFieldChange={handleFieldChange}
        />
      </div>

      <DialogFooterActions
        btnColor="kyckr"
        disabled={!isValidForm}
        isPendingUpdate={isPendingUpdate}
        onSave={handleSave}
      />
    </>
  );
};
