import { ReactFlowProvider } from 'reactflow';

import { MoonrakerGraph } from '@spektr/moonraker-graph';

const skeletonInitialNodes = [
  {
    id: '1',
    type: 'skeletonNode',
    data: {},
    position: { x: 0, y: 0 },
  },
  {
    id: '2',
    type: 'skeletonNode',
    data: {},
    position: { x: 0, y: 0 },
  },
  {
    id: '3',
    type: 'skeletonNode',
    data: {},
    position: { x: 0, y: 0 },
  },
  {
    id: '4',
    type: 'skeletonNode',
    data: {},
    position: { x: 0, y: 0 },
  },
] as MoonrakerGraph.Node[];
const skeletonInitialEdges = [
  {
    id: '1-2',
    source: '1',
    target: '2',
    type: 'skeletonEdge',
    data: {
      label: '',
    },
    animated: false,
  },
  {
    id: '2-3',
    source: '2',
    target: '3',
    type: 'skeletonEdge',
    data: {
      label: '',
    },
    animated: false,
  },
  {
    id: '2-4',
    source: '2',
    target: '4',
    type: 'skeletonEdge',
    data: {
      label: '',
    },
    animated: false,
  },
] as MoonrakerGraph.Edge[];

export const GraphSkeleton = () => (
  <ReactFlowProvider>
    <MoonrakerGraph
      className="h-[695px] w-full"
      edges={skeletonInitialEdges}
      nodes={skeletonInitialNodes}
    />
  </ReactFlowProvider>
);
