import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { FormField } from '@spektr/components-forms';

import { DatePicker } from '../DatePicker';

import type { DateField as DateFieldProp } from '@spektr/moonraker-types';

export type DateFieldProps = {
  field: DateFieldProp;
  name: string;
};

export const DateField = ({ field, name }: DateFieldProps) => {
  const context = useFormContext();

  const requiredValidation = field.validation?.find(
    (v) => v.type === 'required'
  );

  const {
    field: formField,
    fieldState: { invalid, error },
  } = useController({
    name,
    control: context.control,
    rules: {
      required: requiredValidation?.message,
    },
  });

  const dateDisabled = useMemo(() => {
    const disabled = [];
    if (field.attributes.minDate) {
      disabled.push({ before: new Date(Number(field.attributes.minDate)) });
    }
    if (field.attributes.maxDate) {
      disabled.push({ after: new Date(Number(field.attributes.maxDate)) });
    }

    return disabled;
  }, [field.attributes.minDate, field.attributes.maxDate]);

  return (
    <FormField
      label={field.attributes.label}
      required={!!requiredValidation}
      helperText={error ? error.message : field.attributes.helperText}
      name={name}
      hasError={invalid}
    >
      <DatePicker
        ref={formField.ref}
        name={name}
        value={formField.value}
        dateFormat={field.attributes.format}
        disabled={dateDisabled}
        onChange={formField.onChange}
      />
    </FormField>
  );
};
