import type { FormValues } from '@spektr/moonraker-types';

import type { NodeRecordType } from '../types/NodeData';

const INDIVIDUAL_FIELDS = ['first_name', 'last_name', 'full_name'];
const COMPANY_FIELDS = ['company_name'];

export function predictIfIndividualOrCompany(
  values: FormValues,
  currentType: NodeRecordType = 'unknown'
): NodeRecordType {
  const hasIndividualFields = INDIVIDUAL_FIELDS.some((field) => values[field]);
  const hasCompanyFields = COMPANY_FIELDS.some((field) => values[field]);

  if (hasIndividualFields && hasCompanyFields) {
    return 'unknown';
  }

  if (hasIndividualFields) {
    return 'individual';
  }

  if (hasCompanyFields) {
    return 'company';
  }

  return currentType;
}
