import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

import { ArrowLeft } from 'lucide-react';

import { Button } from '@spektr/client/components';

import { useFormRouter } from '../../hooks/useFormRouter';
import { formRoutes } from '../../types';

export type FormWidgetSidebarProps = {
  children: ReactNode | ReactNode[];
  className?: string;
  title: string;
};

export const FormWidgetSidebar = ({
  children,
  className,
  title,
}: FormWidgetSidebarProps) => {
  const { currentRoute, navigateTo } = useFormRouter();

  return (
    <div
      className={cn(
        'flex shrink-0 flex-col',
        'h-full w-full',
        'bg-color-bg-moonraker',
        'border-r py-3',
        className
      )}
    >
      {currentRoute.includes(formRoutes.Values.fields) &&
      currentRoute !== formRoutes.Values.fields ? (
        <Button
          color="secondary"
          className="mb-3 ml-3 w-fit"
          startIcon={<ArrowLeft className="h-[18px] w-[18px]" />}
          onClick={() => navigateTo(formRoutes.Values.fields)}
        >
          Go back
        </Button>
      ) : (
        <p className="text-color-text-primary mb-3 px-3 text-base font-medium">
          {title}
        </p>
      )}

      {children}
    </div>
  );
};
