import { format, parse } from 'date-fns';

export const isValidDateFormat = (formatString: string): boolean => {
  const originalConsole = console;

  // Restore console to original state
  const restoreConsole = () => {
    // eslint-disable-next-line no-global-assign
    console = originalConsole;
  };

  // Suppress warnings from date-fns since we're already catching the errors
  // eslint-disable-next-line no-global-assign
  console = new Proxy(console, {
    get: (target, property: keyof Console) => {
      if (property === 'warn') {
        return (message: string) => {
          if (!message.includes('date-fns')) {
            originalConsole.warn(message);
          }
        };
      }
      return target[property];
    },
  });

  try {
    const date = new Date();
    const formattedDate = format(date, formatString);
    const parsedDate = parse(formattedDate, formatString, new Date());
    restoreConsole();

    return !isNaN(parsedDate.getTime());
  } catch (error) {
    restoreConsole();
    return false;
  }
};

export const parseDate = (
  value: string | number | undefined
): Date | undefined => {
  if (!value) return undefined;

  let date: Date;
  if (isNaN(Number(value))) {
    date = new Date(value);
  } else {
    date = new Date(Number(value));
  }

  if (isNaN(date.getTime())) {
    return undefined;
  }

  return date;
};
