import { cn } from '@spektr/style-utils';

export const slackContained = cn(
  'bg-color-slack',
  'text-color-text-button-contained-slack',
  'hover:bg-color-slack/80',
  'active:bg-color-slack/90'
);
export const slackOutlined = cn(
  slackContained,
  'border-color-slack',
  'text-color-text-tertiary',
  'hover:bg-color-slack/10',
  'active:bg-color-slack/20',
  'disabled:border-color-slack/50'
);
export const slackText = cn('text-color-text-tertiary');
