import { ElementRef, ReactNode, forwardRef } from 'react';
import {
  SelectProps as SelectRootProps,
  Root,
  Value,
} from '@radix-ui/react-select';

import { cn } from '@spektr/style-utils';

import { SelectTrigger, SelectContent, SelectItem } from './components';

export namespace Select {
  export type Ref = ElementRef<typeof Root>;

  export type Option = {
    value: string;
    label: string;
    disabled?: boolean;
  };

  export type Props = SelectRootProps & {
    className?: string;
    triggerClassName?: string;
    defaultValue?: string;
    emptyLabel?: string;
    placeholder?: string;
    options: Option[];
    icon?: ReactNode;
    renderLabel?: (option: Option) => ReactNode;
  };
}

export const Select = forwardRef<Select.Ref, Select.Props>(
  (
    {
      triggerClassName,
      defaultValue,
      disabled = false,
      emptyLabel = 'No options',
      name,
      options,
      placeholder,
      icon,
      renderLabel,
      ...props
    },
    ref
  ) => {
    return (
      <Root {...props} disabled={disabled} defaultValue={defaultValue}>
        <SelectTrigger
          name={name}
          icon={icon}
          className={cn(
            {
              'text-zinc-400': !props.value,
            },
            triggerClassName
          )}
          ref={ref}
        >
          <Value placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options.length > 0 ? (
            options.map((option) => (
              <SelectItem
                key={`${option.label}-${option.value}`}
                value={option.value}
                disabled={option?.disabled}
              >
                {renderLabel ? renderLabel(option) : option.label}
              </SelectItem>
            ))
          ) : (
            <SelectItem disabled value="empty">
              {emptyLabel}
            </SelectItem>
          )}
        </SelectContent>
      </Root>
    );
  }
);
