import { VariantProps, cva } from 'class-variance-authority';
import { FileText, X } from 'lucide-react';
import { ChangeEventHandler, FocusEventHandler } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

import { cn } from '@spektr/style-utils';

export namespace FileInput {
  export type Props = {
    currentFile?: File;
    description?: string;
    error?: boolean;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onDrop: (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => void;
    onClear: () => void;
  };
}

export const FileInput = ({
  currentFile,
  description,
  error,
  onBlur,
  onChange,
  onDrop,
  onClear,
}: FileInput.Props) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    maxFiles: 1,
    onDrop,
  });

  if (currentFile) {
    return (
      <div
        className={cn(
          'flex flex-row items-center gap-2',
          'p-2',
          'rounded-md border border-zinc-500'
        )}
      >
        <FileText className="h-4 w-4 shrink-0 text-zinc-500" />
        <div className="flex flex-col">
          <span className="text-sm text-black">{currentFile.name}</span>
        </div>
        <X
          className="ml-auto h-4 w-4 shrink-0 cursor-pointer text-zinc-500"
          onClick={onClear}
        />
      </div>
    );
  }

  let dropState: VariantProps<typeof dropZoneVariants>['dropState'] = 'default';
  let backgroundState: VariantProps<typeof dropZoneVariants>['background'] =
    'default';

  if (isDragActive && isDragAccept) {
    dropState = 'accept';
    backgroundState = 'accept';
  } else if (isDragReject || error) {
    dropState = 'error';

    if (isDragReject) {
      backgroundState = 'error';
    }
  }

  return (
    <div
      {...getRootProps()}
      className="rounded-md focus-within:shadow-md focus-within:outline-none focus-within:ring-1 focus:border-blue-500 focus-visible:ring-blue-500"
    >
      <input {...getInputProps({ onBlur, onChange })} />
      <div
        className={cn(
          dropZoneVariants({
            dropState,
            background: backgroundState,
          })
        )}
      >
        <p className="text-center text-xs text-black">{description}</p>
      </div>
    </div>
  );
};

const dropZoneVariants = cva(
  [
    'flex flex-col items-center justify-center',
    'p-4',
    'rounded-lg border border-dashed',
    'cursor-pointer',
  ],
  {
    variants: {
      dropState: {
        default: 'border-zinc-500',
        accept: 'border-green-500',
        error: 'border-red-500',
      },
      background: {
        default: 'bg-white',
        accept: 'bg-green-100',
        error: 'bg-red-100',
      },
    },
    defaultVariants: {
      dropState: 'default',
      background: 'default',
    },
  }
);
