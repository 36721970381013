import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

import { BasicDialog, NodeIcon } from '@spektr/client/components';

import { getExistingSource, processBuilderUrl } from '@spektr/shared/utils';
import { Process } from '@spektr/shared/types';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { SourceProvider } from './providers/SourceProvider';

import { AnotherProcessMethod } from './containers/AnotherProcessMethod';
import { CustomFilterMethod } from './containers/CustomFilterMethod';
import { FooterActions } from './containers/FooterActions';
import { ProcessTrigger } from './containers/ProcessTrigger';

import { FilterSkeleton } from './components/FilterSkeleton';

export type ProcessFilterDialogProps = {
  process: Process;
};

export const ProcessFilterDialog = ({ process }: ProcessFilterDialogProps) => {
  const { processTriggers } = useFeatureFlags();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(processBuilderUrl(process.id));
  };

  const showProcessTrigger = () => {
    return processTriggers && process.type === 'monitoring';
  };

  return (
    <BasicDialog
      title={
        <div className="flex items-center gap-1">
          <span>Process settings</span>
          <span className="text-color-text-subtext text-sm">
            - choose what data flows into this process
          </span>
        </div>
      }
      defaultOpen
      icon={<NodeIcon size="lg" className="mr-2" nodeType="filter" />}
      size="large"
      onClose={handleClose}
    >
      <SourceProvider
        initialSource={getExistingSource(process)}
        process={process}
      >
        <Suspense fallback={<FilterSkeleton />}>
          <div className="flex h-full w-full grow flex-col gap-4">
            <CustomFilterMethod />
            <AnotherProcessMethod process={process} />
            {showProcessTrigger() && <ProcessTrigger />}
            <FooterActions process={process} onClose={handleClose} />
          </div>
        </Suspense>
      </SourceProvider>
    </BasicDialog>
  );
};
