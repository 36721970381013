import { useIncompleteCalculationNode } from '@spektr/shared/components';

import { type NodeUpdateInput } from '@spektr/shared/types';

import { DialogFooterActions } from '../../../../components/DialogFooterActions';

export namespace CalculationFooter {
  export type Props = {
    isPendingUpdate?: boolean;
    onUpdate: (data: NodeUpdateInput) => void;
  };
}

export const CalculationFooter = ({
  isPendingUpdate,
  onUpdate,
}: CalculationFooter.Props) => {
  const { calculationNode } = useIncompleteCalculationNode();

  const handleSave = () => {
    if (!calculationNode.success) return;

    onUpdate(calculationNode.data);
  };

  const disabled = !calculationNode.success;

  return (
    <DialogFooterActions
      btnColor="cyan"
      disabled={disabled}
      isPendingUpdate={isPendingUpdate}
      disabledReason="Please make sure all fields are filled out"
      onSave={handleSave}
    />
  );
};
