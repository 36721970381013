import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RBAC } from '@spektr/shared/rbac';
import { triggerFrequency } from '@spektr/shared/validators';
import { TriggerFrequency } from '@spektr/shared/types';

import { Input, SelectWithTooltip } from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { useSourceProvider } from '../../providers/SourceProvider';

const triggerFrequencies = ['day', 'week', 'month'] as const;

export const ProcessTrigger = () => {
  const { t } = useTranslation('process');
  const { processId, trigger, setTrigger } = useSourceProvider();
  const { hasPermission } = usePermissionsContext();

  const disabledReason = useMemo(() => {
    if (!hasPermission(RBAC.ACTIONS.PROCESS.LAUNCH)) {
      return t('errors.permissions.trigger');
    }

    return '';
  }, [hasPermission, t]);

  useEffect(() => {
    if (!trigger?.startDate) {
      const todayAtMidday = new Date().setUTCHours(12, 0, 0, 0);
      const defaultStartDate = new Date(todayAtMidday).setUTCDate(
        new Date(todayAtMidday).getDate() + 1
      );
      setTrigger({ processId, startDate: defaultStartDate });
    }

    if (!trigger?.frequency) {
      setTrigger({
        frequency: triggerFrequency[2],
      });
    }

    if (!trigger?.interval) {
      setTrigger({
        interval: trigger?.interval || 1,
      });
    }
  }, [processId, setTrigger, trigger]);

  const frequencyOptions = triggerFrequencies.map((frequency, index) => ({
    label: frequency,
    value: triggerFrequency[index],
  }));

  const canTrigger = !disabledReason;

  return (
    <div className="flex flex-col gap-4 rounded-md border p-4">
      <div className="flex items-center justify-between gap-6">
        <p className="text-sm font-medium">
          Set schedule frequency relative to launch time
        </p>

        <div className="flex items-center gap-2">
          <span className="text-color-text-subtext text-sm">every</span>

          <Input
            className="w-20"
            defaultValue={trigger?.interval || 1}
            disabled={!canTrigger}
            type="number"
            onChange={(ev) => setTrigger({ interval: +ev.target.value })}
          />

          <SelectWithTooltip
            value={trigger?.frequency || 'monthly'}
            options={frequencyOptions}
            disabled={!canTrigger}
            onValueChange={(value) =>
              setTrigger({ frequency: value as TriggerFrequency })
            }
            tooltipProps={{
              content: disabledReason,
              side: 'left',
            }}
            showTooltip={!canTrigger}
          />
        </div>
      </div>
    </div>
  );
};
