import { LabelHTMLAttributes, ReactNode } from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

export namespace Label {
  export type Props = LabelHTMLAttributes<HTMLLabelElement> &
    VariantProps<typeof variants> & {
      required?: boolean;
      children: ReactNode;
    };
}

export const Label = ({
  children,
  className,
  error,
  ...props
}: Label.Props) => {
  return (
    <label className={cn(variants({ error }), className)} {...props}>
      {children}
      {children !== '' && props.required && (
        <span className="ml-1 text-red-500">*</span>
      )}
    </label>
  );
};

const variants = cva('text-sm font-medium', {
  variants: {
    error: {
      true: 'text-red-500',
      false: 'text-black',
    },
  },
  defaultVariants: {
    error: false,
  },
});
