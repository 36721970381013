import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

type CustomerTimelineRowProps = {
  isLast?: boolean;
  className?: string;
  children: ReactNode;
};

export const CustomerTimelineRow = ({
  isLast = false,
  className,
  children,
}: CustomerTimelineRowProps) => {
  return (
    <div
      className={cn(
        'grid grid-cols-12 gap-4 p-6',
        !isLast && 'border-b border-dashed',
        className
      )}
    >
      {children}
    </div>
  );
};
