//TODO:  [ST-1237] Label and help are just here for backwards compatibility, they should be removed when the label and help are moved to a localization file and retrieved by the field key

export const commonSpektrFields = {
  soldPublicationDate: {
    key: 'sold_publication_date',
    type: 'string',
    label: 'Sold publication date',
    help: 'Sold publication date',
  },
  soldCompanyName: {
    key: 'sold_company_name',
    type: 'string',
    label: 'Sold company name',
    help: 'Sold company name',
  },
  registeredPublicationDate: {
    key: 'registered_publication_date',
    type: 'string',
    label: 'Registered publication date',
    help: 'Registered publication date',
  },
  registeredCompanyName: {
    key: 'registered_company_name',
    type: 'string',
    label: 'Registered company name',
    help: 'Registered company name',
  },
  branchPublicationDate: {
    key: 'branch_publication_date',
    type: 'string',
    label: 'Branch publication date',
    help: 'Branch publication date',
  },
  branchCompanyName: {
    key: 'branch_company_name',
    type: 'string',
    label: 'Branch company name',
    help: 'Branch company name',
  },
  changesPublicationDate: {
    key: 'changes_publication_date',
    type: 'string',
    label: 'Changes publication date',
    help: 'Changes publication date',
  },
  changesCompanyName: {
    key: 'changes_company_name',
    type: 'string',
    label: 'Changes company name',
    help: 'Changes company name',
  },
  deregisteredPublicationDate: {
    key: 'deregistered_publication_date',
    type: 'string',
    label: 'Deregistered publication date',
    help: 'Deregistered publication date',
  },
  deregisteredCompanyName: {
    key: 'deregistered_company_name',
    type: 'string',
    label: 'Deregistered company name',
    help: 'Deregistered company name',
  },
  collectiveProceedingsPublicationDate: {
    key: 'collective_proceedings_publication_date',
    type: 'string',
    label: 'Collective proceedings publication date',
    help: 'Collective proceedings publication date',
  },
  collectiveProceedingsCompanyName: {
    key: 'collective_proceedings_company_name',
    type: 'string',
    label: 'Collective proceedings company name',
    help: 'Collective proceedings company name',
  },
  conciliationPublicationDate: {
    key: 'conciliation_publication_date',
    type: 'string',
    label: 'Conciliation publication date',
    help: 'Conciliation publication date',
  },
  conciliationCompanyName: {
    key: 'conciliation_company_name',
    type: 'string',
    label: 'Conciliation company name',
    help: 'Conciliation company name',
  },
  sanctionsPublicationDate: {
    key: 'sanctions_publication_date',
    type: 'string',
    label: 'Sanctions publication date',
    help: 'Sanctions publication date',
  },
  sanctionsEntityType: {
    key: 'sanctions_entity_type',
    type: 'string',
    label: 'Sanctions entity type',
    help: 'Sanctions entity type',
  },
  sanctionsCompanyName: {
    key: 'sanctions_company_name',
    type: 'string',
    label: 'Sanctions company name',
    help: 'Sanctions company name',
  },
  warningsPublicationDate: {
    key: 'warnings_publication_date',
    type: 'string',
    label: 'Warnings publication date',
    help: 'Warnings publication date',
  },
  warningsEntityType: {
    key: 'warnings_entity_type',
    type: 'string',
    label: 'Warnings entity type',
    help: 'Warnings entity type',
  },
  warningsCompanyName: {
    key: 'warnings_company_name',
    type: 'string',
    label: 'Warnings company name',
    help: 'Warnings company name',
  },
  adverseMediaPublicationDate: {
    key: 'adverse_media_publication_date',
    type: 'string',
    label: 'Adverse media publication date',
    help: 'Adverse media publication date',
  },
  adverseMediaEntityType: {
    key: 'adverse_media_entity_type',
    type: 'string',
    label: 'Adverse media entity type',
    help: 'Adverse media entity type',
  },
  adverseMediaCompanyName: {
    key: 'adverse_media_company_name',
    type: 'string',
    label: 'Adverse media company name',
    help: 'Adverse media company name',
  },
  sanctionsFullName: {
    key: 'sanctions_full_name',
    type: 'string',
    label: 'Sanctions full name',
    help: 'Sanctions full name',
  },
  warningsFullName: {
    key: 'warnings_full_name',
    type: 'string',
    label: 'Warnings full name',
    help: 'Warnings full name',
  },
  adverseMediaFullName: {
    key: 'adverse_media_full_name',
    type: 'string',
    label: 'Adverse media full name',
    help: 'Adverse media full name',
  },
  pepPublicationDate: {
    key: 'pep_publication_date',
    type: 'string',
    label: 'Is PEP publication date',
    help: 'Is PEP publication date',
  },
  pepEntityType: {
    key: 'pep_entity_type',
    type: 'string',
    label: 'Is PEP entity type',
    help: 'Is PEP entity type',
  },
  pepFullName: {
    key: 'pep_full_name',
    type: 'string',
    label: 'Is PEP full name',
    help: 'Is PEP full name',
  },
  pepClass1PublicationDate: {
    key: 'pep_class1_publication_date',
    type: 'string',
    label: 'Is PEP class 1 publication date',
    help: 'Is PEP class 1publication date',
  },
  pepClass1EntityType: {
    key: 'pep_class1_entity_type',
    type: 'string',
    label: 'Is PEP class 1 entity type',
    help: 'Is PEP class 1 entity type',
  },
  pepClass1FullName: {
    key: 'pep_class1_full_name',
    type: 'string',
    label: 'Is PEP class 1 full name',
    help: 'Is PEP class 1 full name',
  },
  pepClass2PublicationDate: {
    key: 'pep_class2_publication_date',
    type: 'string',
    label: 'Is PEP class 2 publication date',
    help: 'Is PEP class 2 publication date',
  },
  pepClass2EntityType: {
    key: 'pep_class2_entity_type',
    type: 'string',
    label: 'Is PEP class 2 entity type',
    help: 'Is PEP class 2 entity type',
  },
  pepClass2FullName: {
    key: 'pep_class2_full_name',
    type: 'string',
    label: 'Is PEP class 2 full name',
    help: 'Is PEP class 2 full name',
  },
  pepClass3PublicationDate: {
    key: 'pep_class3_publication_date',
    type: 'string',
    label: 'Is PEP class 3 publication date',
    help: 'Is PEP class 3 publication date',
  },
  pepClass3EntityType: {
    key: 'pep_class3_entity_type',
    type: 'string',
    label: 'Is PEP class 3 entity type',
    help: 'Is PEP class 3 entity type',
  },
  pepClass3FullName: {
    key: 'pep_class3_full_name',
    type: 'string',
    label: 'Is PEP class 3 full name',
    help: 'Is PEP class 3 full name',
  },
  pepClass4PublicationDate: {
    key: 'pep_class4_publication_date',
    type: 'string',
    label: 'Is PEP class 4 publication date',
    help: 'Is PEP class 4 publication date',
  },
  pepClass4EntityType: {
    key: 'pep_class4_entity_type',
    type: 'string',
    label: 'Is PEP class 4 entity type',
    help: 'Is PEP class 4 entity type',
  },
  pepClass4FullName: {
    key: 'pep_class4_full_name',
    type: 'string',
    label: 'Is PEP class 4 full name',
    help: 'Is PEP class 4 full name',
  },
  fullName: {
    key: 'full_name',
    type: 'string',
    label: 'Full name',
    help: 'Full name',
  },
  occupation: {
    key: 'occupation',
    type: 'string',
    label: 'Occupation',
    help: 'Occupation',
  },
  occupationStartDate: {
    key: 'occupation_start_date',
    type: 'string',
    label: 'Occupation start date',
    help: 'Occupation start date',
  },
  occupationIsActive: {
    key: 'occupation_is_active',
    type: 'boolean',
    label: 'Occupation is active',
    help: 'Occupation is active',
  },
  dateOfBirth: {
    key: 'date_of_birth',
    type: 'string',
    label: 'Date of birth',
    help: 'Date of birth',
  },
  nationality: {
    key: 'nationality',
    type: 'string',
    label: 'Nationality',
    help: 'Nationality',
  },
  fullAddress: {
    key: 'full_address',
    type: 'string',
    label: 'Full address',
    help: 'Full address',
  },
  companyFullAddress: {
    key: 'company_full_address',
    type: 'string',
    label: 'Full address',
    help: 'Full address',
  },
  companyName: {
    key: 'company_name',
    type: 'string',
    label: 'Company name',
    help: 'Company name',
  },
  position: {
    key: 'position',
    type: 'string',
    label: 'Position',
    help: 'Position',
  },
  positionStartDate: {
    key: 'position_start_date',
    type: 'string',
    label: 'Position start date',
    help: 'Position start date',
  },
  positionIsActive: {
    key: 'position_is_active',
    type: 'boolean',
    label: 'Position is active',
    help: 'Position is active',
  },
  companyNumber: {
    key: 'company_number',
    type: 'string',
    label: 'Company number',
    help: 'Company number',
  },
  registrationAuthority: {
    key: 'registration_authority',
    type: 'string',
    label: 'Registration authority',
    help: 'Registration authority',
  },
  registrationFullAddress: {
    key: 'registration_full_address',
    type: 'string',
    label: 'Registration full address',
    help: 'Registration full address',
  },
  shareholdingPercentage: {
    key: 'shareholding_percentage',
    type: 'string',
    label: 'Shareholding percentage',
    help: 'Shareholding percentage',
  },
  entityType: {
    key: 'entity_type',
    type: 'string',
    label: 'Entity type',
    help: 'Entity type',
  },
  buildingName: {
    key: 'building_name',
    type: 'string',
    label: 'Building name',
    help: 'Building name',
  },
  streetNumber: {
    key: 'street_number',
    type: 'string',
    label: 'Street number',
    help: 'Street number',
  },
  streetName: {
    key: 'street_name',
    type: 'string',
    label: 'Street name',
    help: 'Street name',
  },
  companyStreetName: {
    key: 'company_street_name',
    type: 'string',
    label: 'Street name',
    help: 'Street name',
  },
  city: {
    key: 'city',
    type: 'string',
    label: 'City',
    help: 'City',
  },
  companyCity: {
    key: 'company_city',
    type: 'string',
    label: 'City',
    help: 'City',
  },
  postcode: {
    key: 'zip_code',
    type: 'string',
    label: 'Postcode',
    help: 'Postcode',
  },
  companyZipCode: {
    key: 'company_zip_code',
    type: 'string',
    label: 'Postcode',
    help: 'Postcode',
  },
  municipality: {
    key: 'municipality',
    type: 'string',
    label: 'Municipality',
    help: 'Municipality',
  },
  region: {
    key: 'region',
    type: 'string',
    label: 'Region',
    help: 'Region',
  },
  companyRegion: {
    key: 'company_state',
    type: 'string',
    label: 'Region',
    help: 'Region',
  },
  country: {
    key: 'country',
    type: 'string',
    label: 'Country',
    help: 'Country',
  },
  country_residence: {
    key: 'country_residence',
    type: 'string',
    label: 'Country residence',
    help: 'Country residence',
  },
  email: {
    key: 'email',
    type: 'string',
    label: 'Email',
    help: 'Email',
  },
  phoneNumber: {
    key: 'phone_number',
    type: 'string',
    label: 'Phone number',
    help: 'Phone number',
  },
  website: {
    key: 'website',
    type: 'string',
    label: 'Website',
    help: 'Website',
  },
  taxNumber: {
    key: 'tax_number',
    type: 'string',
    label: 'Tax number',
    help: 'Tax number',
  },
  legal_form: {
    key: 'legal_form',
    type: 'string',
    label: 'Legal form',
    help: 'Legal form',
  },
  national_id: {
    key: 'national_id',
    type: 'string',
    label: 'National ID',
    help: 'National ID',
  },
  foundationDate: {
    key: 'foundation_date',
    type: 'string',
    label: 'Foundation date',
    help: 'Foundation date',
  },
  registrationDate: {
    key: 'registration_date',
    type: 'string',
    label: 'Registration date',
    help: 'Registration date',
  },
  incorporationDate: {
    key: 'incorporation_date',
    type: 'string',
    label: 'Incorporation date',
    help: 'Incorporation date',
  },
  dissolutionDate: {
    key: 'dissolution_date',
    type: 'string',
    label: 'Dissolution date',
    help: 'Dissolution date',
  },
  companyStatus: {
    key: 'company_status',
    type: 'string',
    label: 'Company status',
    help: 'Company status',
  },
  jurisdictionCode: {
    key: 'jurisdiction_code',
    type: 'string',
    label: 'Jurisdiction code',
    help: 'Jurisdiction code',
  },
  companyType: {
    key: 'company_type',
    type: 'string',
    label: 'Company type',
    help: 'Company type',
  },
  firstName: {
    key: 'first_name',
    type: 'string',
    label: 'First name',
    help: 'First name',
  },
  lastName: {
    key: 'last_name',
    type: 'string',
    label: 'Last name',
    help: 'Last name',
  },
  gender: {
    key: 'gender',
    type: 'string',
    label: 'Gender',
    help: 'Gender',
  },
  idNumber: {
    key: 'id_number',
    type: 'string',
    label: 'ID number',
    help: 'ID number',
  },
  yearOfBirth: {
    key: 'year_of_birth',
    type: 'string',
    label: 'Year of birth',
    help: 'Year of birth',
  },
  employer: {
    key: 'employer',
    type: 'string',
    label: 'Employer',
    help: 'Employer',
  },
  cprNumberIdentifier: {
    key: 'cpr_number_identifier',
    type: 'string',
    label: 'CPR number identifier',
    help: 'CPR number identifier',
  },
  documentValidFrom: {
    key: 'document_valid_from',
    type: 'string',
    label: 'Document valid from',
    help: 'Document valid from',
  },
  documentNumber: {
    key: 'document_number',
    type: 'string',
    label: 'Document number',
    help: 'Document number',
  },
  documentValidUntil: {
    key: 'document_valid_until',
    type: 'string',
    label: 'Document valid until',
    help: 'Document valid until',
  },
  documentType: {
    key: 'document_type',
    type: 'string',
    label: 'Document type',
    help: 'Document type',
  },
  documentCountry: {
    key: 'document_country',
    type: 'string',
    label: 'Document country',
    help: 'Document country',
  },
  documentFirstIssue: {
    key: 'document_first_issue',
    type: 'string',
    label: 'Document first issue',
    help: 'Document first issue',
  },
  documentNfcValidated: {
    key: 'document_nfc_validated',
    type: 'boolean',
    label: 'Document NFC validated',
    help: 'Document NFC validated',
  },
  driverLicenceNumber: {
    key: 'driver_licence_number',
    type: 'string',
    label: 'Driver licence number',
    help: 'Driver licence number',
  },

  hasAdverseMediaUnresolvedAlerts: {
    key: 'has_adverse_media_unresolved_alerts',
    type: 'boolean',
    label: 'Has adverse media unresolved alerts',
    help: 'Has adverse media unresolved alerts',
  },

  hasAdverseMediaAlertsWithNoMatches: {
    key: 'has_adverse_media_alerts_with_no_matches',
    type: 'boolean',
    label: 'Has adverse media alerts with no matches',
    help: 'Has adverse media alerts with no matches',
  },

  hasSanctionsUnresolvedAlerts: {
    key: 'has_sanctions_unresolved_alerts',
    type: 'boolean',
    label: 'Has sanctions unresolved alerts',
    help: 'Has sanctions unresolved alerts',
  },

  hasSanctionsAlertsWithNoMatches: {
    key: 'has_sanctions_alerts_with_no_matches',
    type: 'boolean',
    label: 'Has sanctions alerts with no matches',
    help: 'Has sanctions alerts with no matches',
  },

  hasIsPepUnresolvedAlerts: {
    key: 'has_is_pep_unresolved_alerts',
    type: 'boolean',
    label: 'Has is PEP unresolved alerts',
    help: 'Has is PEP unresolved alerts',
  },

  hasIsPepAlertsWithNoMatches: {
    key: 'has_is_pep_alerts_with_no_matches',
    type: 'boolean',
    label: 'Has is PEP alerts with no matches',
    help: 'Has is PEP alerts with no matches',
  },

  hasIdVerification: {
    key: 'has_id_verification',
    type: 'boolean',
    label: 'Has ID verification',
    help: 'Has ID verification',
  },

  hasBusinessVerificationUnresolvedAlerts: {
    key: 'has_business_verification_unresolved_alerts',
    type: 'boolean',
    label: 'Has Open Corporates unresolved alerts',
    help: 'Has Open Corporates unresolved alerts',
  },

  hasBusinessVerificationAlertsWithNoMatches: {
    key: 'has_business_verification_alerts_with_no_matches',
    type: 'boolean',
    label: 'Has Open Corporates alerts with no matches',
    help: 'Has Open Corporates alerts with no matches',
  },
  customerType: {
    key: 'customer_type', // 'individual' or 'company'
    type: 'string',
    label: 'Customer type',
    help: 'Customer type',
  },
  citizenship: {
    key: 'citizenship',
    type: 'string',
    label: 'Citizenship',
    help: 'Citizenship',
  },
  placeOfBirth: {
    key: 'place_of_birth',
    type: 'string',
    label: 'Place of birth',
    help: 'Place of birth',
  },
  virkCvrNumber: {
    key: 'virk_cvr_number',
    type: 'string',
    label: 'VIRK CVR number',
    help: 'VIRK CVR number',
  },
};
