import { useState } from 'react';
import {
  getSmoothStepPath,
  EdgeLabelRenderer,
  BaseEdge,
  type EdgeProps,
} from 'reactflow';
import { ContactRound, Newspaper } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { Popover } from '@spektr/client/components';

import { ConnectionPopover } from '../ConnectionPopover';

import type { EdgeData } from '../../types/EdgeData';

export namespace EdgeWithLabel {
  export type Props = EdgeProps<EdgeData>;
}

export const EdgeWithLabel = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  ...props
}: EdgeWithLabel.Props) => {
  const [open, setOpen] = useState(false);

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    centerY: sourceY + Math.abs(targetY - sourceY) / 4,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeStyle = {
    ...props.style,
    stroke: 'rgb(var(--zinc-500))',
  };

  if (props.selected) {
    edgeStyle.stroke = 'rgb(var(--blue-500))';
  }

  return (
    <>
      <BaseEdge {...props} path={edgePath} style={edgeStyle} />
      <EdgeLabelRenderer>
        <Popover
          align="center"
          alignOffset={0}
          className="bg-white dark:bg-zinc-900"
          side="right"
          sideOffset={80}
          triggerProps={{
            asChild: true,
          }}
          open={open}
          onOpenChange={setOpen}
          trigger={
            <div
              className={cn(
                'absolute',
                'flex flex-col items-start justify-center gap-2',
                'px-3 py-2',
                'rounded-md border border-zinc-300 dark:border-zinc-700',
                'bg-white dark:bg-zinc-900',
                'text-zinc-700 dark:text-zinc-300',
                'cursor-pointer',
                'nodrag nopan',
                {
                  'border-blue-500': props.selected,
                }
              )}
              style={{
                pointerEvents: 'all',
                transform: `translate(-50%, -125%) translate(${targetX}px,${targetY}px)`,
              }}
            >
              <div className="flex items-center gap-2">
                <ContactRound className="h-3 w-3" />
                <span className="flex-grow text-[10px] font-medium capitalize">
                  {data?.role ?? 'Unknown role'}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Newspaper className="h-3 w-3" />
                <span className="flex-grow text-[10px] font-medium">
                  {data?.label ?? 'Unknown ownership'}
                </span>
              </div>
            </div>
          }
        >
          <ConnectionPopover
            id={props.id}
            key={data?.ownershipShares}
            connection={data!}
            source={props.source}
            target={props.target}
            onClose={() => setOpen(false)}
          />
        </Popover>
      </EdgeLabelRenderer>
    </>
  );
};
