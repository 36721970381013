import { useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { type InputField as InputFieldProp } from '@spektr/moonraker-types';

import { FormField, Select, CountrySelect } from '@spektr/components-forms';

// TODO: Move this in a shared location
import countries from '../../../assets/files/countries.json';

import { getCountryCode, getCountryName } from '../../utils/countries';

export type CountryFieldProps = {
  field: InputFieldProp;
  name: string;
};
export const CountryField = ({ field, name }: CountryFieldProps) => {
  const context = useFormContext();
  const [searchValue, setSearchValue] = useState('');

  const requiredValidation = field.validation?.find(
    (v) => v.type === 'required'
  );
  const {
    field: formField,
    fieldState: { invalid, error },
  } = useController({
    name,
    control: context.control,
    rules: {
      required: requiredValidation?.message,
    },
  });

  const options = useMemo<Select.Option[]>(() => {
    return countries
      .map((country) => ({
        value: getCountryCode(country),
        label: getCountryName(country),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  const filteredOptions = useMemo<Select.Option[]>(() => {
    return options.filter(
      (option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
        option.value.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [options, searchValue]);

  return (
    <FormField
      label={field.attributes.label}
      required={!!requiredValidation}
      helperText={error ? error.message : field.attributes.helperText}
      name={name}
      hasError={invalid}
    >
      <CountrySelect
        options={filteredOptions}
        value={formField.value}
        defaultValue={formField.value}
        placeholder={field.attributes.placeholder}
        onValueChange={formField.onChange}
        onSearch={setSearchValue}
      />
    </FormField>
  );
};
