import { ComponentProps } from 'react';
import { DayPicker } from 'react-day-picker';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

import 'react-day-picker/dist/style.css';

import { cn } from '@spektr/style-utils';

import { useCalendar } from './hooks/useCalendar';

import { CalendarDropdown } from './components/CalendarDropdown';

export type CalendarProps = ComponentProps<typeof DayPicker>;

function Calendar({
  fromYear = 1900,
  toYear = 2100,
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const { getDate, updateMonth, updateYear } = useCalendar(
    (props.selected as Date) ?? new Date()
  );

  return (
    <DayPicker
      fromYear={fromYear}
      toYear={toYear}
      captionLayout="dropdown-buttons"
      showOutsideDays={showOutsideDays}
      className={cn(
        'flex items-center justify-center',
        'w-72 p-1',
        'bg-white',
        'text-black',
        className
      )}
      classNames={{
        month: 'space-y-4 flex flex-col items-center justify-center',
        caption: cn('relative', 'flex justify-center items-center', 'pt-1'),
        caption_dropdowns: 'flex items-center gap-2',
        caption_label: cn(
          'flex items-center',
          'px-2 py-1',
          'rounded-md',
          'text-sm font-medium',
          'z-[1] opacity-50'
        ),
        dropdown: cn(
          'absolute top-0 right-0 bottom-0 left-0 p-0',
          'z-[2] appearance-none opacity-0 cursor-pointer'
        ),
        dropdown_icon: 'hidden',
        dropdown_month: 'relative group w-32',
        dropdown_year: 'relative group',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          'flex items-center justify-center',
          'h-7 w-7 p-0',
          'rounded-md',
          'hover:!bg-zinc-400 focus:!bg-zinc-400'
        ),
        nav_button_previous: cn('absolute left-1'),
        nav_button_next: cn('absolute right-1'),
        table: 'border-collapse space-y-1',
        head_row: 'flex',
        head_cell: cn('w-8', 'rounded-md', 'font-normal text-[0.8rem]'),
        row: 'flex w-full mt-2',
        cell: cn(
          'relative',
          'p-0',
          'text-center text-sm',
          'focus-within:relative focus-within:z-20',
          props.mode === 'range'
            ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
            : '[&:has([aria-selected])]:rounded-md'
        ),
        day: cn(
          'flex items-center justify-center',
          'h-8 w-8 p-0',
          'rounded-md',
          'font-normal',
          'aria-selected:opacity-100 aria-selected:bg-blue-400',
          'hover:!bg-zinc-400 focus:!bg-zinc-400'
        ),
        day_selected: 'rounded-md',
        day_today: cn('rounded-md', 'text-black font-medium'),
        day_hidden: 'invisible',
        vhidden: 'hidden',
        ...classNames,
      }}
      components={{
        IconLeft: ({ className, ...props }) => (
          <ChevronLeftIcon className={cn('h-4 w-4', className)} {...props} />
        ),
        IconRight: ({ className, ...props }) => (
          <ChevronRightIcon className={cn('h-4 w-4', className)} {...props} />
        ),
        Dropdown: (props) => (
          <CalendarDropdown
            getDate={getDate}
            updateMonth={updateMonth}
            updateYear={updateYear}
            {...props}
          />
        ),
      }}
      defaultMonth={props.selected as Date}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
