import { cn } from '@spektr/style-utils';

export const companiesHouseContained = cn(
  'bg-color-companiesHouse',
  'text-color-text-button-contained-vendor',
  'hover:bg-color-companiesHouse/80',
  'active:bg-color-companiesHouse/90'
);
export const companiesHouseOutlined = cn(
  companiesHouseContained,
  'border-color-companiesHouse',
  'text-color-companiesHouse',
  'hover:bg-color-companiesHouse/10',
  'active:bg-color-companiesHouse/20',
  'disabled:border-color-companiesHouse/50'
);
export const companiesHouseText = cn('text-color-companiesHouse');
