import { useSuspenseQuery } from '@tanstack/react-query';

import { getProcessLinksByIdQuery } from '@spektr/client/services';
import { EntitiesField } from '@spektr/moonraker-types';
import { Process } from '@spektr/shared/types';
import { FormNode } from '@spektr/shared/validators';

import { getFormFieldOrderIndex } from '../providers/FormEngine/utils/getFormFieldOrderIndex';

export const useEntityHasConnectedProcesses = (
  process: Process,
  node: FormNode
) => {
  const { data: links } = useSuspenseQuery(
    getProcessLinksByIdQuery(process.id)
  );

  const entityHasConnectedProcesses = (field: EntitiesField): boolean => {
    if (!node) {
      return false;
    }

    const fieldLabel = field.attributes.label;
    const adjBranches = node.adj.filter(
      (branch) => branch.isFinal && branch.label === fieldLabel
    );

    const idx = getFormFieldOrderIndex(field, node);
    const adjBranch =
      adjBranches.length > 1 ? adjBranches[idx] : adjBranches[0];

    if (!adjBranch) {
      return false;
    }

    const linked = links.find(
      (link) =>
        link.source.outcomeId === adjBranch.id &&
        link.source.processId === process.id
    );

    return !!linked;
  };

  return entityHasConnectedProcesses;
};
