import { memo, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { Check, ChevronRight, CircleStop, Plus } from 'lucide-react';

import { OutcomeNode as OutcomeNodeType } from '@spektr/shared/types';
import { RBAC } from '@spektr/shared/rbac';
import {
  useCreateProcessWithSource,
  useDeleteNode,
  useUpdateNode,
} from '@spektr/shared/hooks';
import { loopDetailsUrl, processBuilderUrl } from '@spektr/shared/utils';

import { getProcessesQuery, getTagsQuery } from '@spektr/client/services';
import { cn } from '@spektr/style-utils';
import { usePermissionsContext } from '@spektr/client/providers';
import {
  AlertDialog,
  DropdownOption,
  Tag,
  toast,
} from '@spektr/client/components';

import { ProcessConnectDialog } from '../../ProcessConnectDialog';
import { TagCreationDialog } from '../../TagCreationDialog';

import { GraphNodeProps } from '../types';
import { NODE } from '../constants';

import { NodeDeleteButton } from '../BasicNode/components/NodeDeleteButton';

import { OutcomeNodeProcessLink } from './components/OutcomeNodeProcessLink';
import { OutcomeNodeTag } from './components/OutcomeNodeTag';

type OutcomeNodeProps = GraphNodeProps<OutcomeNodeType>;

export const OutcomeNode = memo(({ data }: OutcomeNodeProps) => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissionsContext();
  const { process, node, meta } = data;
  const deleteNodeMutation = useDeleteNode(process.id, node.id);
  const updateNodeMutation = useUpdateNode(process.id);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [showConnectionDialog, setShowConnectionDialog] = useState(false);
  const [showTagCreateModal, setShowTagCreateModal] = useState(false);
  const linkedProcessesIds = useMemo(
    () =>
      meta.links.find((link) => link.source.outcomeId === node.id)?.processes ??
      [],
    [meta.links, node.id]
  );
  const { data: processes } = useQuery({
    ...getProcessesQuery(),
    enabled: linkedProcessesIds.length > 0,
  });
  const { data: tags } = useSuspenseQuery(getTagsQuery());
  const selectedTag = useMemo(
    () =>
      node.tagIds?.[0]
        ? tags.find((tag) => tag.id === node.tagIds?.[0])
        : undefined,
    [node.tagIds, tags]
  );

  const linkedProcesses = useMemo(() => {
    return (
      processes?.filter((process) => linkedProcessesIds.includes(process.id)) ??
      []
    );
  }, [linkedProcessesIds, processes]);

  const hasProcessLinks = linkedProcesses.length > 0;
  const canDeleteNode =
    hasPermission(RBAC.ACTIONS.NODE.DELETE) && !hasProcessLinks;
  const isLoopOutcome = process.type === 'loop';

  const handleDeleteNode = async () => {
    await deleteNodeMutation.mutateAsync();
    setShowDeleteConfirmDialog(false);
  };
  const createProcess = useCreateProcessWithSource(process.id, node.id);

  const tagActions = useMemo(() => {
    const tagOptions = tags
      .filter((tag) => selectedTag?.id === tag.id || !tag.isDeleted)
      .map((availableTag) => ({
        label: (
          <div className="flex w-full items-center gap-1.5">
            <Tag
              label={availableTag.label}
              color={availableTag.color}
              isDeleted={availableTag.isDeleted}
            />
            {availableTag.id === node.tagIds?.[0] && (
              <Check className="ml-auto h-3 w-3" />
            )}
          </div>
        ),
        value: availableTag.id,
        type: 'item',
      }));

    return [
      {
        label: (
          <div className="flex w-full items-center gap-1.5">
            <Tag
              label={tagOptions.length > 0 ? 'Manage tags' : 'Add new tag'}
            />
            {tagOptions.length > 0 ? (
              <ChevronRight className="ml-auto h-3 w-3" />
            ) : (
              <Plus className="ml-auto h-3 w-3" />
            )}
          </div>
        ),
        type: 'item',
        value: 'add-tag',
      },
      ...tagOptions,
    ] as DropdownOption[];
  }, [tags, node.tagIds, selectedTag?.id]);

  const handleLinkActionClick = (action: string) => {
    const linkedProcess = linkedProcesses.find(
      (process) => process.id === action
    );

    switch (action) {
      case 'create-risk':
        createProcess('risk');
        break;
      case 'create-monitoring':
        createProcess('monitoring');
        break;
      case 'create-loop':
        createProcess('loop');
        break;
      case 'create-onboarding':
        createProcess('onboarding');
        break;
      case 'create-score':
        createProcess('score');
        break;
      case 'connect':
        setShowConnectionDialog(true);
        break;
      default:
        // Check if the action is a process id and navigate to the process
        if (linkedProcess) {
          const processUrl =
            linkedProcess.type === 'loop'
              ? loopDetailsUrl(action)
              : processBuilderUrl(action);

          navigate(processUrl);
        }
        break;
    }
  };

  const handleTagActionClick = async (tagId: string) => {
    if (tagId === 'add-tag') {
      setShowTagCreateModal(true);
      return;
    }

    const tagIds = node.tagIds?.[0] === tagId ? [] : [tagId];

    await updateNodeMutation.mutateAsync({
      nodeId: node.id,
      node: {
        nodeType: 'outcome',
        route: node.route,
        tagIds,
      },
    });
  };

  const handleDelete = () => {
    if (canDeleteNode) {
      setShowDeleteConfirmDialog(true);
      return;
    }

    if (hasProcessLinks) {
      toast.error({
        title: 'Error',
        description:
          'You cannot delete this outcomes because it is linked to another process, remove the link before deleting it.',
      });
    }
  };

  return (
    <div
      className="group relative flex h-[80px] items-center justify-center"
      style={{
        height: NODE.HEIGHT,
      }}
    >
      <div
        className={cn(
          'flex flex-col',
          'bg-color-bg-process-builder-item',
          'border-color-border-process-builder-item border shadow-lg duration-200 sm:rounded-lg',
          isLoopOutcome && 'border-color-cyan text-color-cyan',
          'overflow-hidden'
        )}
        style={{
          maxWidth: `${NODE.WIDTH}px`,
        }}
      >
        <Handle
          type="target"
          id={node.id}
          position={Position.Left}
          className="opacity-0"
        />

        {isLoopOutcome ? (
          <div className="flex items-center gap-2 p-2">
            <CircleStop className="h-3.5 w-3.5" />
            <p className="text-xs">End</p>
          </div>
        ) : (
          <div className="flex flex-col items-start">
            <OutcomeNodeTag
              tag={selectedTag}
              actions={tagActions}
              onActionClick={handleTagActionClick}
            />
            <div className="w-full px-3">
              <div className="bg-color-border-process-builder-item h-[1px] w-full" />
            </div>
            <OutcomeNodeProcessLink
              processes={linkedProcesses}
              processType={process.type}
              onActionClick={handleLinkActionClick}
            />
          </div>
        )}
      </div>

      <NodeDeleteButton
        iconClassName="h-4 w-4"
        className="top-1/2"
        onClick={handleDelete}
      />

      <AlertDialog
        open={!!showDeleteConfirmDialog}
        title="Are you sure?"
        paragraph="You will permanently delete this outcome from the process."
        onCancel={() => setShowDeleteConfirmDialog(false)}
        cancel="Cancel"
        onConfirm={handleDeleteNode}
        confirm="Yes, delete"
        confirmDataCy="Yes-delete"
        disableButtons={deleteNodeMutation.isPending}
      />

      {showConnectionDialog && (
        <ProcessConnectDialog
          sourceProcessId={process.id}
          sourceOutcomeId={node.id}
          handleClose={() => setShowConnectionDialog(false)}
        />
      )}

      {showTagCreateModal && (
        <TagCreationDialog
          selectedTag={selectedTag}
          tags={tags}
          onUpdateTag={handleTagActionClick}
          onClose={() => setShowTagCreateModal(false)}
        />
      )}
    </div>
  );
});
