import { useMemo, useState } from 'react';
import { FileText, icons, NetworkIcon } from 'lucide-react';

import { SpektrField, StrictOmit } from '@spektr/shared/types';
import {
  NodeServiceType,
  SpektrFieldTypedKey,
} from '@spektr/shared/validators';

import {
  Combobox,
  ComboboxGroup,
  ComboboxItem,
  ComboboxProps,
  DatasetField,
  iconBoxVariants,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';
import { groupBy } from '@spektr/shared/utils';
import { getServiceFieldsWithoutSubfields } from '@spektr/shared/components';

import { SpektrFieldComboBoxItem } from './SpektrFieldComboBoxItem';

import { ServiceGroup } from './ServiceGroup';
import { CustomFieldsComboboxGroup } from './CustomFields';

export type SpektrfieldComboboxProps = {
  fields: Array<SpektrField | SpektrFieldTypedKey>;
} & StrictOmit<
  ComboboxProps,
  'onSearch' | 'noResults' | 'contentClassName' | 'noResultsText' | 'children'
>;

export const SpektrfieldCombobox = ({
  fields: initialFields,
  className,
  defaultValue,
  ...rest
}: SpektrfieldComboboxProps) => {
  const [search, setSearch] = useState(defaultValue || '');
  const hasScore = useMemo(
    () => initialFields.some((field) => field.key === 'score'),
    [initialFields]
  );

  const fields: Array<StrictOmit<SpektrField, 'sourceId'>> = useMemo(
    () =>
      getServiceFieldsWithoutSubfields(initialFields)
        .filter((field) => field.key !== 'score') // handle score separately bc we always want it to be on top in the combobox
        .map((field) => {
          const label = 'label' in field ? field.label : field.key;
          const availableIn =
            'availableIn' in field ? field.availableIn : undefined;
          const source = 'source' in field ? field.source : 'custom'; // a field without a source is considered a custom field

          return {
            key: field.key,
            type: field.type,
            label,
            availableIn,
            source,
          };
        }),
    [initialFields]
  );

  const filteredBySearch = useMemo(
    () =>
      fields.filter((field) =>
        field.label.toLowerCase().includes(search.toLowerCase())
      ),
    [fields, search]
  );

  const getValue = () => {
    if (defaultValue) {
      const selectedField = fields.find((field) => field.key === defaultValue);
      return selectedField ? selectedField.label : search;
    } else {
      return search;
    }
  };

  const groups = useMemo(
    () => groupBy(filteredBySearch, 'source'),
    [filteredBySearch]
  );
  const {
    dataset,
    custom,
    form,
    ownershipTreeForm,
    customerStatus,
    ...serviceGroups
  } = groups;
  const isEmpty = Object.keys(groups).length === 0;
  return (
    <Combobox
      className={cn('w-[374px]', className)}
      onSearch={setSearch}
      value={getValue()}
      noResults={isEmpty}
      contentClassName="max-h-none p-0"
      {...rest}
    >
      <ComboboxGroup
        className={cn(
          'max-h-64 w-full overflow-y-auto',
          !isEmpty && 'px-2 py-1'
        )}
      >
        {hasScore && (
          <ComboboxItem value="score" label="Score" children="Score" />
        )}
        {dataset && dataset.length > 0 && (
          <ComboboxGroup label="Dataset fields">
            {dataset.map(({ key, label }) => (
              <DatasetField key={key} value={key} label={label} />
            ))}
          </ComboboxGroup>
        )}
        {form && form.length > 0 && (
          <ComboboxGroup label="Form fields">
            {form.map(({ key, label }) => (
              <SpektrFieldComboBoxItem
                key={key}
                value={key}
                label={label}
                icon={
                  <FileText
                    className={cn(
                      iconBoxVariants({ variant: 'cyan' }),
                      'h-5 w-5 shrink-0 p-1'
                    )}
                  />
                }
              />
            ))}
          </ComboboxGroup>
        )}

        {ownershipTreeForm && ownershipTreeForm.length > 0 && (
          <ComboboxGroup label="Ownership Tree Form fields">
            {ownershipTreeForm.map(({ key, label }) => (
              <SpektrFieldComboBoxItem
                key={key}
                value={key}
                label={label}
                icon={
                  <NetworkIcon
                    className={cn(
                      iconBoxVariants({ variant: 'cyan' }),
                      'h-5 w-5 shrink-0 p-1'
                    )}
                  />
                }
              />
            ))}
          </ComboboxGroup>
        )}

        {customerStatus && customerStatus.length > 0 && (
          <ComboboxGroup label="Customer status">
            {customerStatus.map(({ key, label }) => {
              const iconName =
                key === 'customerStatus.approved' ? 'SquareCheck' : 'SquareX';
              const Icon = icons[iconName];
              const iconVariant =
                key === 'customerStatus.approved' ? 'cyan' : 'red';

              return (
                <SpektrFieldComboBoxItem
                  key={key}
                  value={key}
                  label={label}
                  icon={
                    <Icon
                      className={cn(
                        iconBoxVariants({ variant: iconVariant }),
                        'h-5 w-5 shrink-0 p-1'
                      )}
                    />
                  }
                />
              );
            })}
          </ComboboxGroup>
        )}

        {Object.keys(serviceGroups).length > 0 && (
          <ComboboxGroup label="Service fields">
            {Object.entries(serviceGroups ?? {}).map(([source, fields]) => (
              <ServiceGroup
                key={source}
                serviceName={source as NodeServiceType}
                fields={fields ?? []}
              />
            ))}
          </ComboboxGroup>
        )}
        {custom && <CustomFieldsComboboxGroup fields={Object.values(custom)} />}
      </ComboboxGroup>
    </Combobox>
  );
};
