import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';

import { useParsedParams } from '@spektr/shared/hooks';
import {
  casesCustomerDocumentUrl,
  casesCustomerParamsSchema,
} from '@spektr/shared/utils';

import { getCustomerDocuments } from '@spektr/client/services';
import { AlertDialog } from '@spektr/client/components';

import { useDeleteDocument } from '@spektr/platform-hooks';

import { CustomerDocumentsTable } from '../../components';

type CustomerDocumentsListProps = {
  columns: string[];
};

export const CustomerDocumentsList = ({
  columns,
}: CustomerDocumentsListProps) => {
  const navigate = useNavigate();

  const { spektrId: customerId } = useParsedParams(casesCustomerParamsSchema);
  const { data: documents } = useSuspenseQuery(
    getCustomerDocuments(customerId)
  );
  const [removeDocumentId, setRemoveDocumentId] = useState<string>('');
  const removeDocument = useDeleteDocument(customerId, () =>
    setRemoveDocumentId('')
  );

  const sortedDocuments = useMemo(
    () =>
      documents.sort((docA, docB) => {
        return (docB.createdAt ?? 0) - (docA.createdAt ?? 0);
      }),
    [documents]
  );

  const handleViewDocument = (documentKey: string) => {
    navigate(
      casesCustomerDocumentUrl(customerId, encodeURIComponent(documentKey))
    );
  };

  return (
    <div className="flex h-full flex-col items-end gap-4">
      <CustomerDocumentsTable
        documents={sortedDocuments}
        columns={columns}
        onRemoveDocument={setRemoveDocumentId}
        onViewDocument={handleViewDocument}
      />
      {removeDocumentId && (
        <AlertDialog
          open={!!removeDocumentId}
          title="Delete document"
          paragraph="Are you sure you want to delete this document? This action cannot be undone."
          onCancel={() => setRemoveDocumentId('')}
          cancel="Cancel"
          onConfirm={() => removeDocument.mutate(removeDocumentId)}
          confirm="Yes, delete"
        />
      )}
    </div>
  );
  // TODO: @cristina - add pagination when endpoint supports it
};
