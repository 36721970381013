import { cn } from '@spektr/style-utils';

import { NodeType } from '@spektr/shared/validators';

import { BasicNodeContent } from './BasicNode/components/BasicNodeContent';

type AiAmlAlertNodeProps = {
  nodeType: NodeType;
  title: string;
};

export const AiAmlAlertNode = ({ nodeType, title }: AiAmlAlertNodeProps) => {
  return (
    <div className="gradient-border h-full grow rounded-lg p-0.5">
      <div
        className={cn(
          'h-full w-full',
          'flex flex-row items-center gap-4 p-3',
          'rounded-md',
          'bg-color-bg-process-builder-item hover:bg-color-bg-process-builder-item/90'
        )}
      >
        <BasicNodeContent nodeType={nodeType} title={title} />
      </div>
    </div>
  );
};
