import { type XYPosition } from 'reactflow';

import type { Node } from '../types/Node';
import type { NodeData } from '../types/NodeData';

export function createNode(
  id: string,
  type: 'basicNode' | 'editableNode',
  position: XYPosition,
  data: NodeData
): Node {
  return {
    id,
    type,
    position,
    data,
  };
}

export function createEditableNode(
  id: string,
  position: XYPosition,
  data: NodeData
): Node {
  return createNode(id, 'editableNode', position, {
    ...data,
    type: 'pending',
  });
}
