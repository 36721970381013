import { Button } from '@spektr/client/components';

import { useGraphState } from '../../hooks/useGraphState';

import { CaseIcon } from '../CaseIcon';
import { NodeSubText } from '../NodeSubText';

import { type NodeData } from '../../types/NodeData';

export namespace ViewerNodePopover {
  export type Props = {
    data: NodeData;
    onClose?: () => void;
  };
}

export const ViewerNodePopover = ({
  data,
  onClose,
}: ViewerNodePopover.Props) => {
  const { onNavigateToNode } = useGraphState();

  const handleGoToCustomer = () => {
    onClose?.();
    onNavigateToNode?.(data);
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex flex-row justify-between gap-3">
        <div className="flex flex-row items-center gap-3">
          <CaseIcon status="pending" type={data.type} />
          <div className="flex flex-col gap-0.5">
            <p className="text-color-text-node relative text-sm font-medium">
              {data.name}
            </p>
            <NodeSubText data={data} />
          </div>
        </div>
        {/* {data.status !== 'pending' && (
          <div className="flex h-fit shrink-0">
            <StatusWithIcon
              color={data.status === 'approved' ? 'success' : 'error'}
              label={t(`cases:status.${data.status}`)}
              icon={
                data.status === 'approved' ? (
                  <Check className="h-2.5 w-2.5" />
                ) : (
                  <AlertTriangle className="h-2.5 w-2.5" />
                )
              }
            />
          </div>
        )} */}
      </div>
      <Button color="secondary" onClick={handleGoToCustomer}>
        Go to customer
      </Button>
    </div>
  );
};
