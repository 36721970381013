import { z } from 'zod';

export const filterTypes = ['single', 'multiple'] as const;
export const FilterType = z.enum(filterTypes);
export type FilterType = z.infer<typeof FilterType>;

export const filterSelectionTypes = ['single', 'multiple'] as const;
export const FilterSelectionType = z.enum(filterSelectionTypes);
export type FilterSelectionType = z.infer<typeof FilterType>;

export const filterFormats = ['dropdown', 'input', 'date'] as const;
export const FilterFormat = z.enum(filterFormats);
export type FilterFormat = z.infer<typeof FilterFormat>;

export type CasesFilterDropdownValue = {
  key: string;
  labelNode: React.ReactNode;
};

export type CasesFilterConfiguration = {
  icon: React.ReactNode;
  label: string;
  selectionType: FilterSelectionType;
  format: FilterFormat;
  values?: CasesFilterDropdownValue[];
  conflictingFilters?: string[];
};

export type CasesFiltersConfigurations = {
  [filterIdentifier: string]: CasesFilterConfiguration;
};

const allowedLimitValues = [10, 20, 50];
export const pageLimitSchema = z
  .number()
  .refine((value) => allowedLimitValues.includes(value), {
    message: 'Value must be 10, 20, or 50',
  })
  .default(10);
