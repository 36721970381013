import { z } from 'zod';

import { InputField } from './InputField';
import { DropdownField } from './DropdownField';
import { CheckboxField } from './CheckboxField';
import { FileField } from './FileField';
import { DateField } from './DateField';
import { TitleField } from './TitleField';
import { EntitiesField } from './EntitiesField';
import { ParagraphField } from './ParagraphField';
import { DividerField } from './DividerField';
import { CurrencyField } from './CurrencyField';
import { ConsentField } from './ConsentField';

export const ReadOnlyField = z.union([
  TitleField,
  ParagraphField,
  DividerField,
]);
export type ReadOnlyField = z.infer<typeof ReadOnlyField>;

export function isReadOnlyField(field: unknown): field is ReadOnlyField {
  return ReadOnlyField.safeParse(field).success;
}

// ! When adding new fields, make sure to add them to the union in ./EntitiesField.ts
export const WritableField = z.union([
  DateField,
  CurrencyField,
  InputField,
  DropdownField,
  CheckboxField,
  FileField,
  EntitiesField,
  DropdownField,
  ConsentField,
]);
export type WritableField = z.infer<typeof WritableField>;

export function isWritableField(field: unknown): field is WritableField {
  return WritableField.safeParse(field).success;
}

export const Field = z.union([ReadOnlyField, WritableField]);
export type Field = z.infer<typeof Field>;
