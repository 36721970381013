import { Download, File } from 'lucide-react';

import { ButtonWithTooltip } from '@spektr/client/components';

import { cn } from '@spektr/style-utils';

import { simulateDocumentDownload } from '../../utils';

type FileDownloadBlockProps = {
  fileName: string;
  fileType: string;
  processName: string;
  data: any;
};

export const FileDownloadBlock = ({
  fileName,
  fileType,
  processName,
  data,
}: FileDownloadBlockProps) => {
  return (
    <div
      className={cn(
        'flex items-center gap-4 p-4',
        'rounded-md border',
        'bg-color-bg-card-default'
      )}
    >
      <File className="m-3 h-4 w-4" />
      <div className="flex flex-col">
        <p className="text-md">{fileName}</p>
        <span className="text-color-text-subtext text-sm">{`source of ${processName}`}</span>
      </div>
      <ButtonWithTooltip
        className="ml-auto p-2.5"
        showTooltip
        tooltipProps={{
          content: 'Download file',
        }}
        color="icon"
        onClick={() => simulateDocumentDownload(data, fileName, fileType)}
      >
        <Download className="h-4 w-4" />
      </ButtonWithTooltip>
    </div>
  );
};
