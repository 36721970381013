import { useTranslation } from 'react-i18next';

import { NodeType } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';

import { NodeIcon } from '@spektr/client/components';

export type BasicNodeContentProps = {
  nodeType: NodeType;
  title?: string;
  fields?: Record<string, boolean>;
  label?: boolean;
};

export const BasicNodeContent = ({
  nodeType,
  title,
  fields,
  label = false,
}: BasicNodeContentProps) => {
  const { t } = useTranslation('process');
  return (
    <>
      <NodeIcon size="xl" nodeType={nodeType} fields={fields} />
      <div className="flex flex-col">
        {title && (
          <h3 className="text-color-text-node line-clamp-1 text-sm font-medium">
            {title}
          </h3>
        )}
        <span className="text-color-text-node-subtext text-xs">
          {t(`process:nodes.${nodeType}.description`)}
        </span>
      </div>
      {label && (
        <div
          className={cn(
            'absolute bottom-1 right-1',
            'bg-color-bg-accent rounded px-2 py-0.5',
            'text-color-text-node text-[10px] font-medium uppercase tracking-wide'
          )}
        >
          {t(`process:nodes.${nodeType}.label`)}
        </div>
      )}
    </>
  );
};
