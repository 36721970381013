import { ReactNode } from 'react';
import { icons } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { IconBox, IconBoxVariants } from '@spektr/client/components';

type CustomerTimelineCellProps = {
  className?: string;
  iconName?: keyof typeof icons;
  iconColor?: IconBoxVariants['color'];
  label?: string;
  value?: ReactNode;
  renderIcon?: () => ReactNode;
};

export const CustomerTimelineCell = ({
  className,
  iconName,
  iconColor,
  label,
  value,
  renderIcon,
}: CustomerTimelineCellProps) => {
  const Icon = iconName ? icons[iconName] : null;

  return (
    <div className={cn('flex items-center gap-6', 'col-span-2', className)}>
      {renderIcon
        ? renderIcon()
        : Icon && (
            <IconBox
              size="xl"
              color={iconColor}
              renderIcon={({ className }) => <Icon className={className} />}
            />
          )}
      <div className="flex flex-col font-medium leading-tight">
        <div className="text-color-text-subtext text-xs">{label}</div>
        <div className="text-sm">{value}</div>
      </div>
    </div>
  );
};
