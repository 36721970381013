import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CUSTOMERS_KEYS, InsightsApiClient } from '@spektr/client/services';

import { uploadFileToS3 } from '@spektr/shared/utils';

export const useUploadCustomerDocument = (customerId: string) => {
  const queryClient = useQueryClient();
  const uploadCustomerDocument = useMutation({
    mutationFn: async (file: File) => {
      const s3Config =
        await InsightsApiClient.getClient().uploadClientRecordDocument(
          { fileName: file.name },
          {
            params: {
              clientRecordId: customerId,
            },
          }
        );

      return await uploadFileToS3(file, s3Config);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: CUSTOMERS_KEYS.DOCUMENTS(customerId),
      });
    },
  });

  return uploadCustomerDocument;
};
