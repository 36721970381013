import { cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

import { outlined, text } from '../variants';

import {
  buttonBase,
  buttonSizeVariants,
  commonButtonVariants,
} from '../buttonVariants';

import * as variants from './variants';

export const serviceButtonVariants = cva(buttonBase, {
  variants: {
    variant: {
      ...commonButtonVariants,

      'contained-complyAdvantage': variants.complyAdvantageContained,
      'outlined-complyAdvantage': cn(
        variants.complyAdvantageOutlined,
        outlined
      ),
      'text-complyAdvantage': cn(variants.complyAdvantageText, text),

      'contained-openCorporates': variants.openCorporatesContained,
      'outlined-openCorporates': cn(variants.openCorporatesOutlined, outlined),
      'text-openCorporates': cn(variants.openCorporatesText, text),

      'contained-bodacc': variants.bodaccContained,
      'outlined-bodacc': cn(variants.bodaccOutlined, outlined),
      'text-bodacc': cn(variants.bodaccText, text),

      'contained-kyckr': variants.kyckrContained,
      'outlined-kyckr': cn(variants.kyckrOutlined, outlined),
      'text-kyckr': cn(variants.kyckrText, text),

      'contained-companiesHouse': variants.companiesHouseContained,
      'outlined-companiesHouse': cn(variants.companiesHouseOutlined, outlined),
      'text-companiesHouse': cn(variants.companiesHouseText, text),

      'contained-veriff': variants.veriffContained,
      'outlined-veriff': cn(variants.veriffOutlined, outlined),
      'text-veriff': cn(variants.veriffText, text),

      'contained-veriffIdv': variants.veriffIdvContained,
      'outlined-veriffIdv': cn(variants.veriffIdvOutlined, outlined),
      'text-veriffIdv': cn(variants.veriffIdvText, text),

      'contained-mitId': variants.mitIdContained,
      'outlined-mitId': cn(variants.mitIdOutlined, outlined),
      'text-mitId': cn(variants.mitIdText, text),

      'contained-virk': variants.virkContained,
      'outlined-virk': cn(variants.virkOutlined, outlined),
      'text-virk': cn(variants.virkText, text),

      'contained-dunBradstreet': variants.dunBradstreetContained,
      'outlined-dunBradstreet': cn(variants.dunBradstreetOutlined, outlined),
      'text-dunBradstreet': cn(variants.dunBradstreetText, text),

      'contained-dowJones': variants.dowJonesContained,
      'outlined-dowJones': cn(variants.dowJonesOutlined, outlined),
      'text-dowJones': cn(variants.dowJonesText, text),

      'contained-lexisNexis': variants.lexisNexisContained,
      'outlined-lexisNexis': cn(variants.lexisNexisOutlined, outlined),
      'text-lexisNexis': cn(variants.lexisNexisText, text),

      'contained-transUnion': variants.transUnionContained,
      'outlined-transUnion': cn(variants.transUnionOutlined, outlined),
      'text-transUnion': cn(variants.transUnionText, text),

      'contained-creditSafe': variants.creditSafeContained,
      'outlined-creditSafe': cn(variants.creditSafeOutlined, outlined),
      'text-creditSafe': cn(variants.creditSafeText, text),

      // Integration variants
      'contained-slack': cn(variants.slackContained),
      'outlined-slack': cn(variants.slackOutlined, outlined),
      'text-slack': cn(variants.slackText, text),
    },
    size: buttonSizeVariants,
  },
  defaultVariants: {
    variant: 'contained-complyAdvantage',
    size: 'default',
  },
});
