import { useTranslation } from 'react-i18next';
import { icons } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { useFormRouter } from '../../../../hooks/useFormRouter';
import { FormRoutes } from '../../../../types';
import { WIDGETS_TYPES_ICONS } from '../../../../constants/widgets';

type FieldsCategoryCardProps = {
  route: FormRoutes;
};

export const FieldTypeCard = ({ route }: FieldsCategoryCardProps) => {
  const { t } = useTranslation('moonraker-form');
  const { navigateTo } = useFormRouter();

  const Icon =
    icons[WIDGETS_TYPES_ICONS[route] as keyof typeof icons] ??
    icons['TextCursorInput'];

  return (
    <div
      className={cn(
        'cursor-pointer',
        'flex flex-col gap-2',
        'items-center justify-center',
        'min-h-28 w-full rounded-md',
        'bg-color-bg-moonraker-item',
        'hover:bg-color-cyan/10 dark:hover:bg-color-green/40 group'
      )}
      onClick={() => navigateTo(route)}
    >
      <Icon className="group-hover:text-color-cyan dark:group-hover:text-color-green h-4 min-w-4" />

      <div className="max-w-[100px] text-center">
        <p
          className={cn(
            'group-hover:text-color-cyan dark:group-hover:text-color-green',
            'text-sm font-medium'
          )}
        >
          {t(`views.${route}.title`)}
        </p>
        <span
          className={cn(
            'text-color-text-subtext',
            'group-hover:text-color-cyan dark:group-hover:text-color-green',
            'flex text-xs font-medium'
          )}
        >
          {t(`views.${route}.subtitle`)}
        </span>
      </div>
    </div>
  );
};
