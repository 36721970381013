import { useRef } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { produce } from 'immer';

import {
  getProcessByIdQuery,
  getSpektrAiUserQuery,
} from '@spektr/client/services';

import {
  AiAmlAlertNode,
  type AdverseMediaAuthorityLevels,
} from '@spektr/shared/validators';
import { type AlertField } from '@spektr/client/types';

import { DialogEditableTitle } from '../../components/DialogEditableTitle';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { mapAlertFieldToAiField } from './utils/mapAlertFieldToAiField';

import { AiAmlAlertContent } from './containers/AiAmlAlertContent';
import { SkeletonContent } from './components/SkeletonContent';

export namespace AiAmlAlertDialogContent {
  export type Props = BaseDialogProps<AiAmlAlertNode>;
}

const AiAmlAlertDialogContent = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
}: AiAmlAlertDialogContent.Props) => {
  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: botUser } = useSuspenseQuery(getSpektrAiUserQuery());

  const titleRef = useRef<HTMLInputElement>(null);

  const handleUpdate = (
    alertField: AlertField,
    authority: AdverseMediaAuthorityLevels
  ) => {
    const mappedFieldKey = mapAlertFieldToAiField(alertField);
    const payload = produce(node, (draft) => {
      draft.userId = botUser.id;

      draft[mappedFieldKey] = authority;
    });

    onUpdate(payload);
  };

  const handleInputBlur = (title: string) => {
    onUpdate({
      nodeType: node.nodeType,
      adverseMedia: node.adverseMedia,
      pep: node.pep,
      sanctions: node.sanctions,
      title,
      userId: botUser.id,
    });
  };

  return (
    <>
      <DialogEditableTitle
        titleRef={titleRef}
        initialTitle={node.title}
        onBlur={handleInputBlur}
      />
      <AiAmlAlertContent
        isPendingUpdate={isPendingUpdate}
        process={process}
        node={node}
        value={node.adverseMedia}
        onAuthorityChange={handleUpdate}
      />
    </>
  );
};

export const AiAmlAlertDialog = withDialogWrapper(
  AiAmlAlertDialogContent,
  <SkeletonContent />,
  {
    className: 'max-w-[700px]',
  }
);
