import { useController, useFormContext } from 'react-hook-form';

import { Checkbox } from '@spektr/components-forms';

import type { ConsentField as ConsentFieldProp } from '@spektr/moonraker-types';

export namespace ConsentField {
  export type Props = {
    field: ConsentFieldProp;
    name: string;
  };
}

export const ConsentField = ({ field, name }: ConsentField.Props) => {
  const context = useFormContext();

  const requiredValidation = field.validation?.find(
    (v) => v.type === 'required'
  );

  const { field: formField } = useController({
    name,
    control: context.control,
    defaultValue: false,
    rules: {
      required: requiredValidation?.message,
    },
  });

  return (
    <div className="mb-1 flex w-full flex-row items-center gap-2">
      <Checkbox
        value={name}
        checked={formField.value}
        onCheckedChange={formField.onChange}
      />
      <div
        className="consent-text text-sm"
        dangerouslySetInnerHTML={{ __html: field.attributes.label }}
      />
      {requiredValidation?.value && (
        <span className="-ml-1.5 text-red-500">*</span>
      )}
    </div>
  );
};
