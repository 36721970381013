import { ClientRecordFilter } from '@spektr/shared/validators';

export const CUSTOMERS_KEYS = {
  ALL: (filters?: ClientRecordFilter) => {
    const key = ['customers', 'list'] as const;
    return filters ? ([...key, filters] as const) : key;
  },
  BY_ID: (customerId: string) => ['customers', 'details', customerId] as const,
  TIMELINE: (customerId: string, page?: number, limit?: number) => {
    const key = ['customers', 'details', customerId, 'timeline'] as const;
    return page && limit ? ([...key, page, limit] as const) : key;
  },
  SERVICE_DATA: (customerId: string, vendorSearchId: string) =>
    ['customers', 'details', customerId, vendorSearchId, 'data'] as const,
  DOCUMENTS: (customerId: string) =>
    ['customers', 'details', customerId, 'documents'] as const,
  OWNERS_AND_CONTROLLERS: (customerId: string) =>
    ['customers', 'details', customerId, 'owners'] as const,
  CONNECTIONS: (customerId: string) =>
    ['customers', 'details', customerId, 'connections'] as const,
};
