import { produce } from 'immer';

import {
  type SlateDescendant,
  type SlateElement,
} from '@spektr/shared/slate-types';

import { useFormEngine } from '../../../../hooks/useFormEngine';
import { ConfigSegment } from '../../../../components/ConfigSegment';

import { ColorField, FormField, SwitchField } from '../fields';
import { RichTextEditorField } from '../fields/RichTextEditorField';

type ContentKeys =
  | 'title'
  | 'description'
  | 'support.title'
  | 'support.content'
  | 'textColor'
  | 'backgroundColor';

export const FormHelpContent = () => {
  const { state, updateAssistingContent } = useFormEngine();

  const handleToggleSupportBox = (checked: boolean) => {
    updateAssistingContent(
      produce(state.assistingContent, (draft) => {
        draft.support.display = checked;
      })
    );
  };

  const handleChangeContent = (
    field: ContentKeys,
    value: string | SlateElement[]
  ) => {
    updateAssistingContent(
      produce(state.assistingContent, (draft) => {
        if (typeof value === 'string') {
          if (field === 'title') {
            draft.title = value;
          }

          if (field === 'support.title') {
            draft.support.title = value;
          }

          if (field === 'textColor') {
            draft.support.textColor = value;
          }
          if (field === 'backgroundColor') {
            draft.support.backgroundColor = value;
          }
        }

        if (field === 'description') {
          draft.description = value as SlateElement[];
        }

        if (field === 'support.content') {
          draft.support.content = value as SlateElement[];
        }
      })
    );
  };

  return (
    <ConfigSegment name="content" title="Content">
      <div className="flex flex-col gap-4 pb-2">
        <FormField
          value={state.assistingContent.title}
          label="Title"
          name="assistingContent.title"
          placeholder="Input title"
          onChange={(ev) => handleChangeContent('title', ev.target.value)}
        />
        <RichTextEditorField
          initialValue={state.assistingContent.description as SlateDescendant[]}
          label="Content"
          name="assistingContent.description"
          placeholder="Input content"
          onChange={(value) =>
            handleChangeContent('description', value as SlateElement[])
          }
        />

        <SwitchField
          checked={state.assistingContent.support.display}
          label="Show support box"
          onCheckedChange={handleToggleSupportBox}
        />

        <FormField
          value={state.assistingContent.support.title}
          label="Title"
          name="assistingContent.support.title"
          placeholder="Input title"
          onChange={(ev) =>
            handleChangeContent('support.title', ev.target.value)
          }
        />
        <RichTextEditorField
          initialValue={
            state.assistingContent.support.content as SlateDescendant[]
          }
          label="Content"
          name="assistingContent.support.content"
          placeholder="Input content"
          onChange={(value) =>
            handleChangeContent('support.content', value as SlateElement[])
          }
        />
        <ColorField
          label="Text color"
          value={state.assistingContent.support.textColor}
          onChange={(value) =>
            handleChangeContent('textColor', value as string)
          }
        />
        <ColorField
          label="Background color"
          value={state.assistingContent.support.backgroundColor}
          onChange={(value) =>
            handleChangeContent('backgroundColor', value as string)
          }
        />
      </div>
    </ConfigSegment>
  );
};
