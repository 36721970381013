import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getExecutionById, getServiceData } from '@spektr/client/services';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  DialogClose,
  DialogTitle,
  JSONViewer,
  ScrollArea,
} from '@spektr/client/components';

import { useParsedParams } from '@spektr/shared/hooks';
import { casesTimelineServiceDataParamsSchema } from '@spektr/shared/utils';

import { computeVendorData } from '../utils/computeVendorData';

import { FileDownloadBlock } from '../components/FileDownloadBlock';

type NodeRunDataProps = {
  onClose: () => void;
};

export const NodeRunData = ({ onClose }: NodeRunDataProps) => {
  const { t } = useTranslation();
  const { spektrId, vendorSearchId } = useParsedParams(
    casesTimelineServiceDataParamsSchema
  );
  const { data: vendorData } = useSuspenseQuery(
    getServiceData(spektrId, vendorSearchId)
  );
  const { data: executionContext } = useSuspenseQuery(
    getExecutionById(vendorData.executionContextId)
  );
  const processName = executionContext.process.name;

  const { fileName, fileType, data } = useMemo(
    () => computeVendorData(vendorData, processName),
    [vendorData, processName]
  );

  return (
    <div className="flex flex-col gap-4 font-medium">
      <div className="flex flex-row items-center">
        <DialogTitle className="text-color-text-dialog-title">
          {t(`process:nodes.${vendorData.vendor}.name`)}
        </DialogTitle>

        <DialogClose className="ml-auto" onClick={onClose} />
      </div>
      <FileDownloadBlock
        fileName={fileName}
        fileType={fileType}
        processName={processName}
        data={vendorData.response}
      />

      <Accordion collapsible type="single">
        <AccordionItem value="json-data">
          <AccordionTrigger iconPosition="right" className="px-0">
            <p className="text-sm">Data extracted from this document</p>
          </AccordionTrigger>
          <AccordionContent className="break-all rounded-md border p-2">
            <ScrollArea className="max-h-[50vh] overflow-auto">
              <JSONViewer data={data} />
            </ScrollArea>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
