import { type OwnershipTreeFormNode } from '@spektr/shared/validators';

import { commonRenderError } from '../../utils/commonRenderError';

import { FallbackContent } from '../../components/FallbackContent';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { OwnershipTreeFormDialog } from './OwnershipTreeFormDialog';

export namespace OwnershipTreeFormDialogWrapper {
  export type Props = BaseDialogProps<OwnershipTreeFormNode>;
}
export const OwnershipTreeFormDialogWrapper = (
  props: OwnershipTreeFormDialogWrapper.Props
) => {
  return (
    <FallbackContent
      renderError={commonRenderError(
        'Something went wrong when loading this node'
      )}
      fallback={<div />}
    >
      <OwnershipTreeFormDialog {...props} />
    </FallbackContent>
  );
};
