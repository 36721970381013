import {
  ClientRecordTagAddEventData,
  UserSchema,
} from '@spektr/shared/validators';
import { Process } from '@spektr/shared/types';

import { Tag } from '@spektr/client/components';

import { CustomerTimelineCell } from '../CustomerTimelineCell';

import { ChangedDataCell } from './ChangedDataCell';

export type TagAddEventProps = {
  event: ClientRecordTagAddEventData;
  users: UserSchema[];
  processes: Process[];
};

export const TagAddEvent = ({ event, users, processes }: TagAddEventProps) => {
  const process = processes.find((p) => p.id === event.eventData.processId);
  const user = users.find((u) => u.id === event.eventData.userId);

  return (
    <>
      <CustomerTimelineCell
        iconName="Tags"
        className="col-span-4"
        label="Tag added"
        value={
          <Tag
            className="cursor-default"
            color={event.eventData.tag.color}
            label={event.eventData.tag.label || '-'}
          />
        }
      />
      <ChangedDataCell className="col-start-9" user={user} process={process} />
    </>
  );
};
