import { Store } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { FlagGbIcon, IconBox } from '@spektr/client/components';

export const OwnershipHeaderCard = () => {
  return (
    <div className="flex flex-col p-6">
      <p className="text-lg">Ownership Tree</p>
      <p className="text-color-text-subtext text-sm">
        Please fill in ownership structures
      </p>
      <div
        className={cn(
          'flex items-center justify-center',
          'mt-3 h-28',
          'rounded-md border',
          'bg-dots-pattern bg-cover bg-center'
        )}
      >
        <div
          className={cn(
            'flex w-52 items-center gap-2 p-2',
            'border-color-cyan rounded-md border',
            'scale-50'
          )}
        >
          <div
            className={cn(
              'absolute left-1/2 top-0',
              'h-2 w-2',
              'rounded-full border',
              'bg-color-bg-moonraker',
              '-translate-x-1/2 -translate-y-1/2'
            )}
          />
          <IconBox
            size="xl"
            color="cyan"
            renderIcon={({ className }) => <Store className={className} />}
          />
          <div className="flex flex-col">
            <p className="text-sm font-medium">Acme Limited</p>
            <div className="flex items-center gap-1">
              <FlagGbIcon className="h-3 w-auto" />
              <span className="text-color-text-subtext text-xs">123456789</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
