import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import {
  casesCustomerDetailsUrl,
  casesTimelineServiceDataParamsSchema,
} from '@spektr/shared/utils';
import { useDialogClose, useParsedParams } from '@spektr/shared/hooks';
import { ErrorPage } from '@spektr/shared/components';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  SpektrErrorBoundary,
} from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';

import { NodeRunData } from './containers';

import { NodeRunDataSkeleton } from './components/NodeRunDataSkeleton';

export const NodeRunDataDialog = () => {
  const { spektrId } = useParsedParams(casesTimelineServiceDataParamsSchema);
  const [open, startExitAnimation] = useDialogClose();
  const navigate = useNavigate();

  const handleClose = () => {
    startExitAnimation();
    navigate(casesCustomerDetailsUrl(spektrId));
  };

  return (
    <Dialog open={open}>
      <DialogContent
        className="max-w-[50vw]"
        requestStartExitAnimation={handleClose}
        onEndExitAnimation={handleClose}
        onEscapeKeyDown={handleClose}
      >
        <VisuallyHidden asChild>
          <DialogTitle>Loading title</DialogTitle>
        </VisuallyHidden>
        <VisuallyHidden asChild>
          <DialogDescription>Service data inspect</DialogDescription>
        </VisuallyHidden>
        <SpektrErrorBoundary
          fallbackRender={({ error }) => (
            <ErrorPage
              title={handleAxiosError(
                error,
                'An error has occurred while retrieving the node run data!'
              )}
            />
          )}
        >
          <Suspense fallback={<NodeRunDataSkeleton />}>
            <NodeRunData onClose={handleClose} />
          </Suspense>
        </SpektrErrorBoundary>
      </DialogContent>
    </Dialog>
  );
};
