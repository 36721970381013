import {
  isCheckboxField,
  isConsentField,
  isDateField,
  isDropdownField,
  isEntitiesField,
  isFileField,
  isInputField,
  type WritableField,
} from '@spektr/moonraker-types';

import { CheckboxField } from '../../components/CheckboxField';
import { InputField } from '../../components/InputField';
import { PhoneInputField } from '../../components/PhoneInputField';
import { RadioField } from '../../components/RadioField';
import { FileField } from '../../components/FileField';
import { DateField } from '../../components/DateField';
import { EntityField } from '../../components/EntityField';
import { DropdownField } from '../../components/DropdownField';
import { CountryField } from '../../components/CountryField';
import { ConsentField } from '../../components/ConsentField';

export type WritableFieldFactoryProps = {
  field: WritableField;
  name?: string;
};

export const WritableFieldFactory = ({
  field,
  name: propName,
}: WritableFieldFactoryProps) => {
  const name = propName ?? field.config.spektrDataField;
  if (isCheckboxField(field)) {
    if (field.type === 'radio') {
      return <RadioField name={name} field={field} />;
    }

    return <CheckboxField name={name} field={field} />;
  }

  if (isDateField(field)) {
    return <DateField name={name} field={field} />;
  }

  if (isInputField(field)) {
    if (field.attributes.type === 'phone') {
      return <PhoneInputField name={name} field={field} />;
    }

    if (field.attributes.type === 'country') {
      return <CountryField name={name} field={field} />;
    }

    return <InputField name={name} field={field} />;
  }

  if (isEntitiesField(field)) {
    return <EntityField name={name} field={field} />;
  }

  if (isDropdownField(field)) {
    return <DropdownField name={name} field={field} />;
  }

  if (isFileField(field)) {
    return <FileField name={name} field={field} />;
  }

  if (isConsentField(field)) {
    return <ConsentField name={name} field={field} />;
  }

  return null;
};
