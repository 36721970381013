import { z } from 'zod';

export const fieldsRoutes = [
  'fields-company',
  'fields-individual',
  'fields-other',
] as const;
export const fieldsFormRoutes = z.enum(fieldsRoutes);

export const formRoutes = z.enum([
  'blocks',
  'fields',
  ...fieldsRoutes,
  'primitives',
  'visuals',
  'design',
  'layout',
]);

export type FormRoutes = z.infer<typeof formRoutes>;

export interface FormRouterApi {
  currentRoute: FormRoutes;
  isActiveRoute: (routeId: FormRoutes) => boolean;
  navigateTo: (routeId: FormRoutes) => void;
}
