import axios from 'axios';

type PresignedPostData = {
  url: string;
  fields: Record<string, string>;
};

export async function uploadFileToS3(
  file: File,
  presignedPostData: PresignedPostData
) {
  const formData = new FormData();

  // Add presigned post fields to the formData
  Object.entries(presignedPostData.fields).forEach(([key, value]) => {
    formData.append(key, value as string);
  });

  // Add the file to be uploaded
  formData.append('file', file);

  // Make the POST request to the presigned URL
  const response = await axios.post(presignedPostData.url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
}
