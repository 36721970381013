import { useWatch } from 'react-hook-form';

import { isSupportedDataType } from '@spektr/shared/utils';
import { RBAC } from '@spektr/shared/rbac';

import { ActionButton } from '@spektr/client/components';

import { TableFields } from '../../validators/tableFieldsSchema';

export type FooterProps = {
  isEditable?: boolean;
};

export const Footer = ({ isEditable = false }: FooterProps) => {
  const fieldId: string = useWatch({ name: 'fieldId' });
  const fields: TableFields = useWatch({ name: 'fields' });

  const includedFieldsWithInvalidType = Object.values(fields).some((field) => {
    if (field.isIncluded) {
      return !isSupportedDataType(field.type);
    }

    return false;
  });

  return (
    <ActionButton
      rbacAction={RBAC.ACTIONS.DATASET.UPDATE}
      disabled={!isEditable || !fieldId || includedFieldsWithInvalidType}
      className="ml-auto mt-4"
      color="yellow"
      type="submit"
      data-cy="save-dataset"
    >
      Save dataset
    </ActionButton>
  );
};
