import { ReactNode, useCallback, useMemo } from 'react';

import { GraphStateContext } from './context';

import type { MoonrakerForm } from '@spektr/moonraker-types';
import type {
  GraphState,
  NavigateToNodeFn,
  SaveEdgeFn,
  SaveNodeFn,
} from '../../types/GraphState';

export namespace GraphStateProvider {
  export type Props = {
    canAddNodes?: boolean;
    canEditNodes?: boolean;
    canEditEdges?: boolean;
    children: ReactNode;
    form?: MoonrakerForm;
    onSaveNode?: SaveNodeFn;
    onSaveEdge?: SaveEdgeFn;
    onNavigateToNode?: NavigateToNodeFn;
  };
}

export const GraphStateProvider = ({
  canAddNodes = false,
  canEditNodes = false,
  canEditEdges = false,
  children,
  form,
  onSaveNode,
  onSaveEdge,
  onNavigateToNode,
}: GraphStateProvider.Props) => {
  const handleSaveNode = useCallback<SaveNodeFn>(
    (node) => (onSaveNode ? onSaveNode(node) : Promise.resolve()),
    [onSaveNode]
  );

  const handleSaveEdge = useCallback<SaveEdgeFn>(
    (...args) => (onSaveEdge ? onSaveEdge(...args) : Promise.resolve()),
    [onSaveEdge]
  );

  const value = useMemo<GraphState>(
    () => ({
      canAddNodes,
      canEditNodes,
      canEditEdges,
      form,
      onNavigateToNode,
      onSaveNode: handleSaveNode,
      onSaveEdge: handleSaveEdge,
    }),
    [
      canAddNodes,
      canEditNodes,
      canEditEdges,
      form,
      onNavigateToNode,
      handleSaveNode,
      handleSaveEdge,
    ]
  );

  return (
    <GraphStateContext.Provider value={value}>
      {children}
    </GraphStateContext.Provider>
  );
};
