import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

type FormContentBlockProps = {
  className?: string;
  children: ReactNode;
};

export const FormContentBlock = ({
  className,
  children,
}: FormContentBlockProps) => {
  return (
    <div
      className={cn(
        'flex flex-col',
        'w-[360px]',
        'rounded-md',
        'border dark:border-none',
        'bg-color-bg-moonraker',
        'shadow-md',
        'transition-all',
        className
      )}
    >
      {children}
    </div>
  );
};
