import { cn } from '@spektr/style-utils';

export const complyAdvantageContained = cn(
  'bg-color-complyAdvantage',
  'text-color-text-button-contained-vendor',
  'hover:bg-color-complyAdvantage/80',
  'active:bg-color-complyAdvantage/90'
);
export const complyAdvantageOutlined = cn(
  complyAdvantageContained,
  'border-color-complyAdvantage',
  'text-color-complyAdvantage',
  'hover:bg-color-complyAdvantage/10',
  'active:bg-color-complyAdvantage/20',
  'disabled:border-color-complyAdvantage/50'
);
export const complyAdvantageText = cn('text-color-complyAdvantage');
