import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { Root } from '@radix-ui/react-radio-group';

import { cn } from '@spektr/style-utils';

export namespace RadioGroup {
  export type Ref = ElementRef<typeof Root>;

  export type Props = ComponentPropsWithoutRef<typeof Root>;
}

export const RadioGroup = forwardRef<RadioGroup.Ref, RadioGroup.Props>(
  ({ className, ...props }, ref) => {
    return (
      <Root className={cn('grid gap-2', className)} {...props} ref={ref} />
    );
  }
);
