import { createContext } from 'react';

import { GraphState } from '../../types/GraphState';

export const GraphStateContext = createContext<GraphState>({
  canAddNodes: false,
  canEditNodes: false,
  canEditEdges: false,
  form: undefined,
  onSaveNode: () => Promise.resolve(),
  onSaveEdge: () => Promise.resolve(),
  onNavigateToNode: () => null,
});
