import { useDrop } from 'react-dnd';

import { cn } from '@spektr/style-utils';

import { ScrollArea } from '@spektr/client/components';

import { useContentHeight } from '../../hooks/useContentHeight';
import { useFormEngine } from '../../hooks/useFormEngine';

import { FieldConfigSidebar } from '../FieldConfigSidebar';

import { FormView } from './components/FormView';
import { FormContentBlock } from './components/FormContentBlock';
import { OwnershipHeaderCard } from './components/OwnershipHeaderCard';

import type { DraggableItem, DraggableItemType } from '../../types';

type FormCanvasProps = {
  isOwnershipForm?: boolean;
};

export const FormCanvas = ({ isOwnershipForm = false }: FormCanvasProps) => {
  const height = useContentHeight();

  const {
    state,
    selectFieldId,
    dropField,
    cloneField,
    deleteField,
    reorderFields,
  } = useFormEngine();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['block', 'widget', 'primitive'],
    drop: (widget: DraggableItem, monitor) => {
      const itemType = monitor.getItemType() as DraggableItemType;

      dropField(itemType, widget);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [rootFieldId, fieldId] = state.selectedFieldsId ?? [];

  return (
    <div
      className={cn(
        'relative',
        'grid grid-cols-[auto_256px]',
        'bg-color-bg-foreground'
      )}
    >
      <ScrollArea
        style={{
          height,
        }}
      >
        <div
          className={cn(
            'relative',
            'flex items-center justify-center',
            'h-full w-full',
            'py-12',
            isOwnershipForm && 'items-start'
          )}
        >
          <div className="flex flex-col gap-8">
            {isOwnershipForm && (
              <FormContentBlock>
                <OwnershipHeaderCard />
              </FormContentBlock>
            )}
            <FormView
              ref={drop}
              canDrop={canDrop}
              isOver={isOver}
              fields={state.fields}
              order={state.order}
              selectedFieldsId={fieldId ? fieldId : rootFieldId}
              onClone={cloneField}
              onDelete={deleteField}
              onSelect={selectFieldId}
              onReorder={reorderFields}
            />
          </div>
        </div>
      </ScrollArea>
      <FieldConfigSidebar />
    </div>
  );
};
