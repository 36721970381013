import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';
import { useSuspenseQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { ScrollArea, toast } from '@spektr/client/components';
import { UpdateVirkNode, VirkNode } from '@spektr/shared/validators';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { BaseDialogProps } from '../../types/BaseDialogProps';
import { useEnrichedServiceFields } from '../../providers/EnrichedServiceFields';
import { ServiceCommonMappingForm } from '../../components/ServiceCommonMappingForm';

import { CustomerProfileEnrichmentFields } from '../CustomerProfileEnrichmentFields';
import { withFieldsEnrichment } from '../WithFieldsEnrichment';
import { withDialogWrapper } from '../WithDialogWrapper';

import { ContentSkeleton } from './components';
import { DialogFooter } from './containers/DialogFooter/DialogFooter';

export namespace VirkDialogContent {
  export type Props = BaseDialogProps<VirkNode>;
}

export const VirkDialogContent = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
}: VirkDialogContent.Props) => {
  const { t } = useTranslation();

  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );
  const { getServiceFields } = useEnrichedServiceFields();
  const formInstance = useForm<Record<string, string>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });

  const formEntries = useMemo(
    () => [
      {
        key: 'cvrNumber',
        label: t('fields:virk.cvrNumber'),
        type: 'string',
        description: '* Mandatory',
      },
    ],
    [t]
  );

  const handleSave = async () => {
    const mapping = formInstance.getValues();
    const fields = getServiceFields();

    const parsedNode = VirkNode.parse(node);

    const updatedNode = UpdateVirkNode.parse({
      title: parsedNode.title,
      nodeType: parsedNode.nodeType,
      fields,
      mapping,
    });

    await onUpdate(updatedNode);

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  return (
    <FormProvider {...formInstance}>
      <ScrollArea className="-mr-3 max-h-[70vh] overflow-y-auto">
        <div className="mr-3 flex flex-col gap-6">
          <ServiceCommonMappingForm
            spektrFields={spektrFields}
            form={formEntries}
            title={t('process:baseMapAttributesTitle')}
          />
          <CustomerProfileEnrichmentFields
            title={t('process:customerProfileEnrichment.sectionTitle')}
          />
        </div>
      </ScrollArea>
      <DialogFooter isPendingUpdate={isPendingUpdate} onSave={handleSave} />
    </FormProvider>
  );
};
const EnhancedVirkDialogContent = withFieldsEnrichment(VirkDialogContent);

export const VirkDialog = withDialogWrapper(
  EnhancedVirkDialogContent,
  <ContentSkeleton />,
  {
    className: 'max-w-[700px]',
  }
);
