import { cn } from '@spektr/style-utils';

export const outlined = cn('border', 'bg-transparent');
export const text = cn(
  'bg-transparent',
  'hover:bg-transparent hover:text-opacity-80',
  'active:bg-transparent active:text-opacity-90'
);

const primaryContained = cn(
  'bg-color-bg-button-primary',
  'text-color-text-button-contained-primary',
  'hover:bg-color-bg-button-primary--hover',
  'active:bg-color-bg-button-primary--active',
  'disabled:bg-opacity-50'
);
const primaryOutlined = cn(
  primaryContained,
  'dark:border-color-border-button-primary',
  'border-color-border-secondary',
  'disabled:border-color-border-button-primary--disabled'
);
const primaryText = cn(
  'text-color-text-button-primary hover:text-color-text-button-primary/70'
);

const secondaryContained = cn(
  'bg-color-bg-button-secondary',
  'text-color-text-button-contained-secondary',
  'hover:bg-color-bg-button-secondary--hover',
  'active:bg-color-bg-button-secondary--active/50',
  'disabled:bg-opacity-50'
);
const secondaryOutlined = cn(
  secondaryContained,
  'border-color-border-button-secondary',
  'disabled:border-color-border-button-secondary--disabled'
);
const secondaryText = cn('text-color-text-button-secondary');

const cyanContained = cn(
  'bg-color-cyan',
  'text-color-text-button-contained-cyan',
  'hover:bg-opacity-90',
  'active:bg-opacity-80',
  'disabled:bg-opacity-50',
  'disabled:border-color-cyan/50'
);
const cyanOutlined = cn(
  cyanContained,
  'border-color-cyan',
  'text-color-cyan',
  'hover:bg-color-cyan/10',
  'active:bg-color-cyan/20',
  'disabled:border-color-cyan/50'
);
const cyanText = cn('text-color-cyan');

const successContained = cn(
  'bg-color-secondary-green',
  'text-color-text-button-contained-success',
  'hover:bg-opacity-90',
  'active:bg-opacity-80',
  'disabled:bg-opacity-50',
  'disabled:border-color-secondary-green/50'
);
const successOutlined = cn(
  successContained,
  'border-color-secondary-green',
  'text-color-secondary-green',
  'hover:bg-color-secondary-green/10',
  'active:bg-color-secondary-green/20',
  'disabled:border-color-secondary-green/50'
);
const successText = cn('text-color-secondary-green');

const yellowContained = cn(
  'bg-color-yellow',
  'hover:bg-opacity-90',
  'active:bg-opacity-80',
  'text-color-text-button-contained-warning',
  'disabled:bg-opacity-50'
);
const yellowOutlined = cn(
  yellowContained,
  'border-color-yellow',
  'text-color-yellow',
  'hover:bg-color-yellow/10',
  'active:bg-color-yellow/20',
  'disabled:border-color-yellow/50'
);
const yellowText = cn('text-color-yellow');

const blueContained = cn(
  'bg-color-blue',
  'hover: bg-opacity-90',
  'active: bg-opacity-80',
  'text-color-text-button-contained-info',
  'disabled:bg-opacity-50',
  'disabled:border-color-blue/50'
);

const blueOutlined = cn(
  blueContained,
  'border-color-blue',
  'text-color-blue',
  'hover:bg-color-blue/10',
  'active:bg-color-blue/20',
  'disabled:border-color-blue/50'
);

const blueText = cn('text-color-blue');

const redContained = cn(
  'bg-color-red',
  'hover:bg-opacity-90',
  'active:bg-opacity-80',
  'text-color-text-button-contained-alert',
  'disabled:bg-opacity-50'
);
const redOutlined = cn(
  redContained,
  'border-color-red',
  'text-color-red',
  'hover:bg-color-red/10',
  'active:bg-color-red/20',
  'disabled:border-color-red/50'
);
const redText = cn('text-color-red');

const whiteContained = cn(
  'bg-color-bg-button-white',
  'hover:bg-opacity-90',
  'active:bg-opacity-80',
  'text-color-text-button-contained-white',
  'disabled:bg-opacity-50'
);

const whiteOutlined = cn(
  redContained,
  'border-color-border-button-white',
  'text-color-text-button-outlined-white',
  'hover:bg-color-bg-button-white/10',
  'active:bg-color-bg-button-white/20',
  'disabled:border-color-border-button-white--disabled'
);
const whiteText = cn('text-color-text-button-white');

const alertContained = cn(
  'bg-color-bg-button-action',
  'text-color-text-button-contained-action',
  'hover:bg-spektr-tertiary-default/90',
  'active:bg-spektr-tertiary-default/80'
);
const alertOutlined = cn(
  alertContained,
  'border-color-border-button-action',
  'text-color-text-tertiary',
  'hover:bg-color-bg-button-action/10',
  'active:bg-color-bg-button-action/20',
  'disabled:border-color-border-button-action--disabled'
);
const alertText = cn('text-color-text-tertiary');

const iconContained = cn(
  'text-color-text-primary',
  'bg-color-bg-button-icon',
  'dark:bg-color-bg-button-icon/20',
  'hover:bg-color-bg-button-icon--hover',
  'active:bg-color-bg-button-icon--hover/50',
  'border border-color-border-primary',
  'disabled:bg-opacity-50'
);
const iconOutlined = cn(
  iconContained,
  'border-color-border-button-secondary',
  'disabled:border-color-border-button-secondary--disabled'
);

export const variants = {
  primaryContained,
  primaryOutlined,
  primaryText,
  secondaryContained,
  secondaryOutlined,
  secondaryText,
  cyanContained,
  cyanOutlined,
  cyanText,
  successContained,
  successOutlined,
  successText,
  yellowContained,
  yellowOutlined,
  yellowText,
  blueContained,
  blueOutlined,
  blueText,
  redContained,
  redOutlined,
  redText,
  whiteContained,
  whiteOutlined,
  whiteText,
  alertContained,
  alertOutlined,
  alertText,
  iconContained,
  iconOutlined,
};
