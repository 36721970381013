import { Link, useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';
import { Download, File } from 'lucide-react';

import { useParsedParams } from '@spektr/shared/hooks';
import {
  casesCustomerDocumentsUrl,
  casesDocumentParamsSchema,
} from '@spektr/shared/utils';

import { BasicDialog, IconButton } from '@spektr/client/components';
import { getCustomerDocuments } from '@spektr/client/services';

import { useFeatureFlags } from '@spektr/platform-hooks';

export const CustomerViewDocumentDialog = () => {
  const navigate = useNavigate();
  const { viewDocuments } = useFeatureFlags();

  const { spektrId, documentKey } = useParsedParams(casesDocumentParamsSchema);
  const { data: documents } = useSuspenseQuery(getCustomerDocuments(spektrId));

  const document = documents.find((doc) => doc.key === documentKey);

  const handleClose = useCallback(() => {
    navigate(casesCustomerDocumentsUrl(spektrId));
  }, [navigate, spektrId]);

  useEffect(() => {
    if (!viewDocuments) {
      return handleClose();
    }
  }, [handleClose, viewDocuments]);

  return (
    <BasicDialog defaultOpen title="Document" onClose={handleClose}>
      <div className="bg-color-bg-card-default flex items-center justify-between rounded-md border p-4">
        <div className="flex items-center gap-4">
          <File className="text-color-text-subtext m-2.5 h-4 w-4 min-w-4" />

          <div className="flex min-w-0 flex-col items-start">
            <p className="w-full truncate text-base font-medium">
              {document?.fileName}
            </p>
            <span className="text-color-text-subtext w-full truncate text-sm font-medium">
              Source of{' '}
              {document?.source === 'process'
                ? document?.processName
                : document?.source}
            </span>
          </div>
        </div>

        <Link to={document?.downloadPresignedUrl || ''}>
          <IconButton color="icon" className="h-9 w-9 min-w-9 p-0">
            <Download className="h-4 w-4" />
          </IconButton>
        </Link>
      </div>
    </BasicDialog>
  );
};
