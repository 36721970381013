import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Search } from 'lucide-react';

import { NodeType } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';

import { type AlertField } from '@spektr/client/types';
import { Dropdown, NodeIcon } from '@spektr/client/components';

import { useServiceAlertNodeContext } from '../providers/ServiceAlertNodeProvider';

export type SpektrFieldsDropdownProps = {
  value?: string;
  onFieldChange: (field: AlertField) => void;
};

type FieldOption = {
  label: string;
  value: string;
  nodeType: NodeType;
  nodeId: string;
  processId: string;
};

export const AlertFieldsDropdown = ({
  value,
  onFieldChange,
}: SpektrFieldsDropdownProps) => {
  const { t } = useTranslation('fields');
  const [searchValue, setSearch] = useState('');
  const { fieldsWithoutProperties } = useServiceAlertNodeContext();

  const fieldOptions = useMemo(
    () =>
      fieldsWithoutProperties.map(
        (field) =>
          ({
            label:
              field.nodeType === 'form' ||
              field.nodeType === 'ownershipTreeForm'
                ? t('alertFormField', {
                    field: field.name,
                  })
                : t(`${field.nodeType}.${field.name}`),
            value: field.name,
            nodeType: field.nodeType,
            nodeId: field.nodeId,
            processId: field.processId,
          }) as FieldOption
      ),
    [fieldsWithoutProperties, t]
  );

  const filteredFields = useMemo(
    () =>
      fieldOptions.filter((field) =>
        field.label.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [fieldOptions, searchValue]
  );

  const selectedItem = useMemo(() => {
    if (!fieldOptions) {
      return undefined;
    }

    return fieldOptions.find((field) => field.value === value);
  }, [fieldOptions, value]);

  const handleSelect =
    (toggleOpen: (value: boolean) => void) => (option: FieldOption) => {
      toggleOpen(false);
      onFieldChange({
        name: option.value,
        nodeType: option.nodeType,
        nodeId: option.nodeId,
        processId: option.processId,
      });
    };

  return (
    <Dropdown
      hasSearch
      inputProps={{
        startIcon: <Search className="h-4 w-4" />,
        placeholder: 'Search...',
      }}
      renderTrigger={(isOpen, setOpen) => (
        <div
          className={cn(
            'flex items-center gap-2',
            'h-9 w-full px-3',
            'rounded-md border',
            'text-left text-xs font-medium'
          )}
          onClick={() => setOpen(!isOpen)}
        >
          {selectedItem && <NodeIcon nodeType={selectedItem.nodeType} />}
          <span className="line-clamp-1">
            {selectedItem?.label ?? 'Choose alert trigger'}
          </span>
        </div>
      )}
      renderContent={(setOpen) =>
        filteredFields.length === 0 ? (
          <div className="text-color-text-subtext p-2 text-center text-sm font-medium">
            No fields found
          </div>
        ) : (
          <ul className="mt-2">
            {filteredFields.map((item) => (
              <li
                key={item.label}
                className={cn(
                  'w-full',
                  'rounded-sm',
                  'text-sm font-medium',
                  'hover:bg-color-bg-accent'
                )}
              >
                <button
                  type="button"
                  className={cn(
                    'flex items-center gap-2',
                    'w-full p-2',
                    'text-left',
                    {
                      'font-semibold': item.value === selectedItem?.value,
                    }
                  )}
                  onClick={() => handleSelect(setOpen)(item)}
                >
                  <NodeIcon nodeType={item.nodeType} />
                  <span className="line-clamp-1">{item.label}</span>
                </button>
              </li>
            ))}
          </ul>
        )
      }
      onSearch={setSearch}
    />
  );
};
