import { useSuspenseQuery } from '@tanstack/react-query';
import { KeyboardEvent, MouseEvent, useMemo, useState } from 'react';

import { cn } from '@spektr/style-utils';
import { isDatasetSource } from '@spektr/shared/utils';

import { connectionDataSetsQuery } from '@spektr/client/services';

import { CalculatorIcon } from '@spektr/client/components';

import { DatasetCard } from '../../components/DatasetCard';
import { MethodItem } from '../../components/MethodItem';
import { useSourceProvider } from '../../providers/SourceProvider';
import { FilterRuleGroup } from '../FilterRuleGroup';

export const CustomFilterMethod = () => {
  const {
    getSelectedMethod,
    setSelectedMethod,
    getFilterSource,
    setFilterSource,
  } = useSourceProvider();

  const source = getFilterSource();
  const isSelected = isDatasetSource(getSelectedMethod());

  const { data: datasets } = useSuspenseQuery(connectionDataSetsQuery());

  const [isExpanded, setIsExpanded] = useState(false);
  const isOpen = isSelected && isExpanded;

  const datasetsOptions = useMemo(() => {
    if (Array.isArray(datasets)) {
      return datasets.filter((dataset) => {
        return (
          dataset?.fieldId &&
          dataset.fields?.length > 0 &&
          dataset.origin !== 'child'
        );
      });
    }

    return [];
  }, [datasets]);

  const selectedDataset = useMemo(
    () => datasetsOptions.find((dataset) => dataset.id === source?.sourceId),
    [datasetsOptions, source]
  );

  const showDatasets = !selectedDataset || isExpanded;

  const renderExtra = () => {
    if (!showDatasets) {
      const spektrLabelsCount = selectedDataset?.fields.length;
      return (
        <DatasetCard
          className="absolute right-3 top-3 h-[61px] w-full max-w-[307px] hover:bg-transparent"
          description={`${spektrLabelsCount} fields available`}
          title={selectedDataset.name}
          onClick={() => setIsExpanded(true)}
        />
      );
    }
    return null;
  };

  const handleSelectMethod = (
    ev: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
  ) => {
    ev.stopPropagation();

    if (isSelected) {
      return;
    }

    setSelectedMethod('source');

    if (!selectedDataset) {
      setIsExpanded(true);
    }
  };

  const handleSelectDataset =
    (datasetId: string) =>
    (ev: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
      ev.stopPropagation();

      setFilterSource(datasetId);
      setIsExpanded(false);
    };

  return (
    <MethodItem
      icon={
        <CalculatorIcon className="stroke-color-cyan stroke-1.8 h-[18px] w-[18px]" />
      }
      title="Link a dataset"
      isSelected={(isSelected && !!source?.sourceId) || isOpen}
      disabled={datasetsOptions.length === 0}
      isOpen={isSelected}
      description="Use the data of one of your dataset"
      renderExtra={renderExtra}
      onClick={handleSelectMethod}
    >
      {showDatasets ? (
        <div
          className={cn(
            'grid grid-cols-2 gap-4',
            'max-h-[224px] overflow-y-auto',
            '-mx-6 -mb-6 px-6 pb-6'
          )}
        >
          {datasetsOptions.map((dataset) => {
            const spektrLabelsCount = dataset?.fields.length;
            return (
              <DatasetCard
                key={dataset.id}
                description={`${spektrLabelsCount} fields available`}
                isSelected={source?.sourceId === dataset.id}
                title={dataset.name}
                onClick={handleSelectDataset(dataset.id)}
              />
            );
          })}
        </div>
      ) : (
        <FilterRuleGroup />
      )}
    </MethodItem>
  );
};
